import {
  AnimationType,
  InitialDisplayState,
  MoveLinkEndEffectType,
} from "@constants/Constants";
import CssUtils from "./CssUtils";

const animations = {};

const getZoomAndPanKeyFrames = (animationName, moveSettings, zoomSettings) => {
  if (!moveSettings) {
    return `${animationName} {
        100% {
          transform: scale(${zoomSettings.zoomFactor});
          transform-origin:(50%, 50%);
       }
      }`;
  }
  if (!zoomSettings) {
    return `${animationName} {
        100% {
          transform: translate3D(${moveSettings.xOffset}, ${moveSettings.yOffset}, 0);
       }
      }`;
  }
  return null;
};

const restartAnimation = async (animationProps, setStyleProps) => {
  setStyleProps({ ...animationProps, animationName: "" });

  await CssUtils.reloadStyles();

  setStyleProps(animationProps);

  return true;
};

const animatePulse = (
  { initialOpacity, initialDisplayState },
  animationProps,
  { styleProps, setStyleProps }
) => {
  const currentOpacity = styleProps.opacity;
  let newOpacity;

  const validInitialOpacity =
    initialDisplayState === InitialDisplayState.VISIBLE ? initialOpacity : 0;

  if (currentOpacity === validInitialOpacity) {
    newOpacity = validInitialOpacity === 0 ? 1 : 0;
  } else {
    newOpacity = validInitialOpacity;
  }

  const animationName = `type_${AnimationType.PULSE}_animation_from_${currentOpacity}_to_${newOpacity}_to_${validInitialOpacity}`;

  let completeAnimationProps;
  if (animations[animationName]) {
    completeAnimationProps = { animationName, ...animationProps };
  } else {
    animations[animationName] = true;

    completeAnimationProps = {
      ...animationProps,
      animationName,
      keyFrames: `${animationName} {
            0% {
                opacity: ${currentOpacity};
            }

            50% {
                opacity: ${newOpacity};
            }

            100% {
                opacity: ${validInitialOpacity};
            }
    }`,
    };
  }

  return restartAnimation(
    {
      ...completeAnimationProps,
      ...styleProps,
    },
    setStyleProps
  );
};

const animateRotation = (animationProps, { styleProps, setStyleProps }) => {
  const animationName = `type_${AnimationType.ANIMATED_ROTATION}_animation`;

  let completeAnimationProps;
  if (animations[animationName]) {
    completeAnimationProps = { animationName, ...animationProps };
  } else {
    animations[animationName] = true;

    completeAnimationProps = {
      ...animationProps,
      animationName,
      keyFrames: `${animationName} {
        100% { 
                transform:rotate(360deg); 
            } 
    }`,
    };
  }

  return restartAnimation(
    {
      ...completeAnimationProps,
      ...styleProps,
    },
    setStyleProps
  );
};

const animateZoomOutPage = (animationProps, { width, setStyleProps }) => {
  const animationName = `type_${AnimationType.ZOOM_OUT_PAGE}_animation`;
  let completeAnimationProps;
  if (animations[animationName]) {
    completeAnimationProps = { animationName, ...animationProps };
  } else {
    animations[animationName] = true;

    completeAnimationProps = {
      ...animationProps,
      animationName,
      keyFrames: `${animationName} {
        to { 
                transform: none; 
            } 
    }`,
    };
  }

  setStyleProps({
    width,
    ...completeAnimationProps,
  });
  return true;
};

const move = ({ id }, animationProps, { styleProps, setStyleProps }) => {
  const animationName = `type_${AnimationType.MOVE}_animation_for_${id}`;
  let completeAnimationProps;

  const { endEffect } = animationProps;

  let endEffectDelay;
  let endEffectDuration;

  if (!endEffect) {
    endEffectDelay = 0;
    endEffectDuration = 0;
  } else {
    endEffectDelay = endEffect.delay === undefined ? 0 : endEffect.delay;
    endEffectDuration =
      endEffect.duration === undefined ? 1000 : endEffect.duration;
  }

  const duration = animationProps.duration + endEffectDuration + endEffectDelay;

  if (animations[animationName]) {
    completeAnimationProps = {
      animationName,
      animationTiming: `cubic-bezier(${animationProps.bezier})`,
      animationFillMode: "forwards",
      animationDuration: `${duration}ms`,
    };
  } else {
    animations[animationName] = true;

    const animationPercentage = Math.round(
      (100 * animationProps.duration) / duration
    );

    const effectDelayPercentage = Math.round(
      (100 * (animationProps.duration + endEffectDelay)) / duration
    );

    completeAnimationProps = {
      animationTiming: `cubic-bezier(${animationProps.bezier})`,
      animationFillMode: "forwards",
      animationDuration: `${duration}ms`,
      animationName,
      keyFrames: `${animationName} {
        ${animationPercentage}%, ${effectDelayPercentage}% { 
          transform: translate3D(${animationProps.leftOffset}px, ${
        animationProps.topOffset
      }px, 0);
          opacity:${styleProps.opacity};
        } 
        ${
          ((endEffect && endEffect.type === MoveLinkEndEffectType.FADE_OUT) ||
            "") &&
          `100%{
           transform: translate3D(${animationProps.leftOffset}px, ${animationProps.topOffset}px, 0);
          opacity:0;
        }`
        }
      }`,
    };
  }

  return restartAnimation(
    {
      ...completeAnimationProps,
      ...styleProps,
    },
    setStyleProps
  );
};

const zoomAndPan = async (
  { id },
  { moveSettings, zoomSettings },
  { styleProps, setStyleProps }
) => {
  const animationName = `type_${AnimationType.ZOOM_AND_PAN}_animation_for_${id}`;

  let completeAnimationProps;

  if (animations[animationName]) {
    completeAnimationProps = {
      animationName,
      animationFillMode: "forwards",
      animationDuration: `${Math.max(
        moveSettings?.duration || 0,
        zoomSettings?.duration || 0
      )}ms`,
    };
  } else {
    animations[animationName] = true;
    completeAnimationProps = {
      animationName,
      animationFillMode: "forwards",
      animationDuration: `${Math.max(
        moveSettings?.duration || 0,
        zoomSettings?.duration || 0
      )}ms`,
      keyFrames: getZoomAndPanKeyFrames(
        animationName,
        moveSettings,
        zoomSettings
      ),
    };
  }
  return restartAnimation(
    {
      ...completeAnimationProps,
      ...styleProps,
    },
    setStyleProps
  );
};

const animate = (animationType, objectModel, animationProps, options) => {
  switch (animationType) {
    case AnimationType.PULSE:
      return animatePulse(objectModel, animationProps, options);

    case AnimationType.ANIMATED_ROTATION:
      return animateRotation(animationProps, options);

    case AnimationType.ZOOM_OUT_PAGE:
      return animateZoomOutPage(animationProps, options);

    case AnimationType.MOVE:
      return move(objectModel, animationProps, options);

    case AnimationType.ZOOM_AND_PAN:
      return zoomAndPan(objectModel, animationProps, options);

    default:
      return null;
  }
};

export default { animate };
