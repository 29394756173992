import React, { useCallback } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";
import { CenterVertically } from "@src/GlobalStyles";

import ArrowButton from "@atoms/ArrowButton";

import {
  deviceOrientationAtom,
  openedPagesAtom,
  pagesAtom,
  settingsAtom,
} from "@stateManagement/Atoms";

import {
  getPagesIndexesToLoad,
  shouldLoadTwoPages,
  updateUrlHash,
} from "@utils/Utils";

import arrowIcon from "./gfx/rightArrowActiveIcon.svg";
import disabledArrowIcon from "./gfx/rightArrowInactiveIcon.svg";

const Container = styled.div`
  ${CenterVertically};
  width: 96px;
  height: 100%;
`;

const RightSideBar = () => {
  const deviceOrientation = useRecoilValue(deviceOrientationAtom);
  const settings = useRecoilValue(settingsAtom);
  const pages = useRecoilValue(pagesAtom);

  const [openedPages, setOpenedPages] = useRecoilState(openedPagesAtom);

  const onNavigateToNextPage = useCallback(() => {
    const pagesIndexesToLoad = getPagesIndexesToLoad(
      openedPages[1] ? openedPages[1] + 1 : openedPages[0] + 1,
      shouldLoadTwoPages(
        settings.reflowable,
        settings.orientation,
        deviceOrientation
      ),
      pages
    );

    updateUrlHash(pagesIndexesToLoad[0]);
    setOpenedPages(pagesIndexesToLoad);
  }, [
    deviceOrientation,
    openedPages,
    pages,
    setOpenedPages,
    settings.orientation,
    settings.reflowable,
  ]);

  return (
    <Container>
      <ArrowButton
        onClick={onNavigateToNextPage}
        disabled={
          !openedPages.length ||
          (openedPages[1] || openedPages[0]) === pages.length - 1
        }
        disabledStateImgSrc={disabledArrowIcon}
        enabledStateImgSrc={arrowIcon}
      />
    </Container>
  );
};

export default RightSideBar;
