import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useComponentDidMount from "@hooks/UseComponentDidMount";
import PlatformUtils from "@utils/PlatformUtils";
import HttpService from "@services/HttpService";

const StyledPackage = styled.div`
  ${({ height, width, zoomFactor }) => `
    height: ${height}px;
    width: ${width}px;
    transform: translateX(-50%) translateY(-50%) scale(${zoomFactor});
  `}
`;

const Package = React.memo(
  ({ height, id, onObjectLoad, width, zoomFactor, url }) => {
    const [packageContent, setPackageContent] = useState(null);
    const notifiedPage = useRef(false);

    useComponentDidMount(() => {
      HttpService.fetchHtmlPackage(PlatformUtils.getResourceUrl(url)).then(
        (htmlContent) => {
          setPackageContent(htmlContent);
        }
      );
    });

    useEffect(() => {
      if (!packageContent || notifiedPage.current) return;
      onObjectLoad(id);
      notifiedPage.current = true;
    }, [id, onObjectLoad, packageContent]);

    return (
      packageContent && (
        <StyledPackage
          height={height}
          width={width}
          zoomFactor={zoomFactor}
          dangerouslySetInnerHTML={{
            __html: packageContent,
          }}
        />
      )
    );
  }
);

Package.propTypes = {
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onObjectLoad: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

export default Package;
