import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Interactive } from "@src/GlobalStyles";
import useComponentDidMount from "@hooks/UseComponentDidMount";

const offset = 10; // offset outside the knob to interact with it.

const InteractiveContainer = styled(Interactive)`
  position: absolute;
  ${({ width }) => width && `width: ${width}px !important;`};
  ${({ height }) => height && `height: ${height}px !important;;`};
  ${({ top }) => top && `top: ${top}px !important;;`};
  ${({ left }) => left && `left: ${left}px !important;;`};
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
`;

const Container = styled.div`
  position: absolute;
  ${({ width }) => width && `width: ${width}px !important;`};
  ${({ height }) => height && `height: ${height}px !important;;`};
  ${({ top }) => top && `top: ${top}px !important;;`};
  ${({ left }) => left && `left: ${left}px !important;;`};
  z-index: 5;
  overflow: hidden;
`;

const Canvas = styled.canvas`
  position: absolute;
  ${({ width }) => width && `width: ${width}px;`};
  ${({ height }) => height && `height: ${height}px;`};
  top: ${offset}px;
  left: ${offset}px;
`;

const CircularKnob = ({
  backgroundStroke,
  backgroundStrokeColor,
  className,
  foregroundStroke,
  foregroundStrokeColor,
  interactive,
  onChange,
  onInteractionEnded,
  onInteractionStarted,
  style,
  value,
}) => {
  const loaded = useRef(false);
  const canvasRef = useRef({});
  const centerPoint = useRef({});
  const mousePressed = useRef(false);
  const [reRender, setReRender] = useState({});
  const animationFrameId = useRef(null);
  const currentProgress = useRef(value);

  const measuredSizes = useRef({
    containerWidth: style.width,
    containerHeight: style.height,
    containerLeft: style.left,
    containerTop: style.top,
    knobRadius: 0,
    minimInteractionRadius: 0,
    maximInteractionRadius: 0,
    knobWidth: 0,
    knobHeight: 0,
  });

  const getAngle = useCallback((x, y) => {
    const slope = y / x;
    const rawAngle = (180 * Math.atan(slope)) / Math.PI;
    if (x >= 0) {
      return 90 - rawAngle;
    }
    return 270 - rawAngle;
  }, []);

  const draw = useCallback(() => {
    const context = canvasRef.current.getContext("2d");

    const currentMeasuredValues = measuredSizes.current;
    const center = {
      x: currentMeasuredValues.knobWidth / 2,
      y: currentMeasuredValues.knobHeight / 2,
    };

    const progressInRadians = (2 * value) / 100; // 2- 2PI for full circle
    context.clearRect(
      0,
      0,
      currentMeasuredValues.knobWidth,
      currentMeasuredValues.knobHeight
    );
    context.lineWidth = backgroundStroke;
    context.strokeStyle = backgroundStrokeColor;
    context.beginPath();
    context.arc(
      center.x,
      center.y,
      currentMeasuredValues.knobRadius,
      0,
      2 * Math.PI
    );
    context.stroke();
    context.beginPath();
    context.lineWidth = foregroundStroke;
    context.strokeStyle = foregroundStrokeColor;
    context.arc(
      center.x,
      center.y,
      currentMeasuredValues.knobRadius,
      1.5 * Math.PI,
      (1.5 + progressInRadians) * Math.PI
    );
    context.stroke();
  }, [
    backgroundStroke,
    backgroundStrokeColor,
    foregroundStroke,
    foregroundStrokeColor,
    value,
  ]);

  const requestAnimationFrame = useCallback(() => {
    const animationFrameCallback = () => {
      if (onChange && value !== currentProgress.current) {
        onChange(currentProgress.current);
      }
      requestAnimationFrame();
    };

    animationFrameId.current = window.requestAnimationFrame(
      animationFrameCallback
    );
  }, [onChange, value]);

  const onMouseDown = useCallback(
    (e) => {
      const { pageX, pageY } = e;
      mousePressed.current = true;
      const x = pageX - centerPoint.current.x;
      const y = centerPoint.current.y - pageY;
      const currentRadius = Math.sqrt(x * x + y * y);
      if (
        measuredSizes.current.minimInteractionRadius > currentRadius ||
        currentRadius > measuredSizes.current.maximInteractionRadius
      )
        return;
      if (onInteractionStarted) {
        onInteractionStarted();
      }

      e.originalEvent.stopPropagation();
      requestAnimationFrame();
      currentProgress.current = (100 * getAngle(x, y)) / 360;
    },
    [getAngle, onInteractionStarted, requestAnimationFrame]
  );

  const onMouseUp = useCallback(() => {
    mousePressed.current = false;
    if (animationFrameId.current) {
      window.cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    }
    if (onInteractionEnded) {
      onInteractionEnded();
    }
  }, [onInteractionEnded]);

  const onMouseLeave = useCallback(() => {
    mousePressed.current = false;
    if (animationFrameId.current) {
      window.cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    }
    if (onInteractionEnded) {
      onInteractionEnded();
    }
  }, [onInteractionEnded]);

  const onMouseMove = useCallback(
    (e) => {
      if (!mousePressed.current) return;

      const { pageX, pageY } = e;
      const x = pageX - centerPoint.current.x;
      const y = centerPoint.current.y - pageY;

      const currentRadius = Math.sqrt(x * x + y * y);
      if (
        measuredSizes.current.minimInteractionRadius > currentRadius ||
        currentRadius > measuredSizes.current.maximInteractionRadius
      )
        return;
      currentProgress.current = (100 * getAngle(x, y)) / 360;
    },
    [getAngle]
  );

  useEffect(() => {
    currentProgress.current = value;
  }, [value]);

  useEffect(() => {
    draw();
  }, [draw, reRender, value]);

  useComponentDidMount(() => {
    const knobContainer = canvasRef.current.parentElement;
    const knobParent = knobContainer.parentElement;

    const knobContainerRect = knobContainer.getBoundingClientRect();
    const knobContainerParentRect = knobParent.getBoundingClientRect();

    const knobContainerLeft = knobContainerRect.x - knobContainerParentRect.x;
    const knobContainerTop = knobContainerRect.y - knobContainerParentRect.y;

    const knobRadius =
      (knobContainerRect.width - Math.max(backgroundStroke, foregroundStroke)) /
      2;
    const interactionOffset =
      offset + Math.max(backgroundStroke, foregroundStroke) / 2;

    measuredSizes.current = {
      containerLeft: knobContainerLeft - offset,
      containerWidth: 2 * offset + knobContainerRect.width,
      containerTop: knobContainerTop - offset,
      containerHeight: 2 * offset + knobContainerRect.height,
      knobWidth: knobContainerRect.width,
      knobHeight: knobContainerRect.height,
      knobRadius,
      minimInteractionRadius: knobRadius - interactionOffset,
      maximInteractionRadius: knobRadius + interactionOffset,
    };
    centerPoint.current = {
      x: knobContainerRect.x + knobContainerRect.width / 2,
      y: knobContainerRect.y + knobContainerRect.height / 2,
    };
    loaded.current = true;
    setReRender({});
  }, []);

  return interactive ? (
    <InteractiveContainer
      className={className}
      width={measuredSizes.current.containerWidth}
      height={measuredSizes.current.containerHeight}
      left={measuredSizes.current.containerLeft}
      top={measuredSizes.current.containerTop}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
    >
      <Canvas
        ref={canvasRef}
        width={measuredSizes.current.knobWidth}
        height={measuredSizes.current.knobHeight}
      />
    </InteractiveContainer>
  ) : (
    <Container
      className={className}
      width={measuredSizes.current.containerWidth}
      height={measuredSizes.current.containerHeight}
      left={measuredSizes.current.containerLeft}
      top={measuredSizes.current.containerTop}
    >
      <Canvas
        ref={canvasRef}
        width={measuredSizes.current.knobWidth}
        height={measuredSizes.current.knobHeight}
      />
    </Container>
  );
};

CircularKnob.defaultProps = {
  backgroundStroke: 0,
  backgroundStrokeColor: "#ffffff",
  className: null,
  foregroundStroke: 0,
  foregroundStrokeColor: "#000000",
  interactive: false,
  onChange: null,
  onInteractionEnded: null,
  onInteractionStarted: null,
  style: {},
  value: 0,
};

CircularKnob.propTypes = {
  backgroundStroke: PropTypes.number,
  backgroundStrokeColor: PropTypes.string,
  className: PropTypes.string,
  foregroundStroke: PropTypes.number,
  foregroundStrokeColor: PropTypes.string,
  interactive: PropTypes.bool,
  onChange: PropTypes.func,
  onInteractionEnded: PropTypes.func,
  onInteractionStarted: PropTypes.func,
  style: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  value: PropTypes.number,
};

export default CircularKnob;
