import DatabaseService from "@services/DatabaseService";

import {
  BorderStyleValues,
  ObjectType,
  Orientation,
  SideBarWidth,
} from "@constants/Constants";

export const shouldLoadTwoPages = (
  reflowable,
  projectOrientation,
  deviceOrientation
) =>
  reflowable &&
  projectOrientation === Orientation.PORTRAIT &&
  deviceOrientation === Orientation.LANDSCAPE;

export const getProjectScaleFactor = (width, height, twoPagesLoaded) => {
  const validWidth = twoPagesLoaded ? 2 * width : width;
  return Math.min(window.innerWidth / validWidth, window.innerHeight / height);
};

export const getNewProjectWidth = (
  originalProjectWidth,
  newZoomFactor,
  twoPagesLoaded
) =>
  Math.floor((twoPagesLoaded ? 2 : 1) * newZoomFactor * originalProjectWidth);

export const isSideBarPossible = (screenWidth, projectWidth) =>
  screenWidth - projectWidth >= 2 * SideBarWidth;

const getSelectedPageFromUrl = () => {
  const { hash } = window.location;

  if (hash.length) {
    return parseInt(hash.substring("#goToPage-".length), 10) || 0;
  }

  const matchFileNames = window.location.pathname.match(/page\d\d.html+/g);
  return matchFileNames && matchFileNames[0]
    ? parseInt(matchFileNames[0].match(/\d\d+/g)[0], 10) - 1
    : 0;
};

export const getPagesIndexesToLoad = (selectedPage, twoPagesLoaded, pages) => {
  const numberOfPages = pages.length;

  if (!numberOfPages) return [];

  let validSelectedPage =
    twoPagesLoaded && selectedPage % 2 ? selectedPage - 1 : selectedPage;

  if (validSelectedPage < 0) {
    validSelectedPage = 0;
  } else if (validSelectedPage >= numberOfPages) {
    validSelectedPage = numberOfPages - 1;
  }

  if (!twoPagesLoaded) {
    return [validSelectedPage];
  }
  return validSelectedPage === numberOfPages - 1
    ? [validSelectedPage]
    : [validSelectedPage, validSelectedPage + 1];
};

export const getComputedState = (
  reflowable,
  projectOriginalWidth,
  projectOriginalHeight,
  projectOrientation,
  pages,
  startPage = getSelectedPageFromUrl()
) => {
  const deviceOrientation = window.matchMedia("(orientation: landscape)")
    .matches
    ? Orientation.LANDSCAPE
    : Orientation.PORTRAIT;

  const twoPagesLoaded = shouldLoadTwoPages(
    reflowable,
    projectOrientation,
    deviceOrientation
  );

  const projectScaleFactor = getProjectScaleFactor(
    projectOriginalWidth,
    projectOriginalHeight,
    twoPagesLoaded
  );

  const projectWidth = getNewProjectWidth(
    projectOriginalWidth,
    projectScaleFactor,
    twoPagesLoaded
  );

  return {
    projectHeight: Math.floor(projectScaleFactor * projectOriginalHeight),
    projectWidth,
    projectScaleFactor,
    deviceOrientation,
    openedPages: getPagesIndexesToLoad(startPage, twoPagesLoaded, pages),
  };
};

export const updateUrlHash = (pageIndex) => {
  if (ENVIRONMENT_TARGET === "lesemester") return;

  if (pageIndex === undefined) return;
  const { location, history } = window;
  history.replaceState(
    null,
    null,
    `${location.href.replace(location.hash, "")}#goToPage-${pageIndex}`
  );
};

export const getBorderWeight = (borderStyle) =>
  parseInt(BorderStyleValues[borderStyle] || "", 10) || 0;

export const getRootProjectIdForCurrentProjectId = (
  relatedProjectsInfo,
  projectId
) => {
  if (relatedProjectsInfo[projectId]) return projectId;

  const rootProjectId = Object.keys(relatedProjectsInfo).reduce(
    (rootPId, key) => {
      if (rootPId) return rootPId;
      return relatedProjectsInfo[key].find((pId) => pId === projectId)
        ? key
        : undefined;
    },
    undefined
  );

  return rootProjectId || projectId;
};

const removeCurrentRelatedProjectsIdsFromStoredOnes = (
  persistentRelatedProjectsInfo,
  relatedProjectsIds
) => {
  const filteredRelatedProjectsInfo = {};
  const orphansProjectsIds = [];

  Object.keys(persistentRelatedProjectsInfo).forEach((pId) => {
    if (relatedProjectsIds.includes(parseInt(pId, 10))) {
      orphansProjectsIds.push(...persistentRelatedProjectsInfo[pId]);
    } else {
      filteredRelatedProjectsInfo[pId] = persistentRelatedProjectsInfo[
        pId
      ].filter((existingPid) => !relatedProjectsIds.includes(existingPid));
    }
  });

  return {
    filteredRelatedProjectsInfo,
    orphansProjectsIds,
  };
};

export const updateRelatedProjectsIds = async (
  projectId,
  relatedProjectsIds = []
) => {
  try {
    const existingRelatedProjectsInfo = (
      await DatabaseService.get("relatedProjectsInfo", { data: {} })
    ).data;

    const rootProjectId = getRootProjectIdForCurrentProjectId(
      existingRelatedProjectsInfo,
      projectId
    );

    const { filteredRelatedProjectsInfo, orphansProjectsIds } =
      removeCurrentRelatedProjectsIdsFromStoredOnes(
        existingRelatedProjectsInfo,
        relatedProjectsIds
      );

    if (rootProjectId !== projectId) {
      await DatabaseService.put("relatedProjectsInfo", {
        ...filteredRelatedProjectsInfo,
        [rootProjectId]: [
          ...filteredRelatedProjectsInfo[rootProjectId],
          ...relatedProjectsIds,
          ...orphansProjectsIds,
        ],
      });
    } else {
      await DatabaseService.put("relatedProjectsInfo", {
        ...filteredRelatedProjectsInfo,
        [projectId]: [...relatedProjectsIds, ...orphansProjectsIds],
      });
    }
  } catch (e) {
    await DatabaseService.put("relatedProjectsInfo", {
      [projectId]: relatedProjectsIds,
    });
  }
};

export const getRecordedSoundFileName = (
  openedPages,
  pages,
  pageModels,
  layers,
  settings
) => {
  const pageId = pages[openedPages[0]];
  const pageModel = pageModels[pageId];
  const soundType = ObjectType.SOUND;

  if (!settings.recordPerLayer) {
    return `projectId_${settings.projectId}_pageId_${pageId}_recorded.wav`;
  }

  const autostartActiveSounds = pageModel.objects.filter((objectModel) => {
    if (objectModel.type !== soundType || !objectModel.autoStart) return false;
    return layers[objectModel.layerId].active;
  });

  return autostartActiveSounds.length === 1
    ? `projectId_${settings.projectId}_pageId_${pageId}_layerId_${autostartActiveSounds[0].layerId}_recorded.wav`
    : `projectId_${settings.projectId}_pageId_${pageId}_recorded.wav`;
};

export const app = { isNative: NATIVE_APP };
