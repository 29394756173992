function GestureHandler(handlers) {
  let mousePressed = false;
  let touchStart = false;
  let mouseStartProperties;
  let longTouchTimer;
  let longTouchFired = false;
  let maxAllowedTapDistanceWasCrossed = false;
  let onClickExecutedFromTouch = false;
  let onClickExecutedFromMouse = false;

  const tapMaximumDistance = 5;

  const longTouchDuration = 750;

  const EventsToRegister = {
    TOUCH_START: "touchStart",
    TOUCH_END: "touchEnd",
    TOUCH_CANCEL: "touchCancel",
    MOUSE_DOWN: "mouseDown",
    MOUSE_UP: "mouseUp",
    MOUSE_LEAVE: "mouseLeave",
  };

  const registeredEvents = [];

  function getMouseEvent(e) {
    return {
      button: e.button,
      pageX: e.pageX,
      pageY: e.pageY,
      currentTarget: e.currentTarget,
      type: e.type,
      timeStamp: e.timeStamp,
      stopPropagation: e.stopPropagation,
      originalEvent: e,
      pressed: !!e.buttons,
    };
  }

  function getTouchEvent(e) {
    const touch = e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];
    return {
      pageX: touch.screenX,
      pageY: touch.screenY,
      currentTarget: e.currentTarget,
      type: e.type,
      timeStamp: e.timeStamp,
      stopPropagation: e.stopPropagation,
      originalEvent: e,
      pressed: true,
    };
  }

  function startLongTouchTimer(onLongTouch) {
    if (longTouchTimer) {
      window.clearTimeout(longTouchTimer);
    }
    longTouchTimer = window.setTimeout(() => {
      onLongTouch(mouseStartProperties);
      longTouchFired = true;
      window.clearTimeout(longTouchTimer);
      longTouchTimer = null;
    }, longTouchDuration);
  }

  function stopLongTouchTimer() {
    if (longTouchTimer) {
      window.clearTimeout(longTouchTimer);
      longTouchFired = false;
    }
  }

  const onTouchStart = (e) => {
    touchStart = true;
    registeredEvents.push(EventsToRegister.TOUCH_START);
    onClickExecutedFromTouch = false;
    maxAllowedTapDistanceWasCrossed = false;
    const touchProps = getTouchEvent(e);
    mouseStartProperties = getTouchEvent(e);
    const { onLongTouch } = handlers;
    if (handlers.onMouseDown) {
      handlers.onMouseDown(touchProps);
    }
    if (onLongTouch) {
      startLongTouchTimer(onLongTouch);
    }
  };
  const onTouchEnd = (e) => {
    const touchProps = getTouchEvent(e);
    if (handlers.onMouseUp) {
      handlers.onMouseUp({ ...touchProps, pressed: false });
    }
    const { onClick } = handlers;
    if (touchStart) {
      touchStart = false;
      if (!maxAllowedTapDistanceWasCrossed && !longTouchFired) {
        if (onClick && !onClickExecutedFromMouse) {
          onClickExecutedFromTouch = true;
          onClick(touchProps);
        }
      }
    }
    stopLongTouchTimer();
  };

  const onTouchMove = (e) => {
    const touchProps = getTouchEvent(e);
    const { onMouseMove } = handlers;
    if (onMouseMove) {
      onMouseMove(touchProps);
    }

    const { onClick, onLongTouch } = handlers;
    if (
      touchStart &&
      !maxAllowedTapDistanceWasCrossed &&
      (onClick || onLongTouch)
    ) {
      const { pageX, pageY } = touchProps;
      const { pageX: x, pageY: y } = mouseStartProperties;
      if (
        Math.abs(pageX - x) > tapMaximumDistance ||
        Math.abs(pageY - y) > tapMaximumDistance
      ) {
        maxAllowedTapDistanceWasCrossed = true;
        stopLongTouchTimer();
      }
    }
  };
  const onTouchCancel = (e) => {
    const touchProps = getTouchEvent(e);
    const { onMouseLeave } = handlers;
    if (onMouseLeave) {
      onMouseLeave(touchProps);
    }
  };
  const onMouseDown = (e) => {
    const { onLongTouch } = handlers;
    registeredEvents.push(EventsToRegister.MOUSE_DOWN);
    mousePressed = true;
    longTouchFired = false;
    maxAllowedTapDistanceWasCrossed = false;
    mouseStartProperties = { x: e.pageX, y: e.pageY, t: e.timeStamp };
    onClickExecutedFromMouse = false;
    if (handlers.onMouseDown) {
      handlers.onMouseDown(getMouseEvent(e));
    }
    if (onLongTouch) {
      startLongTouchTimer(onLongTouch);
    }
  };
  const onMouseUp = (e) => {
    const { onClick } = handlers;
    if (handlers.onMouseUp) {
      handlers.onMouseUp(getMouseEvent(e));
    }
    if (mousePressed) {
      mousePressed = false;
      if (!maxAllowedTapDistanceWasCrossed && !longTouchFired) {
        if (
          onClick &&
          !onClickExecutedFromTouch &&
          !registeredEvents.includes(EventsToRegister.TOUCH_START)
        ) {
          onClickExecutedFromMouse = true;
          onClick(e);
        }
      }
      registeredEvents.splice(0, registeredEvents.length);
    }
    stopLongTouchTimer();
  };
  const onMouseMove = (e) => {
    const { onClick, onLongTouch } = handlers;
    if (handlers.onMouseMove) {
      handlers.onMouseMove(getMouseEvent(e));
    }
    if (
      mousePressed &&
      !maxAllowedTapDistanceWasCrossed &&
      (onClick || onLongTouch)
    ) {
      const { pageX, pageY } = e;
      const { x, y } = mouseStartProperties;
      if (
        Math.abs(pageX - x) > tapMaximumDistance ||
        Math.abs(pageY - y) > tapMaximumDistance
      ) {
        maxAllowedTapDistanceWasCrossed = true;
        stopLongTouchTimer();
      }
    }
  };
  const onMouseEnter = (e) => {
    const { onMouseEnter: onEnter } = handlers;
    if (onEnter) {
      onEnter(e);
    }
  };
  const onMouseLeave = (e) => {
    const { onMouseLeave: onLeave } = handlers;
    if (onLeave) {
      onLeave(e);
    }
  };

  return {
    onTouchStart,
    onTouchEnd,
    onTouchCancel,
    onTouchMove,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseEnter,
    onMouseLeave,
  };
}

export default GestureHandler;
