import { GoToActionType } from "@constants/Constants";
import NavigationService from "@services/NavigationService";
import PlatformUtils from "@utils/PlatformUtils";

const execute = (action, options) => {
  const { type } = action;

  switch (type) {
    case GoToActionType.PROJECT: {
      const { shareURL, page } = action;
      NavigationService.open(shareURL, page, options.setLightBoxProps);
      break;
    }

    case GoToActionType.PAGE: {
      const { id } = action;
      options.onGoToPage(id);
      break;
    }
    case GoToActionType.EXTERNAL_URL: {
      const { url } = action;
      PlatformUtils.openUrl(url);
      break;
    }
    default: {
      break;
    }
  }
};

export default { execute };
