import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useResetRecoilState, useSetRecoilState, useRecoilState } from "recoil";
import HttpService from "@services/HttpService";
import {
  MenuState,
  OwnVoiceSoundState,
  PlayRecordedSoundState,
  ReadTextState,
  RecordingState,
  SoundEffectState,
  SoundState,
  TextState,
} from "@constants/Constants";

import {
  getComputedState,
  updateRelatedProjectsIds,
  updateUrlHash,
} from "@utils/Utils";
import {
  bottomMenuAtom,
  currentProjectModelAtom,
  layersAtom,
  layersClassesAtom,
  linkedProjectModelAtom,
  openedPagesAtom,
  deviceOrientationAtom,
  pagesAtom,
  pagesModelsAtom,
  projectSizeAtom,
  projectZoomFactorAtom,
  screenSizeAtom,
  settingsAtom,
  thumbnailsAtom,
  atoms,
} from "@stateManagement/Atoms";

import usePrevious from "@hooks/UsePrevious";
import LayersUtils from "@utils/LayersUtils";
import PlatformUtils from "@utils/PlatformUtils";

const loadThumbnails = async (thumbnails, setThumbnails) => {
  const loadedThumbnails = [];

  let thumbnailsIndex;
  for (
    thumbnailsIndex = 0;
    thumbnailsIndex < thumbnails.length;
    thumbnailsIndex += 1
  ) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const thumbnail = await PlatformUtils.loadThumbnail(
        thumbnails[thumbnailsIndex]
      );
      loadedThumbnails.push(thumbnail);
    } catch (e) {
      loadedThumbnails.push("default");
    }
    setThumbnails(
      new Array(thumbnails.length)
        .fill(1)
        .map((el, index) =>
          loadedThumbnails.length > index ? loadedThumbnails[index] : null
        )
    );
  }
};

const loadCustomActionsIfNeeded = (serverUrl, settings) =>
  new Promise((resolve, reject) => {
    const { customActionFileName } = settings;
    if (!customActionFileName) {
      delete window.tapbookauthor.customAction;
      resolve();
    } else {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = serverUrl + customActionFileName;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    }
  });

const getShareUrl = () =>
  new URLSearchParams(window.location.search).get("shareURL");

const AppLoader = ({
  menuSettings,
  shareUrl: externalShareUrl,
  language,
  serverUrl,
  startPage,
  setAppSettings,
}) => {
  const setBottomMenu = useSetRecoilState(bottomMenuAtom);
  const setCurrentProjectModel = useSetRecoilState(currentProjectModelAtom);
  const setLayers = useSetRecoilState(layersAtom);
  const setLayersClasses = useSetRecoilState(layersClassesAtom);
  const setLinkedProjectModel = useSetRecoilState(linkedProjectModelAtom);
  const setOpenedPages = useSetRecoilState(openedPagesAtom);
  const setDeviceOrientation = useSetRecoilState(deviceOrientationAtom);
  const setPages = useSetRecoilState(pagesAtom);
  const setPagesModels = useSetRecoilState(pagesModelsAtom);
  const setProjectSize = useSetRecoilState(projectSizeAtom);
  const setProjectZoomFactor = useSetRecoilState(projectZoomFactorAtom);
  const [projectSettings, setProjectSettings] = useRecoilState(settingsAtom);
  const setScreenSize = useSetRecoilState(screenSizeAtom);
  const setThumbnails = useSetRecoilState(thumbnailsAtom);

  const resetHandlers = useRef(
    (() => {
      const f = useResetRecoilState;
      return atoms.map((atom) => f(atom));
    })()
  );

  const [shareUrl, setShareUrl] = useState(externalShareUrl || getShareUrl());
  const previousShareUrl = usePrevious(shareUrl);

  useEffect(() => {
    PlatformUtils.setShareUrl(shareUrl);
  }, [shareUrl]);

  const setupApp = useCallback(
    async (model) => {
      const { layers, layersClasses, menu, pageModels, pages, settings } =
        model;

      await updateRelatedProjectsIds(
        settings.projectId,
        settings.relatedProjectsIds
      );

      const { hideNavigationOnFirstPage, height, width, reflowable, title } =
        settings;
      if (title) {
        document.title = title;
      }
      const { thumbnails } = menu;

      delete menu.thumbnails;
      delete menu.settings;

      const menuModel = {
        ...menu,
        ...menuSettings,
        hideNavigationOnFirstPage,
        reflowable,
        ownVoiceSoundState: OwnVoiceSoundState.OFF,
        playRecordedSoundState: PlayRecordedSoundState.OFF,
        readTextState: ReadTextState.ON,
        recordingState: RecordingState.OFF,
        state: MenuState.CLOSED,
        soundState: SoundState.ON,
        soundEffectState: SoundEffectState.ON,
        textState: TextState.DISPLAYED,
      };
      const computedSettings = getComputedState(
        reflowable,
        width,
        height,
        settings.orientation,
        pages,
        startPage
      );

      await loadCustomActionsIfNeeded(serverUrl, settings);

      updateUrlHash(computedSettings.openedPages[0]);

      setScreenSize({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      });

      setThumbnails(thumbnails.map(() => null));

      loadThumbnails(thumbnails, setThumbnails).then(/* do nothing */);

      setBottomMenu(menuModel);
      setCurrentProjectModel(model);

      const updatedLayers =
        await LayersUtils.getLayersAfterMergingWithPersistentLayersStoredOnClient(
          settings.projectId,
          layers
        );

      setLayers(updatedLayers);
      setLayersClasses(layersClasses);
      setOpenedPages(computedSettings.openedPages);
      setDeviceOrientation(computedSettings.deviceOrientation);
      setPages(pages);
      setPagesModels(pageModels);
      setProjectSize({
        projectWidth: computedSettings.projectWidth,
        projectHeight: computedSettings.projectHeight,
      });
      setProjectZoomFactor(computedSettings.projectScaleFactor);
      if (setAppSettings) {
        setAppSettings(settings);
      }
      setProjectSettings({
        ...settings,
        language: language === undefined ? settings.language : language,
      });

      const { orientation, linkedProjectUrl, linkedProjectOrientation } =
        settings;

      if (!linkedProjectUrl || orientation === linkedProjectOrientation) return;

      HttpService.fetchModel(linkedProjectUrl).then((relatedModel) => {
        setLinkedProjectModel(relatedModel);
      });
    },
    [
      language,
      menuSettings,
      serverUrl,
      setAppSettings,
      setBottomMenu,
      setCurrentProjectModel,
      setDeviceOrientation,
      setLayers,
      setLayersClasses,
      setLinkedProjectModel,
      setOpenedPages,
      setPages,
      setPagesModels,
      setProjectSettings,
      setProjectSize,
      setProjectZoomFactor,
      setScreenSize,
      setThumbnails,
      startPage,
    ]
  );

  useEffect(() => {
    const locationChangeListener = () => {
      const currentShareUrl = getShareUrl();
      if (shareUrl !== currentShareUrl) {
        setShareUrl(currentShareUrl);
      }
    };

    window.addEventListener("locationChange", locationChangeListener, false);

    return () => {
      window.removeEventListener(
        "locationChange",
        locationChangeListener,
        false
      );
    };
  }, [shareUrl]);

  const resetApp = useCallback(() => {
    resetHandlers.current.forEach((handler) => handler());
  }, []);

  useEffect(() => {
    if (!shareUrl || shareUrl === previousShareUrl) {
      return;
    }
    if (previousShareUrl) {
      setProjectSettings({ ...projectSettings, projectId: undefined });
      resetApp();
    }
    HttpService.fetchModel(PlatformUtils.getModelUrl(shareUrl)).then((model) =>
      setupApp(model)
    );
  }, [
    previousShareUrl,
    projectSettings,
    resetApp,
    setProjectSettings,
    setupApp,
    shareUrl,
  ]);

  return null;
};

AppLoader.defaultProps = {
  language: undefined,
  menuSettings: {},
  pathPrefix: undefined,
  shareUrl: undefined,
  startPage: undefined,
  setAppSettings: undefined,
};
AppLoader.propTypes = {
  language: PropTypes.number,
  menuSettings: PropTypes.shape({}),
  pathPrefix: PropTypes.string,
  shareUrl: PropTypes.string,
  startPage: PropTypes.number,
  setAppSettings: PropTypes.func,
};

export default AppLoader;
