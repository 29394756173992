import ShortId from "@utils/ShortId";

const activeObservers = [];
const supportedElementsTypes = {
  Element: 1,
  Text: 3,
  Document: 9,
};
let animationFrame = null;

const animationFrameCallback = () => {
  activeObservers.forEach(
    (
      { id, callback, element, width: previousWidth, height: previousHeight },
      index
    ) => {
      const width = element.clientWidth;
      const height = element.clientHeight;

      if (width !== previousWidth || height !== previousHeight) {
        callback(element, { previousWidth, previousHeight, width, height });
      }
      activeObservers[index] = {
        id,
        callback,
        element,
        width,
        height,
      };
    }
  );
  animationFrame = window.requestAnimationFrame(animationFrameCallback);
};

const removeObserver = (array, observerId) => {
  const currentObserverIndex = array.findIndex(
    (observer) => observerId === observer.id
  );
  if (currentObserverIndex === -1) return;

  array.splice(currentObserverIndex, 1);
};

function ResizeObserver(callback) {
  if (!(this instanceof ResizeObserver)) {
    throw new Error("ResizeObserver should be created with `new`");
  }

  const id = ShortId.generate();

  const observe = (element) => {
    if (
      !element ||
      !element.nodeType ||
      !Object.values(supportedElementsTypes).includes(element.nodeType)
    ) {
      throw new Error(`Element is not valid or is not supported. 
                         Supported element types are: element(1), text(3) and document(9).
                         Please check https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType for more details`);
    }
    const width = element.clientWidth;
    const height = element.clientHeight;

    setTimeout(() => {
      activeObservers.push({
        id,
        callback,
        element,
        width,
        height,
      });
      if (animationFrame) return;

      animationFrame = window.requestAnimationFrame(animationFrameCallback);
    }, 0);
  };
  const unobserve = () => {
    window.cancelAnimationFrame(animationFrame);
    animationFrame = null;
    setTimeout(() => {
      removeObserver(activeObservers, id);
      if (activeObservers.length) {
        animationFrame = window.requestAnimationFrame(animationFrameCallback);
      }
    }, 0);
  };
  const disconnect = () => {
    unobserve();
  };

  return {
    observe,
    unobserve,
    disconnect,
  };
}

export default ResizeObserver;
