import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import useComponentDidMount from "@hooks/UseComponentDidMount";
import PlatformUtils from "@utils/PlatformUtils";
import { Interactive } from "@src/GlobalStyles";
import usePrevious from "@hooks/UsePrevious";
import { StylesPrefixes } from "@constants/Constants";

const StyledContainer = styled(Interactive)`
  ${({ width, height, overflow }) => `
     width:${width};
     height:${height};
     overflow:${overflow};
     pointer-events:${overflow === "hidden" ? "none" : "all"};
  `}
`;

const ZoomAndPanContainer = styled.div`
  ${({
    width,
    height,
    transform,
    transformOrigin,
    transitionDuration,
    transitionDelay,
  }) => `
     width:${width};
     height:${height};
     ${transform && `transform: ${transform};`}
     ${transformOrigin && `transform-origin: ${transformOrigin};`}
     ${transitionDuration && `transition-duration: ${transitionDuration};`}
     ${transitionDelay && `transition-delay: ${transitionDelay};`}
    ;
  `}
`;

const StyledImage = styled.img`
  position: relative;
  ${({ imageStyles }) =>
    Object.keys(imageStyles)
      .map((key) => `${StylesPrefixes[key]}${imageStyles[key]};`)
      .join("\n")}
`;

const getImageStyles = (zoomAndPan) =>
  zoomAndPan
    ? zoomAndPan.settings
    : {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      };

const Container = ({ children, show, ...props }) =>
  show ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledContainer {...props}>{children}</StyledContainer>
  ) : (
    <>{children}</>
  );

Container.propTypes = {
  children: PropTypes.number.isRequired,
  show: PropTypes.number.isRequired,
};

const PanContainer = ({ active, children, settings }) => {
  if (!settings) return <>{children}</>;
  const { move } = settings;

  if (!move) return <>{children}</>;

  return (
    <ZoomAndPanContainer
      width="100%"
      height="100%"
      transform={
        active ? `translate3D(${move.xOffset}, ${move.yOffset}, 0)` : ""
      }
      transformOrigin=""
      transitionDuration={active ? `${move.duration}ms` : ""}
      transitionDelay={active ? `${move.delay}ms` : ""}
    >
      {children}
    </ZoomAndPanContainer>
  );
};

PanContainer.propTypes = {
  active: PropTypes.number.isRequired,
  children: PropTypes.number.isRequired,
  settings: PropTypes.number.isRequired,
};
const ZoomContainer = ({ active, children, settings }) => {
  if (!settings) return <>{children}</>;
  const { zoom } = settings;

  if (!zoom) return <>{children}</>;

  return (
    <ZoomAndPanContainer
      width="100%"
      height="100%"
      transform={active ? `scale(${zoom.zoomFactor})` : ""}
      transformOrigin={
        active ? `${zoom.translateOriginX} ${zoom.translateOriginY}` : ""
      }
      transitionDuration={active ? `${zoom.duration}ms` : ""}
      transitionDelay={active ? `${zoom.delay}ms` : ""}
    >
      {children}
    </ZoomAndPanContainer>
  );
};

ZoomContainer.propTypes = {
  active: PropTypes.number.isRequired,
  children: PropTypes.number.isRequired,
  settings: PropTypes.number.isRequired,
};

const Image = React.memo(
  ({
    activatedAt,
    autoFit,
    height,
    id,
    onObjectLoad,
    onObjectStopDragging,
    onObjectStartDragging,
    pageActivatedAt,
    projectWidth,
    projectHeight,
    scrollable,
    scrollableWithinPage,
    url,
    width,
    zoomFactor,
    zoomAndPan,
  }) => {
    const previousPageActivationTime = usePrevious(pageActivatedAt);

    const imageRef = useRef();

    const imageStyles = useRef(getImageStyles(zoomAndPan));

    const getContainerStyle = useCallback(() => {
      if (scrollableWithinPage) {
        return projectHeight < width || projectHeight < height
          ? {
              width: `${zoomFactor * projectWidth}px`,
              height: `${zoomFactor * projectHeight}px`,
              overflow: "auto",
              onMouseDown: onObjectStartDragging,
              onMouseUp: onObjectStopDragging,
            }
          : {
              width: "100%",
              height: "100%",
              overflow: "hidden",
              onMouseDown: onObjectStartDragging,
              onMouseUp: onObjectStopDragging,
            };
      }

      if (autoFit) {
        return {
          width: "100%",
          height: "100%",
          overflow: "hidden",
        };
      }

      return {
        width: `${zoomFactor * width}px`,
        height: `${zoomFactor * height}px`,
        overflow: scrollable ? "auto" : "hidden",
      };
    }, [
      autoFit,
      height,
      onObjectStartDragging,
      onObjectStopDragging,
      projectHeight,
      projectWidth,
      scrollable,
      scrollableWithinPage,
      width,
      zoomFactor,
    ]);

    const getImageSize = useCallback(() => {
      if (autoFit || scrollableWithinPage) {
        return {
          width: "100%",
          height: "100%",
        };
      }
      return {};
    }, [autoFit, scrollableWithinPage]);

    const [containerStyle, setContainerStyle] = useState(getContainerStyle());
    const [imageSize, setImageSize] = useState(getImageSize());

    useEffect(() => {
      setContainerStyle(getContainerStyle());
      setImageSize(getImageSize());
    }, [getContainerStyle, getImageSize]);

    useEffect(() => {
      if (pageActivatedAt || !previousPageActivationTime) {
        return;
      }
      const { parentElement } = imageRef.current;
      if (parentElement.scrollTop) {
        parentElement.scrollTop = 0;
      }
      if (parentElement.scrollLeft) {
        parentElement.scrollLeft = 0;
      }
    }, [pageActivatedAt, previousPageActivationTime]);

    useComponentDidMount(() => {
      onObjectLoad(id);
    });
    return (
      <Container
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...containerStyle}
        show={autoFit && !scrollableWithinPage}
      >
        <Container
          width={`${zoomFactor * width}px`}
          height={`${zoomFactor * height}px}`}
          overflow="hidden"
          show={scrollableWithinPage}
        >
          <ZoomContainer settings={zoomAndPan} active={!!activatedAt}>
            <PanContainer settings={zoomAndPan} active={!!activatedAt}>
              <StyledImage
                imageStyles={imageStyles.current}
                ref={imageRef}
                src={PlatformUtils.getResourceUrl(url)}
                height={
                  !autoFit && !scrollableWithinPage ? imageSize.height : "100%"
                }
                width={
                  !autoFit && !scrollableWithinPage ? imageSize.width : "100%"
                }
              />
            </PanContainer>
          </ZoomContainer>
        </Container>
      </Container>
    );
  }
);

Image.defaultProps = {
  activatedAt: undefined,
  autoFit: true,
  pageActivatedAt: undefined,
  scrollable: false,
  scrollableWithinPage: false,
  zoomAndPan: undefined,
};

Image.propTypes = {
  activatedAt: PropTypes.number,
  autoFit: PropTypes.bool,
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onObjectLoad: PropTypes.func.isRequired,
  onObjectStopDragging: PropTypes.func.isRequired,
  onObjectStartDragging: PropTypes.func.isRequired,
  pageActivatedAt: PropTypes.number,
  projectWidth: PropTypes.number.isRequired,
  projectHeight: PropTypes.number.isRequired,
  scrollable: PropTypes.bool,
  scrollableWithinPage: PropTypes.bool,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number.isRequired,
  zoomAndPan: PropTypes.shape({
    settings: PropTypes.shape({
      top: PropTypes.string.isRequired,
      left: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
      height: PropTypes.string.isRequired,
    }).isRequired,
    move: PropTypes.shape({
      duration: PropTypes.number.isRequired,
      yOffset: PropTypes.string.isRequired,
      xOffset: PropTypes.string.isRequired,
      delay: PropTypes.number.isRequired,
    }),
    zoom: PropTypes.shape({
      duration: PropTypes.number.isRequired,
      translateOriginX: PropTypes.string.isRequired,
      translateOriginY: PropTypes.string.isRequired,
      delay: PropTypes.number.isRequired,
      zoomFactor: PropTypes.number.isRequired,
    }),
  }),
};

export default Image;
