import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";

const getIconSrc = (
  disabledImgSrc,
  selectedStateImgSrc,
  unselectedStateImgSrc,
  disabled,
  selected
) => {
  if (disabled && disabledImgSrc) return disabledImgSrc;

  return selected
    ? selectedStateImgSrc || unselectedStateImgSrc
    : unselectedStateImgSrc;
};

const ImgButton = ({
  className,
  disabled,
  disabledImgSrc,
  selected,
  selectedStateImgSrc,
  unselectedStateImgSrc,
  onClick,
}) => (
  <Button className={className} disabled={disabled} onClick={onClick}>
    <img
      alt=""
      src={getIconSrc(
        disabledImgSrc,
        selectedStateImgSrc,
        unselectedStateImgSrc,
        disabled,
        selected
      )}
    />
  </Button>
);

ImgButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledImgSrc: PropTypes.string,
  selected: PropTypes.bool,
  selectedStateImgSrc: PropTypes.string,
  unselectedStateImgSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ImgButton.defaultProps = {
  className: "",
  disabled: false,
  disabledImgSrc: null,
  selected: false,
  selectedStateImgSrc: null,
};

export default ImgButton;
