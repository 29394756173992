import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useRecoilValue, useRecoilState } from "recoil";

import LeftSideBar from "@components/sidebars/LeftSideBar";
import RightSideBar from "@components/sidebars/RightSideBar";

import Content from "@components/Content";

import LoadingSpinner from "@src/LoadingSpinner";

import ResizeObserver from "@observers/ResizeObserver";

import {
  getNewProjectWidth,
  getPagesIndexesToLoad,
  getProjectScaleFactor,
  isSideBarPossible,
  shouldLoadTwoPages,
} from "@utils/Utils";
import {
  deviceOrientationAtom,
  openedPagesAtom,
  pagesAtom,
  settingsAtom,
  projectSizeAtom,
  projectZoomFactorAtom,
  screenSizeAtom,
} from "@stateManagement/Atoms";
import BottomMenu from "@components/bottomMenu/BottomMenu";
import { Orientation } from "@constants/Constants";
import UpdateContentLightBox from "@components/lightbox/UpdateContentLightBox";
import UserNotifications from "@components/notifications/UserNotifications";

const rootElementStyle = document.documentElement.style;

const resizeObserverCallbackGenerator =
  (
    deviceOrientation,
    openedPages,
    pages,
    projectOrientation,
    projectOriginalHeight,
    projectOriginalWidth,
    projectScaleFactor,
    reflowable,
    setScreenSize,
    setDeviceOrientation,
    setOpenedPages,
    setProjectScaleFactor,
    setProjectSize
  ) =>
  (element, { previousWidth, width, height }) => {
    const newDeviceOrientation =
      width >= height ? Orientation.LANDSCAPE : Orientation.PORTRAIT;

    const twoPagesLoaded = shouldLoadTwoPages(
      reflowable,
      projectOrientation,
      newDeviceOrientation
    );

    const projectNewScaleFactor = getProjectScaleFactor(
      projectOriginalWidth,
      projectOriginalHeight,
      twoPagesLoaded
    );

    if (
      width === previousWidth &&
      projectNewScaleFactor === projectScaleFactor
    ) {
      return;
    }

    const newOpenedPages = getPagesIndexesToLoad(
      openedPages[0],
      twoPagesLoaded,
      pages
    );

    setProjectSize({
      projectWidth: getNewProjectWidth(
        projectOriginalWidth,
        projectNewScaleFactor,
        twoPagesLoaded
      ),
      projectHeight: Math.floor(projectNewScaleFactor * projectOriginalHeight),
    });
    setScreenSize({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });

    setDeviceOrientation(newDeviceOrientation);

    if (newOpenedPages) {
      setOpenedPages(newOpenedPages);
    }

    if (projectScaleFactor !== projectNewScaleFactor) {
      setProjectScaleFactor(projectNewScaleFactor);
      rootElementStyle.setProperty(
        "--tba-player-zoom-factor",
        `${projectNewScaleFactor}px`
      );
    }
  };

const App = ({ children, hasSound }) => {
  const [openedPages, setOpenedPages] = useRecoilState(openedPagesAtom);
  const [projectScaleFactor, setProjectScaleFactor] = useRecoilState(
    projectZoomFactorAtom
  );

  const [{ projectWidth }, setProjectSize] = useRecoilState(projectSizeAtom);
  const [deviceOrientation, setDeviceOrientation] = useRecoilState(
    deviceOrientationAtom
  );
  const {
    projectId,
    reflowable,
    orientation: projectOrientation,
    width: projectOriginalWidth,
    height: projectOriginalHeight,
  } = useRecoilValue(settingsAtom);

  const pages = useRecoilValue(pagesAtom);
  const [{ screenWidth }, setScreenSize] = useRecoilState(screenSizeAtom);

  useEffect(() => {
    if (!projectId || !openedPages) return undefined;
    const rootElement = document.getElementById("root");

    const resizeObserverCallback = resizeObserverCallbackGenerator(
      deviceOrientation,
      openedPages,
      pages,
      projectOrientation,
      projectOriginalHeight,
      projectOriginalWidth,
      projectScaleFactor,
      reflowable,
      setScreenSize,
      setDeviceOrientation,
      setOpenedPages,
      setProjectScaleFactor,
      setProjectSize
    );

    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    resizeObserver.observe(rootElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [
    deviceOrientation,
    openedPages,
    pages,
    projectId,
    projectOrientation,
    projectOriginalHeight,
    projectOriginalWidth,
    projectScaleFactor,
    reflowable,
    setDeviceOrientation,
    setOpenedPages,
    setProjectScaleFactor,
    setProjectSize,
    setScreenSize,
  ]);

  const sideBarVisible = isSideBarPossible(screenWidth, projectWidth);
  return (
    <>
      {projectId && openedPages && (
        <>
          {(sideBarVisible || null) && <LeftSideBar visible={sideBarVisible} />}
          <Content />
          {(sideBarVisible || null) && (
            <RightSideBar visible={sideBarVisible} />
          )}
          <BottomMenu hasSound={hasSound} />
          <UserNotifications />
          <UpdateContentLightBox />
        </>
      )}
      {children}
      {!projectId && <LoadingSpinner />}
    </>
  );
};

App.defaultProps = {
  children: null,
  hasSound: undefined,
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hasSound: PropTypes.bool,
};

export default App;
