import {
  ActionType,
  AnimationType,
  LightBoxType,
  MenuState,
} from "@constants/Constants";
import CssAnimationsManager from "./CssAnimationsManager";
import { getBorderWeight } from "./Utils";
import HistoryManager from "./HistoryManager";
import { getCaption } from "./CaptionsUtils";

const actionsThatNeedToBeExecutedOnce = [
  ActionType.TOGGLE_VISIBILITY_ONCE,
  ActionType.FADE_VISIBILITY_ONCE,
  ActionType.PULSATE_ONLY_ONCE,
];

const getPageTranslateSettings = ({
  borderStyle,
  objectHeight,
  objectLeft,
  objectTop,
  objectWidth,
  pageAbsoluteHeight,
  pageAbsoluteWidth,
  zoomFactor,
}) => {
  const marginWeight = 2 * getBorderWeight(borderStyle);
  return {
    fx: (
      zoomFactor *
      ((objectWidth * objectLeft) / (pageAbsoluteWidth - objectWidth) +
        objectLeft)
    ).toFixed(4),
    fy: (
      zoomFactor *
      ((objectHeight * objectTop) / (pageAbsoluteHeight - objectHeight) +
        objectTop)
    ).toFixed(4),
    scaleFactor: Math.min(
      pageAbsoluteWidth / (objectWidth + marginWeight),
      pageAbsoluteHeight / (objectHeight + marginWeight)
    ),
  };
};

const executeToggleVisibilityAction = ({
  initialOpacity,
  styleProps,
  setStyleProps,
}) => {
  const newOpacity = initialOpacity === styleProps.opacity ? 0 : initialOpacity;
  setStyleProps({ ...styleProps, opacity: newOpacity });

  return true;
};

const executeFadeVisibilityAction = (model, { styleProps, setStyleProps }) => {
  const {
    action: { duration },
    initialOpacity,
  } = model;

  const newOpacity = initialOpacity === styleProps.opacity ? 0 : initialOpacity;
  setStyleProps({
    ...styleProps,
    opacity: newOpacity,
    transition: `opacity ${duration}ms linear 0s`,
  });

  return true;
};

const executePulseAction = async (model, numberOfPulses, options) => {
  const { action } = model;
  const { duration } = action;

  return CssAnimationsManager.animate(
    AnimationType.PULSE,
    model,
    {
      animationDuration: `${duration}ms`,
      animationTiming: "linear",
      animationIterationCount: numberOfPulses,
    },
    options
  );
};

const executeRotateAction = (model, options) => {
  const { action } = model;
  const { iterations, rotationDuration } = action;

  return CssAnimationsManager.animate(
    AnimationType.ANIMATED_ROTATION,
    null,
    {
      animationDuration: `${rotationDuration}ms`,
      animationTiming: "linear",
      animationIterationCount: iterations,
    },
    options
  );
};

const executeZoomOutStartingPointAction = async (animationProps) => {
  const translateSettings = getPageTranslateSettings(animationProps);

  return CssAnimationsManager.animate(
    AnimationType.ZOOM_OUT_PAGE,
    null,
    {
      transformOrigin: `${translateSettings.fx}px ${translateSettings.fy}px`,
      transform: `scale(${
        animationProps.animationStartZoomFactor || translateSettings.scaleFactor
      })`,
      animationDuration: `${animationProps.duration}ms`,
      animationTiming: `cubic-bezier(${animationProps.bezier})`,
      animationIterationCount: 1,
      animationDelay: `${
        animationProps.userInteracted ? 0 : animationProps.delay
      }ms`,
      animationFillMode: "forwards",
    },
    animationProps
  );
};

const executeZoomInStartingPointAction = async (animationProps) => {
  if (animationProps.executedActionsTimes === 1) {
    return executeZoomOutStartingPointAction(animationProps);
  }

  const translateSettings = getPageTranslateSettings(animationProps);

  animationProps.setStyleProps({
    width: animationProps.width,
    transformOrigin: `${translateSettings.fx}px ${translateSettings.fy}px`,
    transition: `transform ${animationProps.duration}ms cubic-bezier(${animationProps.bezier}) ${animationProps.delay}ms`,
    transform: `scale(${translateSettings.scaleFactor})`,
  });

  return true;
};

const execute = (objectModel, options) => {
  const { action } = objectModel;
  const { type } = action;
  const { executedActionsTimes } = options;

  if (
    actionsThatNeedToBeExecutedOnce.includes(type) &&
    executedActionsTimes === 1
  )
    return false;
  switch (type) {
    case ActionType.ZOOM_IN_STARTING_POINT: {
      return executeZoomInStartingPointAction(options);
    }

    case ActionType.ZOOM_OUT_STARTING_POINT: {
      return executeZoomOutStartingPointAction(options);
    }
    case ActionType.TOGGLE_BOTTOM_MENU: {
      const { menuModel, setMenuModel } = options;
      const { state } = menuModel;
      setMenuModel({
        ...menuModel,
        state: state === MenuState.OPEN ? MenuState.CLOSED : MenuState.OPEN,
      });
      return true;
    }
    case ActionType.TOGGLE_VISIBILITY_ONCE: {
      return executeToggleVisibilityAction(options);
    }
    case ActionType.TOGGLE_VISIBILITY: {
      return executeToggleVisibilityAction(options);
    }
    case ActionType.FADE_VISIBILITY_ONCE: {
      return executeFadeVisibilityAction(objectModel, options);
    }
    case ActionType.FADE_VISIBILITY: {
      return executeFadeVisibilityAction(objectModel, options);
    }
    case ActionType.PULSATE_ONLY_ONCE: {
      return executePulseAction(objectModel, 1, options);
    }
    case ActionType.PULSATE_ONCE: {
      return executePulseAction(objectModel, 1, options);
    }
    case ActionType.PULSATE: {
      return executePulseAction(objectModel, "infinite", options);
    }
    case ActionType.ANIMATED_ROTATION: {
      return executeRotateAction(objectModel, options);
    }

    case ActionType.BACK: {
      HistoryManager.back();
      return true;
    }

    case ActionType.START_RECORDING_WIZARD: {
      window.NotificationManager.showError(
        getCaption("not.supported.in.this.version")
      );
      return true;
    }
    case ActionType.START_PLAY_RECORDED_SOUNDS: {
      // AudioPlayer.startRecordedSoundsAutoPlay();
      window.NotificationManager.showError(
        getCaption("not.supported.in.this.version")
      );
      return true;
    }
    case ActionType.STOP_PLAY_RECORDED_SOUNDS: {
      window.NotificationManager.showError(
        getCaption("not.supported.in.this.version")
      );
      // AudioPlayer.stopRecordedSoundsAutoPlay();
      return true;
    }
    case ActionType.CLOSE: {
      options.setLightBoxProps(null);
      return true;
    }
    case ActionType.UNLOCK_VIA_SS: {
      options.setLightBoxProps({ type: LightBoxType.SS_UNLOCK });
      return true;
    }
    case ActionType.EVALUATE: {
      // TODO: implement it after Evaluate object is added
      return true;
    }
    case ActionType.RESET_EVALUATION: {
      // TODO: implement it after Evaluate object is added
      return true;
    }
    case ActionType.API_ACTION: {
      window.tapbookauthor.customAction.process(action);
      return true;
    }
    case ActionType.PRICE_CONTAINER: {
      return true;
    }
    case ActionType.PRINT: {
      window.print();
      return true;
    }
    default: {
      return false;
    }
  }
};

export default { execute };
