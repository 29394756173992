const recordedSounds = {};
let soundRecorderStream;
let audioRecorder;

function Recorder(source, cfg = {}) {
  const bufferLen = cfg.bufferLen || 4096;

  const recordingWorker = new Worker(
    new URL("recorder.worker", import.meta.url)
  );
  const { context } = source;
  const node = context.createScriptProcessor(bufferLen, 2, 2);

  recordingWorker.postMessage({
    command: "init",
    config: {
      sampleRate: context.sampleRate,
    },
  });
  let recording = false;
  let currCallback;

  node.onaudioprocess = (e) => {
    if (!recording) return;
    recordingWorker.postMessage({
      command: "record",
      buffer: [
        e.inputBuffer.getChannelData(0),
        e.inputBuffer.getChannelData(1),
      ],
    });
  };

  const record = () => {
    recording = true;
  };

  const stop = () => {
    recording = false;
  };
  const stopWorker = () => {
    recordingWorker.postMessage({
      command: "close",
    });
  };
  const disconnect = () => {
    node.disconnect();
  };

  const clear = () => {
    recordingWorker.postMessage({ command: "clear" });
  };

  const getBuffers = (cb) => {
    currCallback = cb || cfg.callback;
    recordingWorker.postMessage({ command: "getBuffers" });
  };

  const exportWAV = (cb, type) => {
    currCallback = cb || cfg.callback;
    if (!currCallback) throw new Error("Callback not set");
    recordingWorker.postMessage({
      command: "exportWAV",
      type: type || cfg.type || "audio/wav",
    });
  };

  const exportMonoWAV = (cb, type) => {
    currCallback = cb || cfg.callback;
    if (!currCallback) throw new Error("Callback not set");
    recordingWorker.postMessage({
      command: "exportMonoWAV",
      type: type || cfg.type || "audio/wav",
    });
  };

  recordingWorker.onmessage = (e) => {
    const blob = e.data;
    currCallback(blob);
  };

  source.connect(node);
  // if the script node is not connected to an output the "onaudioprocess" event is not triggered in chrome.
  node.connect(context.destination);

  return {
    disconnect,
    record,
    stop,
    stopWorker,
    clear,
    getBuffers,
    exportWAV,
    exportMonoWAV,
  };
}

const gotStream = (stream) => {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContext = new AudioContext();
  const audioInput = audioContext.createMediaStreamSource(stream);
  const inputPoint = audioContext.createGain();
  const analyserNode = audioContext.createAnalyser();
  const zeroGain = audioContext.createGain();

  soundRecorderStream = stream;

  // Create an AudioNode from the stream.
  audioInput.connect(inputPoint);

  analyserNode.fftSize = 2048;
  inputPoint.connect(analyserNode);

  audioRecorder = new Recorder(inputPoint);

  zeroGain.gain.value = 0.0;
  inputPoint.connect(zeroGain);
  zeroGain.connect(audioContext.destination);
  audioRecorder.record();
};

const record = () => {
  navigator.mediaDevices
    .getUserMedia({
      audio: {
        mandatory: {
          googEchoCancellation: "false",
          googAutoGainControl: "false",
          googNoiseSuppression: "false",
          googHighpassFilter: "false",
        },
        optional: [],
      },
    })
    .then(gotStream)
    .catch(
      (
        e // todo: create a dialog
      ) => alert(`Error getting audio${e.message}`)
    );
};
const cancelRecording = () => {};

const stopAndSaveRecording = (fileName) =>
  new Promise((resolve) => {
    audioRecorder.stop();
    audioRecorder.exportWAV((blob) => {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        recordedSounds[fileName] = reader.result.toString();
        resolve();
      });

      reader.readAsDataURL(blob);
      audioRecorder.stopWorker();
      soundRecorderStream.getTracks().forEach((track) => {
        track.stop();
      });
      audioRecorder.disconnect();
    });
  });

const getRecordedSounds = () => recordedSounds;

export default {
  record,
  stopAndSaveRecording,
  cancelRecording,
  getRecordedSounds,
};
