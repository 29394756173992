import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { NotificationTypes } from "@constants/Constants";
import { CenterVertically, Interactive, OpenSans400 } from "@src/GlobalStyles";
import useCallbackRef from "@hooks/UseCallbackRef";
import removeButtonIcon from "./gfx/close.svg";

const Container = styled.div`
  ${CenterVertically};
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 10px;
  transition: opacity 0.3s linear;
  background-color: var(
    --${({ type }) => {
        switch (type) {
          case NotificationTypes.SUCCESS:
            return "boring-green";
          case NotificationTypes.ERROR:
            return "red";
          case NotificationTypes.INFO:
            return "dark-grey-blue";
          default:
            return "transparent";
        }
      }}
  );
`;

const Message = styled.div`
  ${OpenSans400};
  margin-left: 8px;
  color: var(--pale-grey);
`;

const RemoveButtonContainer = styled(Interactive)`
  width: 24px;
  height: 24px;
  margin-left: 16px;
  cursor: pointer;
`;

const RemoveButtonIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const UserNotificationItem = ({ id, type, message, onDismiss }) => {
  const [opacity, setOpacity] = useState(1);

  const onUserRemove = useCallback(() => {
    setOpacity(0);
  }, []);

  const containerCallbackRef = useCallbackRef((node) => {
    const fadingDone = () => {
      onDismiss(id);
    };
    let timeout;

    node.addEventListener("transitionend", fadingDone);

    if (type !== NotificationTypes.ERROR) {
      timeout = setTimeout(() => {
        setOpacity(0);
      }, 5000);
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
      node.removeEventListener("transitionend", fadingDone);
    };
  });

  return (
    <Container ref={containerCallbackRef} type={type} style={{ opacity }}>
      <Message>{message}</Message>
      <RemoveButtonContainer onClick={onUserRemove}>
        <RemoveButtonIcon src={removeButtonIcon} />
      </RemoveButtonContainer>
    </Container>
  );
};

UserNotificationItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    NotificationTypes.ERROR,
    NotificationTypes.SUCCESS,
    NotificationTypes.SUCCESS,
  ]).isRequired,
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default UserNotificationItem;
