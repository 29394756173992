import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { ObjectType } from "@constants/Constants";
import InteractivePageObjectSection from "./InteractivePageObjectSection";
import HotPoint from "./HotPoint";
import Image from "./Image";
import Input from "./Input";
import Sound from "./Sound";
import Text from "./Text";
import Package from "./Package";
import Drawing from "./drawing/Drawing";
import TimedEvent from "./TimedEvent";
import QuizTest from "./QuizTest";
import Video from "./Video";
import Anchor from "./Anchor";

const { round } = Math;

const interactiveObjectTypes = [ObjectType.SOUND];

const isObjectInteractive = ({ type }) => interactiveObjectTypes.includes(type);

const getObjectComponent = (
  karaokeSyncIndex,
  karaokeSoundSyncPoints,
  model,
  onKaraokeSoundTimeUpdate,
  onKaraokeTextLoaded,
  onObjectLoad,
  onObjectStartDragging,
  onObjectStopDragging,
  zoomFactor,
  activatedAt,
  activatedByLinkProps,
  pageActivatedAt,
  recordedSoundId,
  defaultSoundAccessRight,
  recordedSoundAccessRight,
  projectHeight,
  projectWidth,
  backgroundOpacity,
  backgroundColor,
  zoomAndPan
) => {
  const { type } = model;
  switch (type) {
    case ObjectType.HOTPOINT: {
      return <HotPoint id={model.id} onObjectLoad={onObjectLoad} />;
    }
    case ObjectType.INPUT: {
      return (
        <Input
          emailInfo={model.emailInfo}
          id={model.id}
          onObjectLoad={onObjectLoad}
          placeholder={model.placeholder}
          structure={model.structure}
          zoomFactor={zoomFactor}
        />
      );
    }
    case ObjectType.SOUND: {
      return (
        <Sound
          activatedAt={activatedAt}
          activatedByLinkProps={activatedByLinkProps}
          defaultSoundAccessRight={defaultSoundAccessRight}
          display={model.display}
          id={model.id}
          karaokeConfig={model.karaokeConfig}
          loop={model.loop}
          onKaraokeSoundTimeUpdate={onKaraokeSoundTimeUpdate}
          onObjectLoad={onObjectLoad}
          onObjectStartDragging={onObjectStartDragging}
          onObjectStopDragging={onObjectStopDragging}
          pageActivatedAt={pageActivatedAt}
          path={model.path}
          recordedSoundAccessRight={recordedSoundAccessRight}
          recordedSoundId={recordedSoundId}
          zoomFactor={zoomFactor}
        />
      );
    }
    case ObjectType.TEXT: {
      return (
        <Text
          backgroundColor={backgroundColor}
          backgroundOpacity={backgroundOpacity}
          content={model.content}
          height={model.height}
          id={model.id}
          karaokeSyncIndex={karaokeSyncIndex}
          onObjectLoad={onObjectLoad}
          onKaraokeTextLoaded={onKaraokeTextLoaded}
          syncPoints={karaokeSoundSyncPoints}
          width={model.width}
          zoomFactor={zoomFactor}
        />
      );
    }
    case ObjectType.PACKAGE: {
      return (
        <Package
          height={model.height}
          id={model.id}
          onObjectLoad={onObjectLoad}
          width={model.width}
          zoomFactor={zoomFactor}
          url={model.url}
        />
      );
    }
    case ObjectType.DRAWING: {
      return (
        <Drawing
          borderStyle={model.borderStyle}
          colorChooserAvailable={model.colorChooserAvailable}
          drawingToolAlwaysActive={model.drawingToolAlwaysActive}
          height={round(zoomFactor * model.height)}
          id={model.id}
          lineColor={model.lineColor}
          lineWidth={round(zoomFactor * (model.lineWidth || 1))}
          onObjectLoad={onObjectLoad}
          onObjectStartDragging={onObjectStartDragging}
          onObjectStopDragging={onObjectStopDragging}
          pageActivatedAt={pageActivatedAt}
          width={round(zoomFactor * model.width)}
        />
      );
    }
    case ObjectType.IMAGE: {
      return (
        <Image
          activatedAt={activatedAt}
          autoFit={model.autoFit}
          height={model.height}
          id={model.id}
          onObjectLoad={onObjectLoad}
          onObjectStopDragging={onObjectStopDragging}
          onObjectStartDragging={onObjectStartDragging}
          pageActivatedAt={pageActivatedAt}
          projectHeight={projectHeight}
          projectWidth={projectWidth}
          scrollable={model.scrollable}
          scrollableWithinPage={model.scrollableWithinPage}
          url={model.url}
          width={model.width}
          zoomAndPan={zoomAndPan}
          zoomFactor={zoomFactor}
        />
      );
    }
    case ObjectType.QUIZ_TEST: {
      return <QuizTest id={model.id} onObjectLoad={onObjectLoad} />;
    }
    case ObjectType.VIDEO: {
      return <Video id={model.id} onObjectLoad={onObjectLoad} />;
    }
    case ObjectType.ANCHOR: {
      return <Anchor id={model.id} onObjectLoad={onObjectLoad} />;
    }

    default: {
      return undefined;
    }
  }
};

const getInitialOpacity = (opacity, type) => {
  if (type === ObjectType.TEXT) {
    return 1;
  }

  return !opacity && opacity !== 0 ? 1 : opacity;
};

const getBackgroundColor = (backgroundColor, type) => {
  if (type === ObjectType.TEXT) {
    return null;
  }

  return backgroundColor;
};

const PageObject = ({
  activatedByLinkProps,
  defaultSoundAccessRight,
  karaokeSyncIndex,
  karaokeSoundSyncPoints,
  objectModel,
  onActionToBeExecutedOnPage,
  onExecuteLinkAction,
  onExecuteLayersAction,
  onGoToPage,
  onKaraokeSoundTimeUpdate,
  onKaraokeTextLoaded,
  onObjectStartDragging,
  onObjectStopDragging,
  onObjectLoad,
  pageActivatedAt,
  projectHeight,
  projectWidth,
  recordedSoundId,
  recordedSoundAccessRight,
  translateProps,
  shouldLoad,
  zoomFactor,
}) => {
  const [objectActivatedAt, setObjectActivatedAt] = useState(undefined);

  const onObjectActivated = useCallback((activationTime) => {
    setObjectActivatedAt(activationTime);
  }, []);

  if (
    !shouldLoad &&
    (objectModel.type !== ObjectType.TIMED_EVENT ||
      objectModel.globalEvent !== true)
  )
    return null;

  const container =
    objectModel.type === ObjectType.TIMED_EVENT ? (
      <TimedEvent />
    ) : (
      <InteractivePageObjectSection />
    );

  return (
    <container.type
      action={objectModel.action}
      activatedByLinkProps={activatedByLinkProps}
      addLayersIds={objectModel.addLayersIds}
      autoStart={objectModel.autoStart}
      backgroundColor={getBackgroundColor(
        objectModel.backgroundColor,
        objectModel.type
      )}
      borderStyle={objectModel.borderStyle}
      customProperties={objectModel.customProperties}
      draggable={objectModel.draggable}
      delayRangeStart={objectModel.delayRangeStart}
      delayRangeEnd={objectModel.delayRangeEnd}
      eventType={objectModel.eventType}
      globalEvent={objectModel.globalEvent}
      goTo={objectModel.goTo}
      gradient={objectModel.gradient}
      height={objectModel.height}
      id={objectModel.id}
      initialDisplayState={objectModel.initialDisplayState}
      initialOpacity={getInitialOpacity(
        objectModel.backgroundOpacity,
        objectModel.type
      )}
      infiniteLooping={objectModel.infiniteLooping}
      interactive={isObjectInteractive(objectModel)}
      iterations={objectModel.iterations}
      key={objectModel.id}
      left={objectModel.left}
      links={objectModel.links}
      loopIntervalEnd={objectModel.loopIntervalEnd}
      loopIntervalStart={objectModel.loopIntervalStart}
      moveLayersProps={objectModel.moveLayersProps}
      onActionToBeExecutedOnPage={onActionToBeExecutedOnPage}
      onExecuteLinkAction={onExecuteLinkAction}
      onExecuteLayersAction={onExecuteLayersAction}
      onGoToPage={onGoToPage}
      onObjectActivated={onObjectActivated}
      onObjectLoad={onObjectLoad}
      onObjectStartDragging={onObjectStartDragging}
      onObjectStopDragging={onObjectStopDragging}
      opacity={objectModel.backgroundOpacity}
      pageActivatedAt={pageActivatedAt}
      projectHeight={projectHeight}
      projectWidth={projectWidth}
      removeLayersIds={objectModel.removeLayersIds}
      rotation={objectModel.rotation}
      scaleFactor={zoomFactor}
      scoreAndEvaluation={objectModel.scoreAndEvaluation}
      setLayersIds={objectModel.setLayersIds}
      title={objectModel.title}
      toggleLayersIds={objectModel.toggleLayersIds}
      top={objectModel.top}
      type={objectModel.type}
      translateProps={translateProps}
      width={objectModel.width}
      zIndex={objectModel.zIndex}
      zoomAndPan={!!objectModel.zoomAndPan}
      zoomLayersProps={objectModel.zoomLayersProps}
    >
      {getObjectComponent(
        karaokeSyncIndex,
        karaokeSoundSyncPoints,
        objectModel,
        onKaraokeSoundTimeUpdate,
        onKaraokeTextLoaded,
        onObjectLoad,
        onObjectStartDragging,
        onObjectStopDragging,
        zoomFactor,
        objectActivatedAt,
        activatedByLinkProps,
        pageActivatedAt,
        recordedSoundId,
        defaultSoundAccessRight,
        recordedSoundAccessRight,
        projectHeight,
        projectWidth,
        getInitialOpacity(objectModel.backgroundOpacity),
        objectModel.backgroundColor,
        objectModel.zoomAndPan
      )}
    </container.type>
  );
};

const moveLayersProps = PropTypes.shape({
  layerId: PropTypes.number,
  duration: PropTypes.number,
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
  bezier: PropTypes.string,
});
const zoomLayersProps = PropTypes.shape({
  layerId: PropTypes.number,
  duration: PropTypes.number,
  delay: PropTypes.number,
  abortOnTap: PropTypes.bool,
  zoomFactor: PropTypes.number,
  bezier: PropTypes.string,
});

const evaluationLayersActionsPropTypes = PropTypes.shape({
  addLayersIds: PropTypes.arrayOf(PropTypes.number),
  removeLayersIds: PropTypes.arrayOf(PropTypes.number),
  setLayersIds: PropTypes.arrayOf(PropTypes.number),
  toggleLayersIds: PropTypes.arrayOf(PropTypes.number),
  moveLayersProps,
  zoomLayersProps,
});

const linksPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    relatedObjectId: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
        duration: PropTypes.number,
        endEffect: PropTypes.shape({
          type: PropTypes.number.isRequired,
          delay: PropTypes.number,
          bezier: PropTypes.string,
        }),
        bezier: PropTypes.string,
        autoStart: PropTypes.bool,
        abortOnTap: PropTypes.bool,
        snap: PropTypes.bool,
        tolerance: PropTypes.number,
      })
    ),
  })
);

PageObject.defaultProps = {
  activatedByLinkProps: undefined,
  karaokeSyncIndex: -1,
  karaokeSoundSyncPoints: null,
  pageActivatedAt: undefined,
  translateProps: null,
};
PageObject.propTypes = {
  activatedByLinkProps: PropTypes.shape({
    activatedAt: PropTypes.number.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
      })
    ),
  }),
  defaultSoundAccessRight: PropTypes.number.isRequired,
  karaokeSyncIndex: PropTypes.number,
  karaokeSoundSyncPoints: PropTypes.arrayOf(PropTypes.number),
  objectModel: PropTypes.shape({
    action: PropTypes.shape({
      type: PropTypes.number.isRequired,
      duration: PropTypes.number,
      rotationDuration: PropTypes.number,
      iterations: PropTypes.number,
      serviceUrl: PropTypes.string,
      productId: PropTypes.string,
      successCallback: PropTypes.string,
      errorCallback: PropTypes.string,
      androidProductId: PropTypes.string,
      iosProductId: PropTypes.string,
    }),

    addLayersIds: PropTypes.arrayOf(PropTypes.number),
    autoStart: PropTypes.bool,
    backgroundColor: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    borderStyle: PropTypes.number,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    customProperties: PropTypes.arrayOf(PropTypes.string),
    delayRangeStart: PropTypes.number,
    delayRangeEnd: PropTypes.number,
    draggable: PropTypes.bool,
    eventType: PropTypes.number,
    globalEvent: PropTypes.bool,
    goTo: PropTypes.shape({
      type: PropTypes.number.isRequired,
      id: PropTypes.number,
      page: PropTypes.number,
      embedded: PropTypes.bool,
      url: PropTypes.string,
    }),
    gradient: PropTypes.shape({
      color: PropTypes.string.isRequired,
      thickness: PropTypes.number.isRequired,
    }),
    height: PropTypes.number,
    id: PropTypes.string.isRequired,
    initialDisplayState: PropTypes.number,
    initialOpacity: PropTypes.number,
    iterations: PropTypes.number,
    infiniteLooping: PropTypes.bool,
    left: PropTypes.number,
    links: linksPropTypes,
    loopIntervalEnd: PropTypes.number,
    loopIntervalStart: PropTypes.number,
    moveLayersProps,
    removeLayersIds: PropTypes.arrayOf(PropTypes.number),
    rotation: PropTypes.number,
    scoreAndEvaluation: PropTypes.shape({
      highlight: PropTypes.bool.isRequired,
      scorePerCorrectAnswer: PropTypes.number.isRequired,
      correctAnswerLayersAction: evaluationLayersActionsPropTypes,
      wrongAnswerLayersAction: evaluationLayersActionsPropTypes,
    }),
    setLayersIds: PropTypes.arrayOf(PropTypes.number),
    title: PropTypes.string,
    toggleLayersIds: PropTypes.arrayOf(PropTypes.number),
    top: PropTypes.number,
    translateProps: PropTypes.shape({}),
    type: PropTypes.number.isRequired,
    width: PropTypes.number,
    zIndex: PropTypes.number.isRequired,
    zoomAndPan: PropTypes.shape({
      settings: PropTypes.shape({
        top: PropTypes.string.isRequired,
        left: PropTypes.string.isRequired,
        width: PropTypes.string.isRequired,
        height: PropTypes.string.isRequired,
      }).isRequired,
      move: PropTypes.shape({
        duration: PropTypes.number.isRequired,
        yOffset: PropTypes.string.isRequired,
        xOffset: PropTypes.string.isRequired,
        delay: PropTypes.number.isRequired,
      }),
      zoom: PropTypes.shape({
        duration: PropTypes.number.isRequired,
        translateOriginX: PropTypes.string.isRequired,
        translateOriginY: PropTypes.string.isRequired,
        delay: PropTypes.number.isRequired,
        zoomFactor: PropTypes.number.isRequired,
      }),
    }),
    zoomLayersProps,
  }).isRequired,
  onActionToBeExecutedOnPage: PropTypes.func.isRequired,
  onObjectLoad: PropTypes.func.isRequired,
  onExecuteLinkAction: PropTypes.func.isRequired,
  onExecuteLayersAction: PropTypes.func.isRequired,
  onGoToPage: PropTypes.func.isRequired,
  onKaraokeSoundTimeUpdate: PropTypes.func.isRequired,
  onKaraokeTextLoaded: PropTypes.func.isRequired,
  onObjectStartDragging: PropTypes.func.isRequired,
  onObjectStopDragging: PropTypes.func.isRequired,
  pageActivatedAt: PropTypes.number,
  projectHeight: PropTypes.number.isRequired,
  projectWidth: PropTypes.number.isRequired,
  recordedSoundAccessRight: PropTypes.number.isRequired,
  recordedSoundId: PropTypes.string.isRequired,
  shouldLoad: PropTypes.bool.isRequired,
  translateProps: PropTypes.shape({}),
  zoomFactor: PropTypes.number.isRequired,
};

export default PageObject;
