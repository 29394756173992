import { atom } from "recoil";
import { Orientation } from "@constants/Constants";

export const bottomMenuAtom = atom({
  key: "tba_bottomMenu",
  default: {},
});

export const currentProjectModelAtom = atom({
  key: "tba_currentProjectModel",
  default: {},
});

export const layersAtom = atom({
  key: "tba_layers",
  default: {},
});

export const layersClassesAtom = atom({
  key: "tba_layersClasses",
  default: [],
});

export const linkedProjectModelAtom = atom({
  key: "tba_linkedProjectModel",
  default: {},
});

export const openedPagesAtom = atom({
  key: "tba_openedPages",
  default: undefined,
});

export const deviceOrientationAtom = atom({
  key: "tba_deviceOrientation",
  default: Orientation.LANDSCAPE,
});

export const pagesAtom = atom({
  key: "tba_pagesAtom",
  default: [],
});

export const pagesModelsAtom = atom({
  key: "tba_pagesModelsAtom",
  default: [],
});

export const projectSizeAtom = atom({
  key: "tba_projectSize",
  default: {
    projectWidth: window.innerWidth,
    projectHeight: window.innerHeight,
  },
});

export const projectZoomFactorAtom = atom({
  key: "tba_projectZoomFactor",
  default: 1,
});

export const screenSizeAtom = atom({
  key: "tba_screenSize",
  default: {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  },
});

export const settingsAtom = atom({
  key: "tba_settings",
  default: {},
});

export const thumbnailsAtom = atom({
  key: "tba_thumbnails",
  default: [],
});

export const lightBoxAtom = atom({
  key: "tba_lightBox",
  default: null,
});

export const storageUpdatedAtom = atom({
  key: "tba_storageUpdated",
  default: null,
});

export const atoms = [
  bottomMenuAtom,
  currentProjectModelAtom,
  layersAtom,
  layersClassesAtom,
  linkedProjectModelAtom,
  openedPagesAtom,
  deviceOrientationAtom,
  pagesAtom,
  pagesModelsAtom,
  projectSizeAtom,
  projectZoomFactorAtom,
  screenSizeAtom,
  settingsAtom,
  thumbnailsAtom,
  lightBoxAtom,
];
