import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ImgButton from "./ImgButton";

const StyledImgButton = styled(ImgButton)`
  position: relative;
  width: 96px;
  height: 96px;
`;

const ArrowButton = ({
  onClick,
  disabled,
  enabledStateImgSrc,
  disabledStateImgSrc,
}) => (
  <StyledImgButton
    onClick={onClick}
    disabled={disabled}
    disabledImgSrc={disabledStateImgSrc}
    unselectedStateImgSrc={enabledStateImgSrc}
  />
);

ArrowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  enabledStateImgSrc: PropTypes.string.isRequired,
  disabledStateImgSrc: PropTypes.string.isRequired,
};

ArrowButton.defaultProps = {
  disabled: false,
};

export default ArrowButton;
