import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Center } from "@src/GlobalStyles";

const Container = styled.div`
  ${Center};
  position: absolute;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color:${backgroundColor};`}
  width: 100%;
  height: 100%;
`;

const SpinnerContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
`;

const Dot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;

  &:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: #ff7710;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
  }

  &:nth-child(1) {
    animation-delay: -1.1s;
  }
  &:nth-child(2) {
    animation-delay: -1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
  &:nth-child(4) {
    animation-delay: -0.8s;
  }
  &:nth-child(5) {
    animation-delay: -0.7s;
  }
  &:nth-child(6) {
    animation-delay: -0.6s;
  }
  &:nth-child(1):before {
    animation-delay: -1.1s;
  }
  &:nth-child(2):before {
    animation-delay: -1s;
  }
  &:nth-child(3):before {
    animation-delay: -0.9s;
  }
  &:nth-child(4):before {
    animation-delay: -0.8s;
  }
  &:nth-child(5):before {
    animation-delay: -0.7s;
  }
  &:nth-child(6):before {
    animation-delay: -0.6s;
  }
`;

const LoadingSpinner = ({ backgroundColor }) => (
  <Container backgroundColor={backgroundColor}>
    <SpinnerContainer>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </SpinnerContainer>
  </Container>
);

LoadingSpinner.defaultProps = {
  backgroundColor: null,
};

LoadingSpinner.propTypes = {
  backgroundColor: PropTypes.string,
};

export default LoadingSpinner;
