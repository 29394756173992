import bokmal from "@resources/captions/bokmal.json";
import english from "@resources/captions/english.json";
import nynorsk from "@resources/captions/nynorsk.json";
import { Language } from "@constants/Constants";

const captions = {
  [Language.BOKMAL]: bokmal,
  [Language.ENGLISH]: english,
  [Language.NYNORSK]: nynorsk,
  [Language.LULESAMISK]: bokmal,
  [Language.NORDSAMISK]: bokmal,
  [Language.SORSAMISK]: bokmal,
};

let language = Language.BOKMAL;

export const setLanguage = (newLanguage) => {
  language = newLanguage;
};

export const getCaption = (key) => captions[language][key];
