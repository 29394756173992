import ShortId from "@utils/ShortId";

const callbacksMapping = {};
const actionsTypes = {
  REQUEST: "request",
  RESPONSE: "response",
};

const validIdentifier = "TBA";
let targetURL = window.top.location.origin;
let sender = window.top;

const events = ["storage"];
const subscribers = {};

function initialize(successCallback, errorCallback) {
  const actionId = ShortId.generate();
  const callback = {};
  if (successCallback) {
    callback.success = successCallback;
  }
  if (errorCallback) {
    callback.error = errorCallback;
  }
  callbacksMapping[actionId] = callback;
  return actionId;
}

function getMessageBody(actionName, actionId, parameters) {
  return {
    id: actionId,
    type: actionsTypes.REQUEST,
    identifier: validIdentifier,
    message: {
      action: actionName,
      parameters,
    },
  };
}

function sendMessage(messageBody) {
  sender.postMessage(messageBody, targetURL);
}

function processAction(actionName, parameters, successCallback, errorCallback) {
  const actionId = initialize(successCallback, errorCallback);
  sendMessage(getMessageBody(actionName, actionId, parameters));
}

function setTargetURL(url) {
  targetURL = url;
}

function setSender(senderObject) {
  sender = senderObject;
}

function restoreIAP(productsIds, successCallback, errorCallback) {
  processAction("restoreIAP", productsIds, successCallback, errorCallback);
}

function purchase(productId, successCallback, errorCallback) {
  processAction("purchase", productId, successCallback, errorCallback);
}

function addLayers(layersIdentifiers, successCallback, errorCallback) {
  processAction("addLayers", layersIdentifiers, successCallback, errorCallback);
}

function removeLayers(layersIdentifiers, successCallback, errorCallback) {
  processAction(
    "removeLayers",
    layersIdentifiers,
    successCallback,
    errorCallback
  );
}

function setLayers(layersIdentifiers, successCallback, errorCallback) {
  processAction("setLayers", layersIdentifiers, successCallback, errorCallback);
}

function toggleLayers(layersIdentifiers, successCallback, errorCallback) {
  processAction(
    "toggleLayers",
    layersIdentifiers,
    successCallback,
    errorCallback
  );
}

function goToPage(pageNumber, successCallback, errorCallback) {
  processAction("goToPage", pageNumber - 1, successCallback, errorCallback);
}

function goToProject(projectId, successCallback, errorCallback) {
  processAction("goToProject", projectId, successCallback, errorCallback);
}

function goToWebPage(pageURL, successCallback, errorCallback) {
  processAction("goToWeb", pageURL, successCallback, errorCallback);
}

function openEmailClient(parameters, successCallback, errorCallback) {
  processAction("openEmailClient", parameters, successCallback, errorCallback);
}

function toggleMenuState(parameters, successCallback, errorCallback) {
  processAction("toggleMenuState", parameters, successCallback, errorCallback);
}

function trackUserAction(parameters, successCallback, errorCallback) {
  processAction("trackUserAction", parameters, successCallback, errorCallback);
}

function startSound(soundIdentifier, successCallback, errorCallback) {
  processAction("startSound", soundIdentifier, successCallback, errorCallback);
}

function stopSound(soundIdentifier, successCallback, errorCallback) {
  processAction("stopSound", soundIdentifier, successCallback, errorCallback);
}

function startVideo(videoIdentifier, successCallback, errorCallback) {
  processAction("startVideo", videoIdentifier, successCallback, errorCallback);
}

function stopVideo(videoIdentifier, successCallback, errorCallback) {
  processAction("stopVideo", videoIdentifier, successCallback, errorCallback);
}

function getCurrentSelection(parameters, successCallback, errorCallback) {
  processAction(
    "getCurrentSelection",
    parameters,
    successCallback,
    errorCallback
  );
}

function download(url, successCallback, errorCallback) {
  processAction("download", url, successCallback, errorCallback);
}
function downloadAndUnzip(url, successCallback, errorCallback) {
  processAction("downloadAndUnzip", url, successCallback, errorCallback);
}
function getDownloadPath(successCallback, errorCallback) {
  processAction("getDownloadPath", null, successCallback, errorCallback);
}

function getPrices(productsIds, successCallback, errorCallback) {
  processAction("getPrices", productsIds, successCallback, errorCallback);
}

function subscribe(event, handler) {
  if (!events.includes(event)) throw new Error(`Event ${event} not supported`);
  if (!subscribers[event]) {
    subscribers[event] = [];
  }
  subscribers[event].push(handler);
  processAction("subscribe", { event });
}

function persist(data) {
  processAction("persist", { data });
}

function executeCallback(actionId, parameters, status) {
  const callbackWrapper = callbacksMapping[actionId];
  if (callbackWrapper) {
    delete callbacksMapping[actionId];
    const callback = callbackWrapper[status];
    if (callback) {
      callback(parameters);
    }
  }
}

function dispatch(message) {
  const { event, data } = message;
  const eventSubscribers = subscribers[event] || [];
  eventSubscribers.forEach((subscriber) => {
    subscriber(data);
  });
}

function processMessage(e) {
  const { data } = e;
  if (typeof data === "object") {
    // this complies with messaging API
    const { id: actionId, identifier, type: actionType } = data;
    if (!actionId || !identifier || !actionType) return;

    if ("identifier" in data && "id" in data && "type" in data) {
      if (
        identifier === validIdentifier &&
        actionType === actionsTypes.RESPONSE
      ) {
        executeCallback(actionId, data.parameters, data.status);
        dispatch(data);
      }
    }
  } else {
    dispatch(data);
  }
}

window.addEventListener("message", processMessage, false);

const api = {
  setTargetURL,
  setSender,
  purchase,
  restoreIAP,
  getPrices,
  setLayers,
  addLayers,
  removeLayers,
  toggleLayers,
  goToPage,
  goToProject,
  goToWebPage,
  openEmailClient,
  toggleMenuState,
  trackUserAction,
  startSound,
  stopSound,
  startVideo,
  stopVideo,
  getCurrentSelection,
  download,
  downloadAndUnzip,
  getDownloadPath,
  subscribe,
  persist,
};
if (!window.tapbookauthor) window.tapbookauthor = {};

window.tapbookauthor.api = api;
