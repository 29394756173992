const reloadStyles = () =>
  new Promise((resolve) => {
    // first callback will execute before style load
    window.requestAnimationFrame(() => {
      // second callback will execute before second time style load
      window.requestAnimationFrame(() => {
        resolve();
      });
    });
  });

export default {
  reloadStyles,
};
