import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useComponentDidMount from "@hooks/UseComponentDidMount";
import { Interactive } from "@src/GlobalStyles";
import DatabaseService from "@services/DatabaseService";

const saveTimerPeriod = 1000;

const getImageContent = (src) =>
  new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      resolve(null);
    };
    image.src = src;
  });

const Canvas = styled.canvas`
  position: absolute;
  ${({ height, width }) => `
height:${height}px;
width:${width}px;
`}
`;

const CanvasInteractionArea = styled(Interactive)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Drawer = ({
  clearCanvasTimeStamp,
  drawingId,
  height,
  lineColor,
  lineWidth,
  onAllowDraw,
  onDrawStart,
  onDenyDraw,
  onDrawEnd,
  setCanvasStatus,
  width,
}) => {
  const canvasRef = useRef();
  const canvasPosition = useRef({ x: 0, y: 0 });
  const saveTimer = useRef(0);
  const mousePressed = useRef(false);
  const drawingPoints = useRef([]);
  const requestAnimationFrameId = useRef(0);

  const draw = useCallback(() => {
    if (!drawingPoints.current.length) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const lastPoint = drawingPoints.current[drawingPoints.current.length - 1];

    context.beginPath();
    while (drawingPoints.current.length > 1) {
      const startPosition = drawingPoints.current.shift();
      const endPosition = drawingPoints.current[0];
      context.moveTo(startPosition.x, startPosition.y);
      context.lineTo(endPosition.x, endPosition.y);
    }
    context.closePath();
    context.stroke();
    drawingPoints.current = [lastPoint];

    requestAnimationFrameId.current = window.requestAnimationFrame(draw);
  }, []);

  const saveDrawing = useCallback(
    async (emptyDraw = false) => {
      const { data: drawings } = await DatabaseService.get("drawings", {
        data: {},
      });
      if (emptyDraw) {
        delete drawings[drawingId];
      } else {
        drawings[drawingId] = canvasRef.current.toDataURL();
      }
      await DatabaseService.put("drawings", drawings);
    },
    [drawingId]
  );

  const loadStoredDrawing = useCallback(async () => {
    const { data: drawings } = await DatabaseService.get("drawings", {
      data: null,
    });

    if (!drawings) return;

    const currentDrawing = drawings[drawingId];

    if (!currentDrawing) return;

    const imageContent = await getImageContent(currentDrawing);
    if (!imageContent) return;

    canvasRef.current
      .getContext("2d")
      .drawImage(imageContent, 0, 0, width, height);
    setCanvasStatus("dirty");
  }, [drawingId, height, setCanvasStatus, width]);

  const onMouseDown = useCallback(
    (e) => {
      if (e.button === 2) return;
      onDenyDraw();
      mousePressed.current = true;
      drawingPoints.current.push({
        x: e.pageX - canvasPosition.current.x,
        y: e.pageY - canvasPosition.current.y,
      });
      onDrawStart();
      setCanvasStatus("dirty");
    },
    [onDenyDraw, onDrawStart, setCanvasStatus]
  );

  const onMouseMove = useCallback(
    (e) => {
      if (!mousePressed.current || !e.pressed) return;
      e.originalEvent.stopPropagation();
      const currentPosition = {
        x: e.pageX - canvasPosition.current.x,
        y: e.pageY - canvasPosition.current.y,
      };
      if (!drawingPoints.current.length) {
        drawingPoints.current.push(currentPosition);
      }
      drawingPoints.current.push(currentPosition);

      if (!requestAnimationFrameId.current) {
        requestAnimationFrameId.current = window.requestAnimationFrame(draw);
      }
    },
    [draw]
  );

  const onMouseLeave = useCallback(() => {
    if (!mousePressed.current) return;

    draw();
    if (requestAnimationFrameId.current) {
      window.cancelAnimationFrame(requestAnimationFrameId.current);
      requestAnimationFrameId.current = 0;
    }
    drawingPoints.current = [];

    if (saveTimer.current) {
      window.clearTimeout(saveTimer.current);
      saveTimer.current = 0;
    }
    saveTimer.current = window.setInterval(saveDrawing, saveTimerPeriod);
  }, [draw, saveDrawing]);

  const onMouseUp = useCallback(() => {
    onAllowDraw();
    if (!mousePressed.current) return;
    mousePressed.current = false;
    draw();
    if (requestAnimationFrameId.current) {
      window.cancelAnimationFrame(requestAnimationFrameId.current);
      requestAnimationFrameId.current = 0;
    }
    drawingPoints.current = [];

    if (saveTimer.current) {
      window.clearTimeout(saveTimer.current);
      saveTimer.current = 0;
    }
    saveTimer.current = window.setInterval(saveDrawing, saveTimerPeriod);
    onDrawEnd();
  }, [draw, onAllowDraw, onDrawEnd, saveDrawing]);

  useComponentDidMount(() => {
    const onDocumentMouseUp = () => {
      onAllowDraw();
    };
    document.addEventListener("mouseup", onDocumentMouseUp, false);
    document.addEventListener("touchend", onDocumentMouseUp, false);

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const rect = canvas.getBoundingClientRect();
    canvasPosition.current = { x: rect.left, y: rect.top };

    context.strokeStyle = lineColor;
    context.lineWidth = lineWidth;
    context.lineJoin = "round";
    loadStoredDrawing().then();

    return () => {
      document.removeEventListener("mouseup", onDocumentMouseUp, false);
      document.removeEventListener("touchend", onDocumentMouseUp, false);
    };
  });

  useEffect(() => {
    canvasRef.current.getContext("2d").strokeStyle = lineColor;
  }, [lineColor]);

  useEffect(() => {
    canvasRef.current.getContext("2d").lineWidth = lineWidth;
  }, [lineWidth]);

  useEffect(() => {
    if (!clearCanvasTimeStamp) return;
    canvasRef.current.getContext("2d").clearRect(0, 0, width, height);
    saveDrawing(true).then();
    setCanvasStatus("clean");
  }, [clearCanvasTimeStamp, height, saveDrawing, setCanvasStatus, width]);

  return (
    <>
      <Canvas height={height} ref={canvasRef} width={width} />
      <CanvasInteractionArea
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      />
    </>
  );
};

Drawer.propTypes = {
  clearCanvasTimeStamp: PropTypes.string.isRequired,
  drawingId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  lineColor: PropTypes.string.isRequired,
  lineWidth: PropTypes.number.isRequired,
  onAllowDraw: PropTypes.func.isRequired,
  onDrawStart: PropTypes.func.isRequired,
  onDenyDraw: PropTypes.func.isRequired,
  onDrawEnd: PropTypes.func.isRequired,
  setCanvasStatus: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

export default Drawer;
