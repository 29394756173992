import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Center, Interactive } from "@src/GlobalStyles";
import AudioPlayer from "@media/player/AudioPlayer";
import PlatformUtils from "@utils/PlatformUtils";
import soundIcon from "./gfx/soundPlayIcon.svg";

const OverlayContainer = styled(Interactive)`
  ${Center};
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  cursor: pointer;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--slate-grey);
  position: absolute;
  opacity: 0.8;
  pointer-events: none;
`;

const SoundIcon = styled.img`
  width: 100px;
  position: absolute;
  pointer-events: none;
`;
const AutoStartSoundOverlay = ({ onClick, soundIds, objects }) => {
  const createSounds = async () => {
    objects.reduce(
      (promise, object) =>
        promise.then(() => {
          if (soundIds.includes(object.id)) {
            console.log("creating sound with id:", object.id);
            return AudioPlayer.createSound({
              id: object.id,
              src: PlatformUtils.getResourceUrl(object.path),
            });
          }
          return promise;
        }),
      Promise.resolve()
    );
  };

  const onClickHandler = async () => {
    await createSounds();
    onClick();
  };

  return (
    <OverlayContainer onClick={onClickHandler}>
      <Overlay />
      <SoundIcon alt="" src={soundIcon} />
    </OverlayContainer>
  );
};

AutoStartSoundOverlay.propTypes = {
  onClick: PropTypes.func.isRequired,
  soundIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  objects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AutoStartSoundOverlay;
