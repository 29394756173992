import React from "react";
import PropTypes from "prop-types";
import useComponentDidMount from "@hooks/UseComponentDidMount";

const QuizTest = React.memo(({ id, onObjectLoad }) => {
  useComponentDidMount(() => {
    onObjectLoad(id);
  });
  return null;
});

QuizTest.propTypes = {
  id: PropTypes.string.isRequired,
  onObjectLoad: PropTypes.func.isRequired,
};

export default QuizTest;
