/* eslint-disable */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  ActionsThatShouldNotifyPage,
  ActionType,
  AnimationType,
  BorderStyle,
  BorderStyleValues,
  InitialDisplayState,
  LinkType,
  StylesPrefixes,
} from "@constants/Constants";
import { Interactive } from "@src/GlobalStyles";
import ActionsUtils from "@utils/ActionsUtils";
import usePrevious from "@hooks/UsePrevious";
import { bottomMenuAtom, lightBoxAtom } from "@stateManagement/Atoms";
import GoToActionsUtils from "@utils/GoToActionsUtils";
import useComponentDidMount from "@hooks/UseComponentDidMount";
import CssAnimationsManager from "@utils/CssAnimationsManager";
import AudioPlayer from "@media/player/AudioPlayer";

const { abs, round } = Math;

const defaultStyles = (styleProps, customStyleProps) => `
    position: absolute;
    box-sizing: border-box;
    ${Object.keys(styleProps)
      .map((key) => `${StylesPrefixes[key]}${styleProps[key]};`)
      .join("\n")}
    ${(customStyleProps || []).join("\n")}
`;

const StyledDraggableComponent = styled.div`
  cursor: pointer;
  ${({ styleProps, customStyleProps }) =>
    defaultStyles(styleProps, customStyleProps)};
`;
const StyledInteractiveObject = styled(Interactive)`
  cursor: pointer;
  ${({ styleProps, customStyleProps }) =>
    defaultStyles(styleProps, customStyleProps)};
`;

const StyledNonInteractiveObject = styled.div`
  ${({ styleProps, customStyleProps }) =>
    defaultStyles(styleProps, customStyleProps)};
`;

const GradientOverlay = styled.div`
  position: absolute;
  ${({ width, height, scaleFactor, gradient }) =>
    `
    width:${Math.round(width * scaleFactor)}px;
    height:${Math.round(height * scaleFactor)}px;
    background: -webkit-linear-gradient(top, rgba(${
      gradient.color
    }, 1) 0%, rgba(${gradient.color}, 0) ${gradient.thickness}%, rgba(${
      gradient.color
    }, 0) ${100 - gradient.thickness}%, rgba(${gradient.color}, 1) 100%);
    background: -moz-linear-gradient(top, rgba(${gradient.color}, 1) 0%, rgba(${
      gradient.color
    }, 0) ${gradient.thickness}%, rgba(${gradient.color}, 0) ${
      100 - gradient.thickness
    }%, rgba(${gradient.color}, 1) 100%);
    background: -o-linear-gradient(top, rgba(${gradient.color}, 1) 0%, rgba(${
      gradient.color
    }, 0) ${gradient.thickness}%, rgba(${gradient.color}, 0) ${
      100 - gradient.thickness
    }%, rgba(${gradient.color}, 1) 100%);
   background: -ms-linear-gradient(top, rgba(${gradient.color}, 1) 0%, rgba(${
      gradient.color
    }, 0) ${gradient.thickness}%, rgba(${gradient.color}, 0) ${
      100 - gradient.thickness
    }%, rgba(${gradient.color}, 1) 100%);
        `}
`;

const getUserCanInteractWithObject = (
  action,
  addLayersIds,
  draggable,
  goTo,
  links,
  interactive,
  moveLayersProps,
  removeLayersIds,
  setLayersIds,
  toggleLayersIds,
  zoomAndPan,
  zoomLayersProps
) => {
  return (
    action ||
    addLayersIds ||
    draggable ||
    goTo ||
    links ||
    interactive ||
    moveLayersProps ||
    removeLayersIds ||
    setLayersIds ||
    toggleLayersIds ||
    zoomAndPan ||
    zoomLayersProps
  );
};

const getObjectStyle = ({
  backgroundColor,
  borderStyle,
  displayState,
  height,
  left,
  initialOpacity,
  rotation,
  top,
  width,
  zIndex,
  scaleFactor,
  translateProps,
}) => {
  let opacity = 0;
  if (displayState === InitialDisplayState.VISIBLE) {
    opacity = initialOpacity;
  }

  const ret = {
    border: BorderStyleValues[borderStyle],
    height: `${round(height * scaleFactor)}px`,
    left: `${round(left * scaleFactor)}px`,
    opacity,
    top: `${round(top * scaleFactor)}px`,
    width: `${round(width * scaleFactor)}px`,
    zIndex,
  };
  if (backgroundColor && backgroundColor !== "transparent") {
    ret.backgroundColor = `${backgroundColor}`;
  }

  if (rotation) {
    ret.transform = `rotate(-${rotation}deg)`;
  }

  if (translateProps) {
    ret.transform = `translate3D(${translateProps.xOffset || 0}px, ${
      translateProps.yOffset || 0
    }px, 0)`;
    ret.transition = `transform ${translateProps.duration}ms cubic-bezier(${translateProps.bezier});`;
  }

  return ret;
};

const InteractivePageObjectSection = React.memo(
  ({
    action,
    activatedByLinkProps,
    addLayersIds,
    autoStart,
    backgroundColor,
    borderStyle,
    children,
    customProperties,
    draggable,
    gradient,
    goTo,
    height,
    id,
    initialDisplayState,
    initialOpacity,
    interactive,
    left,
    links,
    moveLayersProps,
    onActionToBeExecutedOnPage,
    onExecuteLinkAction,
    onExecuteLayersAction,
    onGoToPage,
    onObjectActivated,
    onObjectStartDragging,
    onObjectStopDragging,
    opacity,
    pageActivatedAt,
    projectHeight,
    projectWidth,
    removeLayersIds,
    rotation,
    scaleFactor,
    scoreAndEvaluation,
    setLayersIds,
    title,
    toggleLayersIds,
    top,
    translateProps,
    width,
    zIndex,
    zoomAndPan,
    zoomLayersProps,
  }) => {
    const htmlRef = useRef();

    const executedActionsTimes = useRef(0);
    const executedLinksTimes = useRef(0);

    const [objectActivatedAt, setObjectActivatedAt] = useState(undefined);
    const previousPageActivationTime = usePrevious(pageActivatedAt);
    const previousObjectActivationTime = usePrevious(objectActivatedAt);
    const setLightBoxProps = useSetRecoilState(lightBoxAtom);
    const [menuModel, setMenuModel] = useRecoilState(bottomMenuAtom);

    const mousePressed = useRef(false);
    const startInteractionCoordinates = useRef({ x: 0, y: 0 });
    const dragOffsetBeforeSession = useRef({ x: 0, y: 0 });
    const persistentDragOffset = useRef({ x: 0, y: 0 });
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
      persistentDragOffset.current = { ...dragOffset };
    }, [dragOffset]);

    const previousLinkActivationProps = usePrevious(activatedByLinkProps);
    const linksExecuted = useRef(false);
    const hasMoveLinks = useRef(
      (() =>
        (links &&
          links.reduce(
            (ret, { properties }) =>
              ret || properties.find(({ type }) => type === LinkType.MOVE_TO),
            false
          ) &&
          true) ||
        false)()
    );

    const userCanInteractWithObject = useRef(
      getUserCanInteractWithObject(
        action,
        addLayersIds,
        draggable,
        goTo,
        links,
        interactive,
        moveLayersProps,
        removeLayersIds,
        setLayersIds,
        toggleLayersIds,
        zoomAndPan,
        zoomLayersProps
      )
    );

    const [styleProps, setStyleProps] = useState(
      getObjectStyle({
        backgroundColor,
        borderStyle,
        gradient,
        height,
        displayState: initialDisplayState,
        left,
        initialOpacity,
        rotation,
        top,
        width,
        zIndex,
        scaleFactor,
        translateProps,
      })
    );

    const reset = useCallback(() => {
      onObjectActivated(undefined);
      setDragOffset({ x: 0, y: 0 });
      setObjectActivatedAt(undefined);
      executedActionsTimes.current = 0;
      executedLinksTimes.current = 0;
      mousePressed.current = false;
      startInteractionCoordinates.current = { x: 0, y: 0 };
      dragOffsetBeforeSession.current = { x: 0, y: 0 };
      persistentDragOffset.current = { x: 0, y: 0 };
      linksExecuted.current = false;

      setStyleProps(
        getObjectStyle({
          backgroundColor,
          borderStyle,
          gradient,
          height,
          displayState: initialDisplayState,
          left,
          initialOpacity,
          rotation,
          top,
          width,
          zIndex,
          scaleFactor,
          translateProps,
        })
      );
    }, [
      backgroundColor,
      borderStyle,
      gradient,
      height,
      initialDisplayState,
      left,
      initialOpacity,
      rotation,
      top,
      width,
      zIndex,
      scaleFactor,
      translateProps,
    ]);

    const executeLayersAction = useCallback(async () => {
      if (
        addLayersIds ||
        setLayersIds ||
        toggleLayersIds ||
        removeLayersIds ||
        moveLayersProps ||
        zoomLayersProps
      ) {
        await onExecuteLayersAction({
          addLayersIds,
          setLayersIds,
          toggleLayersIds,
          removeLayersIds,
          moveLayersProps,
          zoomLayersProps,
        });
      }
    }, [
      addLayersIds,
      moveLayersProps,
      onExecuteLayersAction,
      removeLayersIds,
      setLayersIds,
      toggleLayersIds,
      zoomLayersProps,
    ]);

    const executeLinkAction = useCallback(() => {
      // remove draggable links since those are related only to current object
      // remove links that should be executed only once
      const filteredLinks = links
        .map((link) => {
          const linkProperties = link.properties.filter(
            (properties) =>
              properties.type !== LinkType.DRAGGABLE_TARGET &&
              !(
                properties.type === LinkType.ACTIVATE_ONCE &&
                executedLinksTimes.current === 1
              )
          );
          return linkProperties.length > 0
            ? {
                relatedObjectId: link.relatedObjectId,
                properties: linkProperties,
              }
            : null;
        })
        .filter((link) => link);
      if (filteredLinks.length) {
        executedLinksTimes.current += 1;
        onExecuteLinkAction(filteredLinks, { left, top });
      }
    }, [left, links, onExecuteLinkAction, top]);

    const executeLinkEffect = useCallback(
      async (activatedAt, linkProps) => {
        const { type } = linkProps;
        switch (type) {
          case LinkType.ACTIVATE: {
            setObjectActivatedAt(activatedAt);
            break;
          }
          case LinkType.ACTIVATE_ONCE: {
            if (!linksExecuted.current) {
              setObjectActivatedAt(activatedAt);
            }
            break;
          }
          case LinkType.PULSATE: {
            await ActionsUtils.execute(
              {
                action: { type: ActionType.PULSATE, duration: 2000 },
                initialOpacity,
              },
              {
                executedActionsTimes: 0,
                styleProps,
                setStyleProps,
                initialOpacity,
              }
            );
            break;
          }
          case LinkType.SHOW: {
            if (styleProps.opacity !== opacity) {
              setStyleProps({ ...styleProps, opacity });
            }
            break;
          }
          case LinkType.HIDE: {
            if (styleProps.opacity !== 0) {
              setStyleProps({ ...styleProps, opacity: 0 });
            }
            break;
          }
          case LinkType.TOGGLE_VISIBILITY: {
            await ActionsUtils.execute(
              {
                action: { type: ActionType.TOGGLE_VISIBILITY },
                initialOpacity,
              },
              {
                executedActionsTimes: 0,
                styleProps,
                setStyleProps,
                initialOpacity,
              }
            );
            break;
          }
          case LinkType.FADE_IN: {
            if (styleProps.opacity !== 0) return;

            await ActionsUtils.execute(
              {
                action: { type: ActionType.FADE_VISIBILITY, duration: 1000 },
                initialOpacity: opacity,
              },
              {
                executedActionsTimes: 0,
                styleProps,
                setStyleProps,
                initialOpacity,
              }
            );
            break;
          }
          case LinkType.FADE_OUT: {
            if (styleProps.opacity === 0) return;

            await ActionsUtils.execute(
              {
                action: { type: ActionType.FADE_VISIBILITY, duration: 1000 },
                initialOpacity: opacity,
              },
              {
                executedActionsTimes: 0,
                styleProps,
                setStyleProps,
                initialOpacity,
              }
            );
            break;
          }
          case LinkType.MOVE_TO: {
            const leftOffset = Math.round(
              (linkProps.left - left) * scaleFactor
            );
            const topOffset = Math.round((linkProps.top - top) * scaleFactor);

            CssAnimationsManager.animate(
              AnimationType.MOVE,
              {
                id,
              },
              { ...linkProps, topOffset, leftOffset },
              {
                styleProps,
                setStyleProps,
              }
            );
            break;
          }

          case LinkType.ZOOM_TO: {
            await onActionToBeExecutedOnPage({
              ...linkProps,
              type: ActionType.ZOOM_IN_STARTING_POINT,
              objectLeft: left,
              objectTop: top,
              objectWidth: width,
              objectHeight: height,
              borderStyle,
              executedActionsTimes: 0,
            });
            break;
          }
          case LinkType.DRAGGABLE_TARGET: {
            alert("e");
            break;
          }
          default: // do nothing
        }
        linksExecuted.current = true;
      },
      [
        borderStyle,
        height,
        id,
        initialOpacity,
        left,
        onActionToBeExecutedOnPage,
        opacity,
        scaleFactor,
        styleProps,
        top,
        width,
      ]
    );

    const executeAction = useCallback(async () => {
      const actionWasExecuted = ActionsThatShouldNotifyPage.includes(
        action.type
      )
        ? await onActionToBeExecutedOnPage({
            ...action,
            objectLeft: left,
            objectTop: top,
            objectWidth: width,
            objectHeight: height,
            borderStyle,
            executedActionsTimes: executedActionsTimes.current,
          })
        : await ActionsUtils.execute(
            { action, initialOpacity, initialDisplayState },
            {
              executedActionsTimes: executedActionsTimes.current,
              projectHeight,
              projectWidth,
              styleProps,
              setStyleProps,
              setLightBoxProps,
              initialOpacity,
              menuModel,
              setMenuModel,
            }
          );

      if (actionWasExecuted) {
        executedActionsTimes.current += 1;
      }
    }, [
      action,
      onActionToBeExecutedOnPage,
      left,
      top,
      width,
      height,
      borderStyle,
      initialOpacity,
      projectHeight,
      projectWidth,
      styleProps,
      setLightBoxProps,
      menuModel,
      setMenuModel,
    ]);

    const executeAutostartMoveLinks = useCallback(() => {
      if (!links) {
        return;
      }

      const autostartMoveLinks = links.filter((link) => {
        const autostartMoveLinkProperties = link.properties.filter(
          ({ type, autoStart: linkAutoStart }) =>
            type === LinkType.MOVE_TO && linkAutoStart
        );

        return autostartMoveLinkProperties.length
          ? {
              relatedObjectId: link.relatedObjectId,
              properties: autostartMoveLinkProperties,
            }
          : null;
      });
      if (!autostartMoveLinks.length) {
        return;
      }
      onExecuteLinkAction(autostartMoveLinks, { left, top });
    }, [left, links, onExecuteLinkAction, top]);

    const onClick = useCallback(async () => {
      if (
        dragOffset.x !== dragOffsetBeforeSession.current.x ||
        dragOffset.y !== dragOffsetBeforeSession.current.y
      ) {
        return;
      }
      const activationTime = new Date().getTime();
      onObjectActivated(activationTime);
      setObjectActivatedAt(activationTime);
    }, [dragOffset.x, dragOffset.y, onObjectActivated]);

    useEffect(() => {
      setStyleProps(
        getObjectStyle({
          backgroundColor,
          borderStyle,
          gradient,
          height,
          displayState: initialDisplayState,
          left,
          initialOpacity,
          rotation,
          top,
          width,
          zIndex,
          scaleFactor,
          translateProps,
        })
      );
    }, [
      backgroundColor,
      borderStyle,
      gradient,
      height,
      initialDisplayState,
      initialOpacity,
      left,
      rotation,
      scaleFactor,
      top,
      translateProps,
      width,
      zIndex,
    ]);

    useEffect(() => {
      if (pageActivatedAt !== 0 && !previousPageActivationTime) {
        if (autoStart) {
          const activationTime = new Date().getTime();
          onObjectActivated(activationTime);
          setObjectActivatedAt(activationTime);
        }
        if (
          action &&
          (action.type === ActionType.ZOOM_OUT_STARTING_POINT ||
            action.type === ActionType.ZOOM_IN_STARTING_POINT)
        ) {
          executeAction().then(/* do nothing */);
        }
        executeAutostartMoveLinks();
      } else if (pageActivatedAt === 0 && previousPageActivationTime !== 0) {
        reset();
      }
    }, [
      action,
      autoStart,
      executeAction,
      executeAutostartMoveLinks,
      pageActivatedAt,
      previousPageActivationTime,
      reset,
    ]);

    useEffect(() => {
      if (!activatedByLinkProps) {
        return;
      }
      const { activatedAt } = activatedByLinkProps;
      if (
        previousLinkActivationProps &&
        previousLinkActivationProps.activatedAt ===
          activatedByLinkProps.activatedAt
      ) {
        return;
      }

      activatedByLinkProps.properties.forEach((link) => {
        executeLinkEffect(activatedAt, link).then();
      });
    }, [activatedByLinkProps, executeLinkEffect, previousLinkActivationProps]);

    useEffect(() => {
      if (
        !objectActivatedAt ||
        objectActivatedAt === previousObjectActivationTime
      ) {
        return;
      }

      if (action) {
        executeAction().then(/* do nothing */);
      }
      if (goTo) {
        GoToActionsUtils.execute(goTo, { setLightBoxProps, onGoToPage });
      }
      if (links) {
        executeLinkAction();
      }
      executeLayersAction().then(/* do nothing */);
    }, [
      action,
      executeAction,
      executeLayersAction,
      executeLinkAction,
      goTo,
      links,
      objectActivatedAt,
      onGoToPage,
      previousObjectActivationTime,
      setLightBoxProps,
    ]);

    const executeSnapLinkIfNeeded = useCallback(() => {
      if (!links) return;

      links.forEach((link) => {
        const { properties } = link;
        properties.forEach((linkProps) => {
          if (linkProps.type === LinkType.DRAGGABLE_TARGET) {
            const {
              targetLeft,
              targetTop,
              targetWidth,
              targetHeight,
              tolerance,
            } = linkProps;
            const currentObjectLeft =
              left + persistentDragOffset.current.x / scaleFactor;
            const currentObjectTop =
              top + persistentDragOffset.current.y / scaleFactor;
            const currentObjectXCenter = currentObjectLeft + round(width / 2);
            const currentObjectYCenter = currentObjectTop + round(height / 2);
            const currentObjectRight = currentObjectLeft + width;
            const currentObjectBottom = currentObjectTop + height;
            const targetXCenter = targetLeft + round(targetWidth / 2);
            const targetYCenter = targetTop + round(targetHeight / 2);
            const targetRight = targetLeft + targetWidth;
            const targetBottom = targetTop + targetHeight;

            const shouldSnap =
              abs(currentObjectLeft - targetLeft) < tolerance ||
              abs(currentObjectRight - targetRight) < tolerance ||
              abs(currentObjectTop - targetTop) < tolerance ||
              abs(currentObjectBottom - targetBottom) < tolerance ||
              abs(currentObjectXCenter - targetXCenter) < tolerance ||
              abs(currentObjectYCenter - targetYCenter) < tolerance;

            if (shouldSnap) {
              setDragOffset({
                x: round(scaleFactor * (targetLeft - left)),
                y: round(scaleFactor * (targetTop - top)),
              });
            }
          }
        });
      });
    }, [persistentDragOffset, height, left, links, scaleFactor, top, width]);

    const onMouseDown = useCallback(
      (e) => {
        if (mousePressed.current || e.target !== htmlRef.current) return;
        startInteractionCoordinates.current = { x: e.pageX, y: e.pageY };
        setDragOffset((currentOffset) => {
          dragOffsetBeforeSession.current = { ...currentOffset };
          return currentOffset;
        });
        onObjectStartDragging();
        mousePressed.current = true;
      },
      [onObjectStartDragging]
    );

    const onMouseUp = useCallback(() => {
      if (!mousePressed.current) return;
      mousePressed.current = false;
      executeSnapLinkIfNeeded();
      onObjectStopDragging();
    }, [executeSnapLinkIfNeeded, onObjectStopDragging]);

    const onMouseMove = useCallback((e) => {
      if (mousePressed.current) {
        e.stopPropagation();
        const xOffset = e.pageX - startInteractionCoordinates.current.x;
        const yOffset = e.pageY - startInteractionCoordinates.current.y;
        setDragOffset({
          x: dragOffsetBeforeSession.current.x + xOffset,
          y: dragOffsetBeforeSession.current.y + yOffset,
        });
      }
    }, []);

    const onTouchStart = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        onMouseDown(e);
      },
      [onMouseDown]
    );

    const onTouchMove = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        onMouseMove(e);
      },
      [onMouseMove]
    );

    const onTouchEnd = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        onMouseUp();
      },
      [onMouseUp]
    );

    useComponentDidMount(() => {
      if (!draggable) return undefined;

      document.addEventListener("mousemove", onMouseMove, false);
      document.addEventListener("mousedown", onMouseDown, false);
      document.addEventListener("mouseup", onMouseUp, false);
      document.addEventListener("touchmove", onTouchMove, false);
      document.addEventListener("touchstart", onTouchStart, false);
      document.addEventListener("touchend", onTouchEnd, false);

      return () => {
        document.removeEventListener("mousemove", onMouseMove, false);
        document.removeEventListener("mousedown", onMouseDown, false);
        document.removeEventListener("mouseup", onMouseUp, false);
        document.removeEventListener("touchmove", onTouchMove, false);
        document.removeEventListener("touchstart", onTouchStart, false);
        document.removeEventListener("touchend", onTouchEnd, false);
      };
    });
    return (
      (!hasMoveLinks.current || null) && (
        <>
          {(draggable || null) && (
            <StyledDraggableComponent
              id={id}
              ref={htmlRef}
              customStyleProps={customProperties}
              style={{
                transform: `translate3D(${dragOffset.x}px, ${dragOffset.y}px, 0)`,
              }}
              styleProps={styleProps}
              title={title}
              onClick={onClick}
            >
              {children}
              {(gradient || null) && (
                <GradientOverlay
                  gradient={gradient}
                  height={height}
                  scaleFactor={scaleFactor}
                  width={width}
                />
              )}
            </StyledDraggableComponent>
          )}
          {((userCanInteractWithObject.current && !draggable) || null) && (
            <StyledInteractiveObject
              id={id}
              customStyleProps={customProperties}
              styleProps={styleProps}
              title={title}
              onClick={onClick}
            >
              {children}
              {(gradient || null) && (
                <GradientOverlay
                  gradient={gradient}
                  height={height}
                  scaleFactor={scaleFactor}
                  width={width}
                />
              )}
            </StyledInteractiveObject>
          )}
          {(!userCanInteractWithObject.current || null) && (
            <StyledNonInteractiveObject
              id={id}
              styleProps={styleProps}
              title={title}
              customStyleProps={customProperties}
            >
              {children}
              {(gradient || null) && (
                <GradientOverlay
                  gradient={gradient}
                  height={height}
                  scaleFactor={scaleFactor}
                  width={width}
                />
              )}
            </StyledNonInteractiveObject>
          )}
        </>
      )
    );
  }
);

export default InteractivePageObjectSection;

const moveLayersProps = PropTypes.shape({
  layerId: PropTypes.number,
  duration: PropTypes.number,
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
  bezier: PropTypes.string,
});
const zoomLayersProps = PropTypes.shape({
  layerId: PropTypes.number,
  duration: PropTypes.number,
  delay: PropTypes.number,
  abortOnTap: PropTypes.bool,
  zoomFactor: PropTypes.number,
  bezier: PropTypes.string,
});

const evaluationLayersActionsPropTypes = PropTypes.shape({
  addLayersIds: PropTypes.arrayOf(PropTypes.number),
  removeLayersIds: PropTypes.arrayOf(PropTypes.number),
  setLayersIds: PropTypes.arrayOf(PropTypes.number),
  toggleLayersIds: PropTypes.arrayOf(PropTypes.number),
  moveLayersProps,
  zoomLayersProps,
});

const linksPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    relatedObjectId: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
        duration: PropTypes.number,
        endEffect: PropTypes.shape({
          type: PropTypes.number.isRequired,
          delay: PropTypes.number,
          bezier: PropTypes.string,
        }),
        bezier: PropTypes.string,
        autoStart: PropTypes.bool,
        abortOnTap: PropTypes.bool,
        snap: PropTypes.bool,
        tolerance: PropTypes.number,
      })
    ),
  })
);

InteractivePageObjectSection.defaultProps = {
  action: null,
  activatedByLinkProps: undefined,
  addLayersIds: null,
  autoStart: false,
  backgroundColor: null,
  borderStyle: BorderStyle.NO_BORDER,
  children: null,
  customProperties: null,
  draggable: false,
  goTo: null,
  gradient: null,
  height: 0,
  initialDisplayState: InitialDisplayState.VISIBLE,
  initialOpacity: 1,
  left: 0,
  links: null,
  moveLayersProps: null,
  pageActivatedAt: undefined,
  opacity: 1,
  removeLayersIds: null,
  rotation: 0,
  scoreAndEvaluation: null,
  setLayersIds: null,
  title: null,
  toggleLayersIds: null,
  top: 0,
  translateProps: null,
  width: 0,
  zoomLayersProps: null,
};

InteractivePageObjectSection.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.number.isRequired,
    duration: PropTypes.number,
    rotationDuration: PropTypes.number,
    iterations: PropTypes.number,
    serviceUrl: PropTypes.string,
    productId: PropTypes.string,
    successCallback: PropTypes.string,
    errorCallback: PropTypes.string,
    androidProductId: PropTypes.string,
    iosProductId: PropTypes.string,
  }),
  activatedByLinkProps: PropTypes.shape({
    activatedAt: PropTypes.number.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
      })
    ),
  }),
  addLayersIds: PropTypes.arrayOf(PropTypes.number),
  autoStart: PropTypes.bool,
  backgroundColor: PropTypes.string,
  borderStyle: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  customProperties: PropTypes.arrayOf(PropTypes.string),
  draggable: PropTypes.bool,
  goTo: PropTypes.shape({
    type: PropTypes.number.isRequired,
    id: PropTypes.number,
    page: PropTypes.number,
    embedded: PropTypes.bool,
    url: PropTypes.string,
  }),
  gradient: PropTypes.shape({
    color: PropTypes.string.isRequired,
    thickness: PropTypes.number.isRequired,
  }),
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  initialDisplayState: PropTypes.number,
  initialOpacity: PropTypes.number,
  interactive: PropTypes.bool.isRequired,
  left: PropTypes.number,
  links: linksPropTypes,
  moveLayersProps,
  onActionToBeExecutedOnPage: PropTypes.func.isRequired,
  onExecuteLinkAction: PropTypes.func.isRequired,
  onExecuteLayersAction: PropTypes.func.isRequired,
  onGoToPage: PropTypes.func.isRequired,
  onObjectActivated: PropTypes.func.isRequired,
  onObjectStartDragging: PropTypes.func.isRequired,
  onObjectStopDragging: PropTypes.func.isRequired,
  opacity: PropTypes.number,
  pageActivatedAt: PropTypes.number,
  projectHeight: PropTypes.number.isRequired,
  projectWidth: PropTypes.number.isRequired,
  removeLayersIds: PropTypes.arrayOf(PropTypes.number),
  rotation: PropTypes.number,
  scaleFactor: PropTypes.number.isRequired,
  scoreAndEvaluation: PropTypes.shape({
    highlight: PropTypes.bool.isRequired,
    scorePerCorrectAnswer: PropTypes.number.isRequired,
    correctAnswerLayersAction: evaluationLayersActionsPropTypes,
    wrongAnswerLayersAction: evaluationLayersActionsPropTypes,
  }),
  setLayersIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string,
  toggleLayersIds: PropTypes.arrayOf(PropTypes.number),
  top: PropTypes.number,
  translateProps: PropTypes.shape({}),
  width: PropTypes.number,
  zIndex: PropTypes.number.isRequired,
  zoomAndPan: PropTypes.bool.isRequired,
  zoomLayersProps,
};
