// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/ended_event

const sounds = {};
const loadedSoundsIds = [];
const playingSoundsIds = [];
const soundsIdsToBeStartedQueue = [];

const getSoundProperty = async (soundId, key) => {
  const soundProps = sounds[soundId];
  if (!soundProps) throw new Error("Sound does not exist");
  return soundProps.sound[key];
};

const getSoundCurrentTime = (soundId) =>
  getSoundProperty(soundId, "currentTime");

const getSoundDuration = (soundId) => getSoundProperty(soundId, "duration");

const isSoundPaused = (soundId) => getSoundProperty(soundId, "paused");

const isSoundPlaying = async (soundId) => {
  try {
    const isPaused = await getSoundProperty(soundId, "paused");
    const isEnded = await getSoundProperty(soundId, "ended");
    return !isPaused && !isEnded;
  } catch (e) {
    return e;
  }
};

const pauseSound = async (soundId) => {
  const soundProps = sounds[soundId];
  if (!soundProps) {
    // throw new Error("Sound does not exist");
    return;
  }
  if (playingSoundsIds.includes(soundId)) {
    playingSoundsIds.splice(playingSoundsIds.indexOf(soundId), 1);
    soundProps.sound.pause();
  }
};

const playSound = async (soundId) => {
  const soundProps = sounds[soundId];
  if (!soundProps) {
    throw new Error("Sound does not exist");
  }
  if (!playingSoundsIds.includes(soundId)) {
    if (loadedSoundsIds.includes(soundId)) {
      soundProps.sound.play();
      playingSoundsIds.push(soundId);
    } else if (!soundsIdsToBeStartedQueue.includes(soundId)) {
      soundsIdsToBeStartedQueue.push(soundId);
    }
  }
};

const playSounds = (soundsIds) =>
  Promise.allSettled(soundsIds.map((soundId) => playSound(soundId)));

const restartSound = (soundId) => {
  const soundProps = sounds[soundId];
  if (!soundProps) {
    throw new Error("Sound does not exist");
  }
  const { sound } = soundProps;
  sound.volume = 1;
  sound.currentTime = 0;
  return playSound(soundId);
};
const resumeSound = (soundId) => playSound(soundId);
const setSoundCurrentTime = async (soundId, currentTime) => {
  const soundProps = sounds[soundId];
  if (!soundProps) {
    throw new Error("Sound does not exist");
  }
  const { sound } = soundProps;
  sound.currentTime = currentTime;
};
const setSoundVolume = (soundId, volume) => {
  const soundProps = sounds[soundId];
  if (!soundProps) {
    throw new Error("Sound does not exist");
  }
  const { sound } = soundProps;
  sound.volume = volume;
};
const stopSound = async (soundId) => {
  const soundProps = sounds[soundId];
  if (!soundProps) {
    // throw new Error("Sound does not exist");
    return;
  }
  if (playingSoundsIds.includes(soundId)) {
    soundProps.sound.pause();
    soundProps.sound.currentTime = 0;
    playingSoundsIds.splice(playingSoundsIds.indexOf(soundId), 1);
  }
};

const stopSounds = (soundsIds) =>
  Promise.allSettled(soundsIds.map((soundId) => stopSound(soundId)));

const stopAllSounds = () => stopSounds(playingSoundsIds);

const destroySound = async (soundId) => {
  const soundProps = sounds[soundId];
  if (!soundProps) {
    return;
    // throw new Error("Sound does not exist");
  }

  const {
    sound,
    onCanPlayTrough,
    onEnded,
    onError,
    onPause,
    onPlayStart,
    onTimeUpdate,
  } = soundProps;

  await stopSound(soundId);

  sound.removeEventListener("canplaythrough", onCanPlayTrough, false);
  sound.removeEventListener("ended", onEnded, false);
  sound.removeEventListener("error", onError, false);
  sound.removeEventListener("pause", onPause, false);
  sound.removeEventListener("play", onPlayStart, false);
  sound.removeEventListener("timeupdate", onTimeUpdate, false);

  delete sounds[soundId];
  if (loadedSoundsIds.includes(soundId)) {
    loadedSoundsIds.splice(loadedSoundsIds.indexOf(soundId), 1);
  }

  if (soundsIdsToBeStartedQueue.includes(soundId)) {
    soundsIdsToBeStartedQueue.splice(
      soundsIdsToBeStartedQueue.indexOf(soundId),
      1
    );
  }
};

const destroySounds = (soundsIds) =>
  Promise.allSettled(soundsIds.map((soundId) => destroySound(soundId)));

const destroyAllSounds = () => destroySounds(Object.keys(sounds));

const getOnCanPlayTrough = (soundId, handler) => () => {
  loadedSoundsIds.push(soundId);
  if (soundsIdsToBeStartedQueue.includes(soundId)) {
    soundsIdsToBeStartedQueue.splice(
      soundsIdsToBeStartedQueue.indexOf(soundId),
      1
    );
    playSound(soundId).then();
  }
  if (handler) {
    handler(soundId);
  }
};

const getOnError = (soundId, handler) => () => {
  if (handler) {
    handler(soundId);
  }
  delete sounds[soundId];
};

const getOnEnded = (soundId, handler) => () => {
  if (handler) {
    handler(soundId);
  }
  if (playingSoundsIds.includes(soundId)) {
    playingSoundsIds.splice(playingSoundsIds.indexOf(soundId), 1);
  }
};

const getOnGenericHandler = (soundId, handler) => () => {
  if (handler) {
    handler(soundId);
  }
};

const createSound = async (soundInfo) => {
  try {
    await destroySound(soundInfo.id);
  } catch (e) {
    // do nothing
  }

  const sound = new Audio();
  const soundId = soundInfo.id;
  const canPlayTroughHandler = getOnCanPlayTrough(
    soundId,
    soundInfo.onCanPlayTrough && soundInfo.onCanPlayTrough instanceof Function
      ? soundInfo.onCanPlayTrough
      : null
  );
  const onEndedHandler = getOnEnded(
    soundId,
    soundInfo.onEnded && soundInfo.onEnded instanceof Function
      ? soundInfo.onEnded
      : null
  );
  const onErrorHandler = getOnError(
    soundId,
    soundInfo.onError && soundInfo.onError instanceof Function
      ? soundInfo.onError
      : null
  );

  const onPauseHandler = getOnGenericHandler(
    soundId,
    soundInfo.onPause && soundInfo.onPause instanceof Function
      ? soundInfo.onPause
      : null
  );

  const onPlayStartHandler = getOnGenericHandler(
    soundId,
    soundInfo.onPlayStart && soundInfo.onPlayStart instanceof Function
      ? soundInfo.onPlayStart
      : null
  );

  const onTimeUpdateHandler = getOnGenericHandler(
    soundId,
    soundInfo.onTimeUpdate && soundInfo.onTimeUpdate instanceof Function
      ? soundInfo.onTimeUpdate
      : null
  );

  sound.addEventListener("canplaythrough", canPlayTroughHandler, false);
  sound.addEventListener("ended", onEndedHandler, false);
  sound.addEventListener("error", onErrorHandler, false);
  sound.addEventListener("pause", onPauseHandler, false);
  sound.addEventListener("play", onPlayStartHandler, false);
  sound.addEventListener("timeupdate", onTimeUpdateHandler, false);

  sounds[soundInfo.id] = {
    sound,
    onCanPlayTrough: canPlayTroughHandler,
    onEnded: onEndedHandler,
    onError: onErrorHandler,
    onPause: onPauseHandler,
    onPlayStart: onPlayStartHandler,
    onTimeUpdate: onTimeUpdateHandler,
  };
  sound.src = soundInfo.src;
  sound.load();
};

export default {
  createSound,
  destroySound,
  destroySounds,
  destroyAllSounds,
  getSoundCurrentTime,
  getSoundDuration,
  isSoundPaused,
  isSoundPlaying,
  pauseSound,
  playSound,
  playSounds,
  restartSound,
  resumeSound,
  setSoundCurrentTime,
  setSoundVolume,
  stopSound,
  stopSounds,
  stopAllSounds,
};
