import DatabaseService from "@services/DatabaseService";
import { getRootProjectIdForCurrentProjectId } from "./Utils";

const filterOutDuplicatedLayersByName = (layersToBeFiltered, updatedLayers) =>
  Object.keys(layersToBeFiltered)
    .map((layerId) => {
      const currentLayer = layersToBeFiltered[layerId];
      const isUpdated = Object.keys(updatedLayers).find(
        (updatedLayerKey) =>
          updatedLayers[updatedLayerKey].name === currentLayer.name
      );
      return isUpdated ? null : currentLayer;
    })
    .filter((layer) => layer);

const getUpdatedLayersForSetAction = (
  layersIds,
  pageLayers,
  allLayers,
  layersClasses
) => {
  const updatedProjectLayers = {};
  const updatedPageLayers = {};

  layersIds.forEach((layerId) => {
    const layersIdsInSameClass = layersClasses.find((layersIdsInClass) =>
      layersIdsInClass.includes(layerId)
    );

    layersIdsInSameClass.forEach((lId) => {
      if (pageLayers[lId]) {
        const layer = pageLayers[lId];
        if (
          (layer.active && lId !== layerId) ||
          (!layer.active && lId === layerId)
        ) {
          updatedPageLayers[lId] = {
            ...pageLayers[lId],
            active: lId === layerId,
          };
        }
      }

      if (!pageLayers[lId]) {
        const layer = allLayers[lId];
        if (
          (layer.active && lId !== layerId) ||
          (!layer.active && lId === layerId)
        ) {
          updatedProjectLayers[lId] = {
            ...allLayers[lId],
            active: lId === layerId,
          };
        }
      }
    });
  });

  return {
    updatedPageLayersForSetAction: updatedPageLayers,
    updatedProjectsLayersForSetAction: updatedProjectLayers,
  };
};

const getUpdatedLayersForToggleAction = (layersIds, pageLayers, allLayers) => {
  const updatedProjectLayers = {};
  const updatedPageLayers = {};

  layersIds.forEach((layerId) => {
    if (pageLayers[layerId]) {
      const layer = pageLayers[layerId];
      updatedPageLayers[layerId] = {
        ...layer,
        active: !layer.active,
      };
    } else {
      const layer = allLayers[layerId];
      updatedProjectLayers[layerId] = {
        ...layer,
        active: !layer.active,
      };
    }
  });

  return {
    updatedPageLayersForToggleAction: updatedPageLayers,
    updatedProjectsLayersForToggleAction: updatedProjectLayers,
  };
};

const getUpdatedLayersForRemoveAction = (layersIds, pageLayers, allLayers) => {
  const updatedProjectLayers = {};
  const updatedPageLayers = {};

  layersIds.forEach((layerId) => {
    if (pageLayers[layerId] && pageLayers[layerId].active) {
      updatedPageLayers[layerId] = {
        ...pageLayers[layerId],
        active: false,
      };
    }
    if (!pageLayers[layerId] && allLayers[layerId].active) {
      updatedProjectLayers[layerId] = {
        ...allLayers[layerId],
        active: false,
      };
    }
  });

  return {
    updatedPageLayersForRemoveAction: updatedPageLayers,
    updatedProjectsLayersForRemoveAction: updatedProjectLayers,
  };
};

const getUpdatedLayersForAddAction = (layersIds, pageLayers, allLayers) => {
  const updatedProjectLayers = {};
  const updatedPageLayers = {};

  layersIds.forEach((layerId) => {
    if (pageLayers[layerId] && !pageLayers[layerId].active) {
      updatedPageLayers[layerId] = {
        ...pageLayers[layerId],
        active: true,
      };
    }
    if (!pageLayers[layerId] && !allLayers[layerId].active) {
      updatedProjectLayers[layerId] = {
        ...allLayers[layerId],
        active: true,
      };
    }
  });

  return {
    updatedPageLayersForAddAction: updatedPageLayers,
    updatedProjectsLayersForAddAction: updatedProjectLayers,
  };
};

const updatePersistentLayers = async (projectId, persistentLayers) => {
  const storedLayers = (await DatabaseService.get("storedLayers", { data: {} }))
    .data;
  await DatabaseService.put("storedLayers", {
    ...storedLayers,
    [projectId]: {
      ...filterOutDuplicatedLayersByName(
        storedLayers[projectId],
        persistentLayers
      ),
      ...persistentLayers,
    },
  });
};

const updatePersistentLayersAfterUserAction = async (
  projectId,
  allLayers,
  updatedPageLayers,
  updatedProjectLayers
) => {
  const updatedLayers = { ...updatedPageLayers, ...updatedProjectLayers };
  const updatedPersistentLayers = {};
  Object.keys(updatedLayers).forEach((layerId) => {
    const layer = updatedLayers[layerId];
    if (layer.persistent) {
      updatedPersistentLayers[layerId] = layer;
    }
  });

  const updatedPersistentLayersIds = Object.keys(updatedPersistentLayers);
  if (!updatedPersistentLayersIds.length) {
    return;
  }

  const globalPersistentLayers = {};
  const projectPersistentLayers = {};

  updatedPersistentLayersIds.forEach((layerId) => {
    const layer = updatedPersistentLayers[layerId];
    if (layer.global) {
      globalPersistentLayers[layerId] = layer;
    } else {
      projectPersistentLayers[layerId] = layer;
    }
  });

  let rootProjectId;

  if (Object.keys(globalPersistentLayers).length) {
    const existingRelatedProjectsInfo = (
      await DatabaseService.get("relatedProjectsInfo", { data: {} })
    ).data;

    rootProjectId = getRootProjectIdForCurrentProjectId(
      existingRelatedProjectsInfo,
      projectId
    );
    if (rootProjectId === projectId) {
      await updatePersistentLayers(rootProjectId, {
        ...globalPersistentLayers,
        ...projectPersistentLayers,
      });
    } else {
      await updatePersistentLayers(rootProjectId, globalPersistentLayers);
    }
  }

  if (
    Object.keys(projectPersistentLayers).length &&
    rootProjectId !== projectId
  ) {
    await updatePersistentLayers(projectId, projectPersistentLayers);
  }
};

const updateLayers = async (
  projectId,
  addLayersIds,
  setLayersIds,
  toggleLayersIds,
  removeLayersIds,
  pageLayers,
  allLayers,
  layersClasses,
  setPageLayers,
  setAllLayers
) => {
  const { updatedPageLayersForAddAction, updatedProjectsLayersForAddAction } =
    getUpdatedLayersForAddAction(addLayersIds || [], pageLayers, allLayers);

  const {
    updatedPageLayersForToggleAction,
    updatedProjectsLayersForToggleAction,
  } = getUpdatedLayersForToggleAction(
    toggleLayersIds || [],
    pageLayers,
    allLayers
  );

  const { updatedPageLayersForSetAction, updatedProjectsLayersForSetAction } =
    getUpdatedLayersForSetAction(
      setLayersIds || [],
      pageLayers,
      allLayers,
      layersClasses
    );

  const {
    updatedPageLayersForRemoveAction,
    updatedProjectsLayersForRemoveAction,
  } = getUpdatedLayersForRemoveAction(
    removeLayersIds || [],
    pageLayers,
    allLayers
  );

  const updatedPageLayers = {
    ...updatedPageLayersForAddAction,
    ...updatedPageLayersForToggleAction,
    ...updatedPageLayersForSetAction,
    ...updatedPageLayersForRemoveAction,
  };
  const updatedProjectLayers = {
    ...updatedProjectsLayersForAddAction,
    ...updatedProjectsLayersForToggleAction,
    ...updatedProjectsLayersForSetAction,
    ...updatedProjectsLayersForRemoveAction,
  };

  if (setPageLayers && Object.keys(updatedPageLayers).length) {
    setPageLayers({ ...pageLayers, ...updatedPageLayers });
  }

  if (Object.keys(updatedProjectLayers).length) {
    setAllLayers({ ...allLayers, ...updatedProjectLayers });
  }

  await updatePersistentLayersAfterUserAction(
    projectId,
    allLayers,
    updatedPageLayers,
    updatedProjectLayers
  );
};

const removePersistentGlobalLayerForProject = async (projectId) => {
  const storedLayers = (await DatabaseService.get("storedLayers", { data: {} }))
    .data;
  const storedLayersForProject = storedLayers[projectId] || {};
  const layersThatNeedToBeStored = {};
  Object.keys(storedLayersForProject).forEach((layerId) => {
    const layer = storedLayersForProject[layerId];
    if (layer.global) {
      return;
    }
    layersThatNeedToBeStored[layerId] = layer;
  });

  delete storedLayers[projectId];

  if (Object.keys(layersThatNeedToBeStored).length) {
    storedLayers[projectId] = layersThatNeedToBeStored;
  }
  if (Object.keys(storedLayers).length) {
    await DatabaseService.put("storedLayers", storedLayers);
  } else {
    await DatabaseService.delete("storedLayers");
  }
};

const updatePersistentLayersWhenProjectIsLoad = async (projectId, layers) => {
  const storedLayers = (await DatabaseService.get("storedLayers", { data: {} }))
    .data;
  const storedLayersForProject = storedLayers[projectId] || {};
  const layersIds = Object.keys(layers);
  const updatedLayersFromStoredOnes = {};
  const newLayersThatNeedToBeStored = {};
  let updatedFromHistory = false;
  layersIds.forEach((layerId) => {
    const layer = layers[layerId];
    const layerName = layer.name;

    const storedLayerWithSameName =
      storedLayersForProject[
        Object.keys(storedLayersForProject).find(
          (storedLayerId) =>
            storedLayersForProject[storedLayerId].name === layerName
        )
      ];

    if (!storedLayerWithSameName) {
      newLayersThatNeedToBeStored[layerId] = layer;
      return;
    }

    if (storedLayerWithSameName.active !== layer.active) {
      updatedLayersFromStoredOnes[layerId] = storedLayerWithSameName;
      updatedFromHistory = true;
    }
  });

  if (Object.keys(newLayersThatNeedToBeStored).length) {
    const layersToBeStored = {
      ...storedLayers,
      [projectId]: {
        ...storedLayersForProject,
        ...newLayersThatNeedToBeStored,
      },
    };
    await DatabaseService.put("storedLayers", layersToBeStored);
  }

  const filteredOutUpdatedLayers = filterOutDuplicatedLayersByName(
    layers,
    updatedLayersFromStoredOnes
  );

  return {
    layers: { ...filteredOutUpdatedLayers, ...updatedLayersFromStoredOnes },
    updated: updatedFromHistory,
  };
};

const getLayersAfterMergingWithPersistentLayersStoredOnClient = async (
  projectId,
  layers
) => {
  const layersIds = Object.keys(layers);
  const projectPersistentLayers = {};
  const globalPersistentLayers = {};
  let globalPersistentLayersAvailable = false;
  let projectPersistentLayersAvailable = false;

  let returnLayers = { ...layers };

  layersIds.forEach((layerId) => {
    const layer = layers[layerId];
    if (!layer.persistent) return;
    if (layer.global) {
      globalPersistentLayers[layerId] = layer;
      globalPersistentLayersAvailable = true;
    } else {
      projectPersistentLayers[layerId] = layer;
      projectPersistentLayersAvailable = true;
    }
  });

  if (globalPersistentLayersAvailable) {
    const existingRelatedProjectsInfo = (
      await DatabaseService.get("relatedProjectsInfo", { data: {} })
    ).data;

    const rootProjectId = getRootProjectIdForCurrentProjectId(
      existingRelatedProjectsInfo,
      projectId
    );

    const updatedLayersInfo = await updatePersistentLayersWhenProjectIsLoad(
      rootProjectId,
      globalPersistentLayers
    );
    if (updatedLayersInfo.updated) {
      returnLayers = { ...returnLayers, ...updatedLayersInfo.layers };
    }
    if (projectId !== rootProjectId) {
      await removePersistentGlobalLayerForProject(projectId);
    }
  }

  if (projectPersistentLayersAvailable) {
    const updatedLayersInfo = await updatePersistentLayersWhenProjectIsLoad(
      projectId,
      projectPersistentLayers
    );
    if (updatedLayersInfo.updated) {
      returnLayers = { ...returnLayers, ...updatedLayersInfo.layers };
    }
  }

  return returnLayers;
};

export default {
  updateLayers,
  getLayersAfterMergingWithPersistentLayersStoredOnClient,
};
