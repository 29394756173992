import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import HtmlTextParser from "@utils/HtmlTextParser";
import useComponentDidMount from "@hooks/UseComponentDidMount";

const getKaraokeContent = (wordsInfo, content) => {
  const node = document.createElement("div");
  node.innerHTML = content;
  let htmlContent = node.innerHTML;

  for (
    let wordsIndex = wordsInfo.length - 1;
    wordsIndex >= 0;
    wordsIndex -= 1
  ) {
    const { startIndex, endIndex } = wordsInfo[wordsIndex];
    const word = htmlContent.substring(startIndex, endIndex);
    const syncWrappedWord = `<span class="partOfKaraoke">${word} </span>`;
    htmlContent =
      htmlContent.substring(0, startIndex) +
      syncWrappedWord +
      htmlContent.substring(endIndex);
  }
  return htmlContent;
};

const StyledText = styled.div`
  position: absolute;

  ${({ height, width, zoomFactor }) => `
    height:${height}px;
    transform: scale(${zoomFactor});
    width:${width}px;
  `}
  transform-origin: 0 0;
  font-size: 16px;
  overflow: auto;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ backgroundOpacity }) => `opacity: ${backgroundOpacity};`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
`;

const Text = React.memo(
  ({
    backgroundColor,
    backgroundOpacity,
    content,
    height,
    id,
    karaokeSyncIndex,
    onKaraokeTextLoaded,
    onObjectLoad,
    syncPoints,
    width,
    zoomFactor,
  }) => {
    const TextRef = useRef();

    const updatedContent = useRef(
      (() =>
        syncPoints
          ? getKaraokeContent(
              HtmlTextParser.parse(content),
              content,
              syncPoints
            )
          : content)()
    );

    const karaokeNodes = useRef([]);

    useComponentDidMount(() => {
      if (!syncPoints) {
        onObjectLoad(id);
        return;
      }

      karaokeNodes.current = [
        ...TextRef.current.getElementsByClassName("partOfKaraoke"),
      ];

      karaokeNodes.current.forEach((node, index) => {
        const highlightDuration =
          index > 0
            ? syncPoints[index] - syncPoints[index - 1]
            : syncPoints[index];

        // eslint-disable-next-line no-param-reassign
        node.style.transitionProperty = "background-color, color";
        // eslint-disable-next-line no-param-reassign
        node.style.transitionDuration = `${highlightDuration}ms, ${highlightDuration}ms`;
        // eslint-disable-next-line no-param-reassign
        node.style.transitionTimingFunction = "linear, linear";
      });
      onKaraokeTextLoaded(id, karaokeNodes.current.length);
      onObjectLoad(id);
    });
    useEffect(() => {
      karaokeNodes.current.forEach((node, index) => {
        if (index > karaokeSyncIndex) {
          if (node.className === "") return;
          // eslint-disable-next-line no-param-reassign
          node.className = "";
        } else {
          if (node.className === "highlightingText karaokeHighlight") return;
          // eslint-disable-next-line no-param-reassign
          node.className = "highlightingText karaokeHighlight";
        }
      });
    }, [id, karaokeSyncIndex]);

    return (
      <>
        <Container
          backgroundColor={backgroundColor}
          backgroundOpacity={backgroundOpacity}
        />
        {(syncPoints || null) && (
          <StyledText
            ref={TextRef}
            height={height}
            width={width}
            zoomFactor={zoomFactor}
            dangerouslySetInnerHTML={{
              __html: updatedContent.current,
            }}
          />
        )}
        {(!syncPoints || null) && (
          <StyledText
            height={height}
            width={width}
            zoomFactor={zoomFactor}
            dangerouslySetInnerHTML={{
              __html: updatedContent.current,
            }}
          />
        )}
      </>
    );
  }
);

Text.defaultProps = {
  backgroundColor: undefined,
  karaokeSyncIndex: -1,
  syncPoints: null,
};

Text.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  karaokeSyncIndex: PropTypes.number,
  onObjectLoad: PropTypes.func.isRequired,
  onKaraokeTextLoaded: PropTypes.func.isRequired,
  syncPoints: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number.isRequired,
};

export default Text;
