window.history.pushState = ((f) =>
  function pushState() {
    // eslint-disable-next-line prefer-rest-params
    const ret = f.apply(this, arguments);
    window.dispatchEvent(new Event("locationChange"));
    return ret;
  })(window.history.pushState);

window.addEventListener(
  "hashchange",
  () => {
    window.location.reload();
  },
  false
);

window.addEventListener("popstate", () => {
  window.dispatchEvent(new Event("locationChange"));
});

const pushState = (titleId, page) => {
  const url = new URL(window.location);
  url.searchParams.set("shareURL", titleId);
  url.hash = `goToPage-${page}`;
  window.history.pushState({}, null, url.toString());
};
const back = () => {
  window.history.back();
};

export default { pushState, back };
