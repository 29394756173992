import { useCallback, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useComponentDidMount from "@hooks/UseComponentDidMount";
import InAppPurchaseService from "@services/InAppPurchaseService";
import DatabaseService from "@services/DatabaseService";
import {
  bottomMenuAtom,
  deviceOrientationAtom,
  layersAtom,
  layersClassesAtom,
  openedPagesAtom,
  pagesAtom,
  pagesModelsAtom,
  settingsAtom,
} from "@stateManagement/Atoms";
import LayersUtils from "@utils/LayersUtils";
import { LayerActionType, MenuState, ObjectType } from "@constants/Constants";
import {
  getPagesIndexesToLoad,
  shouldLoadTwoPages,
  updateUrlHash,
} from "@utils/Utils";
import AudioPlayer from "@media/player/AudioPlayer";
import SubscriberService from "../services/SubscriberService";

const API_IDENTIFIER = "TBA";

const PostMessageListener = () => {
  const [layers, setRecoilLayers] = useRecoilState(layersAtom);
  const layersClasses = useRecoilValue(layersClassesAtom);
  const deviceOrientation = useRecoilValue(deviceOrientationAtom);
  const setStorageUpdated = useSetRecoilState(deviceOrientationAtom);
  const { orientation: projectOrientation, reflowable } =
    useRecoilValue(settingsAtom);
  const [openedPages, setOpenedPages] = useRecoilState(openedPagesAtom);
  const [menuModel, setMenuModel] = useRecoilState(bottomMenuAtom);
  const pages = useRecoilValue(pagesAtom);
  const pageModels = useRecoilValue(pagesModelsAtom);

  const onProgressActions = useRef({});

  const sendResponse = useCallback((actionId, response) => {
    if (onProgressActions[actionId]) {
      return;
    }
    const actionInfo = onProgressActions.current[actionId];
    actionInfo.source.postMessage(response, actionInfo.origin);
    delete onProgressActions.current[actionId];
  }, []);

  const storeActionSource = useCallback((id, messageEvent) => {
    const { source, origin } = messageEvent;
    onProgressActions.current[id] = {
      source,
      origin,
    };
  }, []);

  const getDefaultResponse = useCallback(
    (actionId) => ({
      id: actionId,
      identifier: API_IDENTIFIER,
      type: "response",
      status: "success",
    }),
    []
  );

  const updateLayers = useCallback(
    (actionType, layersIdentifiers, actionId) => {
      const response = getDefaultResponse(actionId);

      if (!layersIdentifiers) {
        return {
          ...response,
          status: "error",
        };
      }

      const layersKeys = Object.keys(layers);

      const layersIds = layersKeys.filter((layerKey) =>
        layersIdentifiers.includes(layers[layerKey].identifier)
      );

      LayersUtils.updateLayersForAction(
        actionType,
        layersIds,
        layers,
        layersClasses,
        setRecoilLayers
      );

      return response;
    },
    [getDefaultResponse, layers, layersClasses, setRecoilLayers]
  );

  const purchase = useCallback(
    async (actionId, productId) => {
      const response = {
        ...getDefaultResponse(),
        message: `Purchased IAP with id: ${productId}`,
        parameters: productId,
      };

      try {
        await InAppPurchaseService.purchase();
      } catch (e) {
        response.status = "error";
        response.message = e;
      }

      sendResponse(actionId, response);
    },
    [getDefaultResponse, sendResponse]
  );

  const getPrices = useCallback(
    async (actionId, productIds) => {
      const response = {
        ...getDefaultResponse(),
        message: "Prices fetched",
        parameters: [],
      };

      try {
        const { validProducts, invalidProducts } =
          await InAppPurchaseService.getProductsData(productIds);

        const products = [...validProducts, ...invalidProducts];
        const filteredProducts = productIds
          .map((productId) =>
            products.find((product) => product.id === productId)
          )
          .filter((product) => product);

        response.status = filteredProducts.length === productIds.length;
        response.parameters = products.map(({ id, price }) => ({ id, price }));
      } catch (e) {
        response.status = "error";
        response.message = e;
      }
      sendResponse(actionId, response);
    },
    [getDefaultResponse, sendResponse]
  );

  const restoreIAP = useCallback(
    async (actionId, productsIds) => {
      const response = {
        ...getDefaultResponse(),
        message: "",
        parameters: {},
      };

      try {
        response.parameters = await InAppPurchaseService.restoreProducts(
          productsIds
        );
      } catch (e) {
        response.status = "error";
        response.message = e;
      }

      sendResponse(actionId, response);
    },
    [getDefaultResponse, sendResponse]
  );

  const addLayers = useCallback(
    (actionId, layersIdentifier) => {
      sendResponse(actionId, {
        ...updateLayers(LayerActionType.ADD, layersIdentifier, actionId),
        message: "Added layers with ID:  ' + layersIds",
      });
    },
    [sendResponse, updateLayers]
  );

  const removeLayers = useCallback(
    (actionId, layersIdentifier) => {
      sendResponse(actionId, {
        ...updateLayers(LayerActionType.REMOVE, layersIdentifier, actionId),
        message: "Removed layers with ID:  ' + layersIds",
      });
    },
    [sendResponse, updateLayers]
  );

  const setLayers = useCallback(
    (actionId, layersIdentifier) => {
      sendResponse(actionId, {
        ...updateLayers(LayerActionType.SET, layersIdentifier, actionId),
        message: "Set layers with ID:  ' + layersIds",
      });
    },
    [sendResponse, updateLayers]
  );

  const toggleLayers = useCallback(
    (actionId, layersIdentifier) => {
      sendResponse(actionId, {
        ...updateLayers(LayerActionType.TOGGLE, layersIdentifier, actionId),
        message: "Toggled layers with ID:  ' + layersIds",
      });
    },
    [sendResponse, updateLayers]
  );
  const goToPage = useCallback(
    (actionId, pageNumber) => {
      const loadTwoPages = shouldLoadTwoPages(
        reflowable,
        projectOrientation,
        deviceOrientation
      );

      const pagesIndexesToLoad = getPagesIndexesToLoad(
        pageNumber,
        loadTwoPages,
        pages
      );

      if (openedPages[0] !== pagesIndexesToLoad[0]) {
        updateUrlHash(pagesIndexesToLoad[0]);
        setOpenedPages(pagesIndexesToLoad);
      }
      sendResponse(actionId, {
        ...getDefaultResponse(actionId),
        parameters: pageNumber,
      });
    },
    [
      deviceOrientation,
      getDefaultResponse,
      openedPages,
      pages,
      projectOrientation,
      reflowable,
      sendResponse,
      setOpenedPages,
    ]
  );

  const goToProject = useCallback((actionId, projectId) => {
    // TODO: implement go to project
    console.log(actionId, projectId);
  }, []);

  const goToWeb = useCallback((actionId, projectId) => {
    // TODO: implement go to project
    console.log(actionId, projectId);
  }, []);

  const openEmailClient = useCallback((actionId, projectId) => {
    // TODO: implement go to project
    console.log(actionId, projectId);
  }, []);

  const toggleMenuState = useCallback(
    (actionId, parameters) => {
      const state =
        menuModel.state === MenuState.OPEN ? MenuState.CLOSED : MenuState.OPEN;
      setMenuModel({ ...menuModel, state });
      sendResponse(actionId, {
        ...getDefaultResponse(actionId),
        parameters,
      });
    },
    [getDefaultResponse, menuModel, sendResponse, setMenuModel]
  );

  const getCurrentSelection = useCallback(
    (actionId) => {
      sendResponse(actionId, {
        ...getDefaultResponse(actionId),
        parameters: {
          currentSelection: Array.from(
            document.getElementsByTagName("iframe")
          ).reduce(
            (selection, iFrame) =>
              selection + iFrame.contentWindow.getSelection().toString(),
            window.getSelection().toString()
          ),
        },
      });
    },
    [getDefaultResponse, sendResponse]
  );

  const getSoundsForOpenedPages = useCallback(
    (identifier) =>
      openedPages.reduce((loadedPagesSounds, openedPage) => {
        const pageModel = pageModels[pages[openedPage]];
        const { objects } = pageModel;
        return [
          ...loadedPagesSounds,
          ...objects.reduce(
            (pageSounds, object) =>
              object.type === ObjectType.SOUND &&
              object.identifier === identifier
                ? [...pageSounds, object]
                : pageSounds,
            []
          ),
        ];
      }, []),
    [openedPages, pageModels, pages]
  );

  const startSound = useCallback(
    (actionId, identifier) => {
      AudioPlayer.playSounds(getSoundsForOpenedPages(identifier));
      sendResponse(actionId, {
        ...getDefaultResponse(actionId),
        parameters: identifier,
      });
    },
    [getDefaultResponse, getSoundsForOpenedPages, sendResponse]
  );

  const stopSound = useCallback(
    (actionId, identifier) => {
      AudioPlayer.stopSounds(getSoundsForOpenedPages(identifier));
      sendResponse(actionId, {
        ...getDefaultResponse(actionId),
        parameters: identifier,
      });
    },
    [getDefaultResponse, getSoundsForOpenedPages, sendResponse]
  );

  const download = useCallback(
    async (actionId, parameters) => {
      const response = {
        ...getDefaultResponse(actionId),
      };

      try {
        await window.tapbookauthor.plugins.download(
          parameters.relativePath,
          parameters.url
        );
      } catch (e) {
        response.status = "error";
      }
      sendResponse(actionId, response);
    },
    [getDefaultResponse, sendResponse]
  );

  const downloadAndUnzip = useCallback(
    async (actionId, parameters) => {
      const response = {
        ...getDefaultResponse(actionId),
      };

      try {
        await window.tapbookauthor.plugins.download(
          parameters.relativePath,
          parameters.url
        );
      } catch (e) {
        response.status = "error";
      }

      // TODO: check how unzip works

      sendResponse(actionId, response);
    },
    [getDefaultResponse, sendResponse]
  );

  const getDownloadPath = useCallback(
    (actionId) => {
      const status = ""; // TODO: check download path
      const downloadPath = "";
      sendResponse(actionId, {
        ...getDefaultResponse(actionId),
        parameters: downloadPath,
        status,
      });
    },
    [getDefaultResponse, sendResponse]
  );

  const startVideo = useCallback(() => {
    // TODO: startVideo
  }, []);

  const stopVideo = useCallback(() => {
    // TODO: stopVideo
  }, []);

  const setOrientation = useCallback(() => {
    // TODO: setOrientation
  }, []);

  const subscribe = useCallback((actionId, parameters) => {
    const { event } = parameters;
    SubscriberService.subscribe(event, actionId);
  }, []);

  const persist = useCallback(
    async (actionId, parameters) => {
      const { data } = parameters;
      data.reduce(
        (promise, entry) =>
          promise.then(() => DatabaseService.put(entry.key, entry.value)),
        Promise.resolve()
      );

      setStorageUpdated({});
    },
    [setStorageUpdated]
  );

  const dispatch = (actionId, event, data) => {
    if (onProgressActions[actionId]) {
      return;
    }

    const actionInfo = onProgressActions.current[actionId];
    actionInfo.source.postMessage(
      {
        ...getDefaultResponse(actionId),
        event,
        data,
      },
      actionInfo.origin
    );
  };

  const actions = useRef({
    purchase,
    getPrices,
    restoreIAP,
    addLayers,
    removeLayers,
    setLayers,
    toggleLayers,
    goToPage,
    goToProject,
    goToWeb,
    openEmailClient,
    toggleMenuState,
    getCurrentSelection,
    startSound,
    stopSound,
    download,
    downloadAndUnzip,
    getDownloadPath,
    startVideo,
    stopVideo,
    setOrientation,
    subscribe,
    persist,
  });

  const receiveMessage = useCallback(
    (e) => {
      const messageData = e.data;

      if (typeof messageData === "object") {
        // this complies with messaging API

        const { identifier, id: actionId, type: actionType } = messageData;
        if (!identifier || !actionId || !actionType) return;
        if (identifier !== API_IDENTIFIER || actionType !== "request") return;

        storeActionSource(actionId, e);

        const { message } = messageData;

        const { action, parameters } = message;

        if (actions.current[action]) {
          actions.current[action](actionId, parameters);
        }
      }
    },
    [storeActionSource]
  );
  useEffect(() => {
    actions.current = {
      purchase,
      getPrices,
      restoreIAP,
      addLayers,
      removeLayers,
      setLayers,
      toggleLayers,
      goToPage,
      goToProject,
      goToWeb,
      openEmailClient,
      toggleMenuState,
      getCurrentSelection,
      startSound,
      stopSound,
      download,
      downloadAndUnzip,
      getDownloadPath,
      startVideo,
      stopVideo,
      setOrientation,
      subscribe,
      persist,
    };
  }, [
    addLayers,
    download,
    downloadAndUnzip,
    getCurrentSelection,
    getDownloadPath,
    getPrices,
    goToPage,
    goToProject,
    goToWeb,
    openEmailClient,
    purchase,
    removeLayers,
    restoreIAP,
    setLayers,
    setOrientation,
    startSound,
    startVideo,
    stopSound,
    stopVideo,
    subscribe,
    toggleLayers,
    toggleMenuState,
    persist,
  ]);

  useComponentDidMount(() => {
    SubscriberService.attach(dispatch);
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  });

  return null;
};

export default PostMessageListener;
