import { Base64 } from "js-base64";

let db;

const PUT = (key, value) =>
  new Promise((resolve, reject) => {
    const transaction = db.transaction(["tbaPersistent"], "readwrite");
    const objectStore = transaction.objectStore("tbaPersistent");
    objectStore.put({ value: Base64.encode(JSON.stringify(value)), key });
    transaction.oncomplete = () => {
      resolve();
    };
    transaction.onerror = () => {
      reject();
    };
  });

const GET = (key, defaultValue) =>
  new Promise((resolve, reject) => {
    const transaction = db.transaction(["tbaPersistent"], "readwrite");
    const objectStore = transaction.objectStore("tbaPersistent");
    const objectStoreRequest = key
      ? objectStore.get(key)
      : objectStore.getAll();
    transaction.oncomplete = () => {
      const { result = {} } = objectStoreRequest;
      const { value } = result;

      if (key) {
        const retValue = value
          ? { ...defaultValue, data: JSON.parse(Base64.decode(value)) }
          : defaultValue;
        resolve(retValue);
      } else {
        resolve(result);
      }
    };
    transaction.onerror = () => {
      reject();
    };
  });

const DELETE = (key) =>
  new Promise((resolve, reject) => {
    const transaction = db.transaction(["tbaPersistent"], "readwrite");
    const objectStore = transaction.objectStore("tbaPersistent");
    objectStore.delete(key);
    transaction.oncomplete = () => {
      resolve();
    };
    transaction.onerror = () => {
      reject();
    };
  });

const DELETE_DATABASE = () =>
  new Promise((resolve, reject) => {
    db.close();

    const request = window.indexedDB.deleteDatabase("TBA");
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = () => {
      reject();
    };
    request.onblocked = () => {
      reject();
    };
  });

const initialize = () =>
  new Promise((resolve, reject) => {
    const request = window.indexedDB.open("TBA");
    request.onerror = () => {
      db = null;
      reject();
    };
    request.onsuccess = () => {
      db = request.result;
      resolve();
    };
    request.onupgradeneeded = () => {
      const database = request.result;
      database.createObjectStore("tbaPersistent", { keyPath: "key" });
    };
    request.onblocked = () => {
      reject();
    };
  });

const resetDatabase = async () => {
  await DELETE_DATABASE();
  await initialize();
};

export default {
  initialize,
  resetDatabase,
  deleteDatabase: DELETE_DATABASE,
  put: PUT,
  get: GET,
  delete: DELETE,
};
