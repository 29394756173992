import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const DefaultSpacer = styled.div`
  flex: 1;
`;

const Spacer = React.memo(({ additionalClassName }) => (
  <DefaultSpacer className={additionalClassName} />
));

Spacer.defaultProps = {
  additionalClassName: "",
};
Spacer.propTypes = {
  additionalClassName: PropTypes.string,
};

export default Spacer;
