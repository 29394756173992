import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  MenuState,
  OwnVoiceSoundState,
  PlayRecordedSoundState,
  ReadTextState,
  RecordingState,
  SoundEffectState,
  SoundState,
  TextState,
} from "@constants/Constants";
import {
  bottomMenuAtom,
  layersAtom,
  openedPagesAtom,
  deviceOrientationAtom,
  screenSizeAtom,
  settingsAtom,
  pagesAtom,
  thumbnailsAtom,
  pagesModelsAtom,
  layersClassesAtom,
} from "@stateManagement/Atoms";
import {
  getPagesIndexesToLoad,
  getRecordedSoundFileName,
  shouldLoadTwoPages,
  updateUrlHash,
} from "@utils/Utils";
import HistoryManager from "@utils/HistoryManager";
import AudioRecorder from "@media/recorder/AudioRecorder";
import AudioPlayer from "@media/player/AudioPlayer";
import PlatformUtils from "@utils/PlatformUtils";
import usePrevious from "@hooks/UsePrevious";
import LayersUtils from "@utils/LayersUtils";

const Menu =
  // eslint-disable-next-line no-undef,import/no-dynamic-require
  require(`tba-player-bottom-menu/menus/${ENVIRONMENT_TARGET}/index`).default;

// eslint-disable-next-line import/no-dynamic-require,no-undef,no-unused-expressions
require(`tba-player-bottom-menu/menus/${ENVIRONMENT_TARGET}/BottomMenu.css`)
  .default;

const StyledBottomMenu = styled(Menu)`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BottomMenu = () => {
  const [menuModel, setMenuModel] = useRecoilState(bottomMenuAtom);
  const [layers, setLayers] = useRecoilState(layersAtom);
  const layersClasses = useRecoilValue(layersClassesAtom);
  const [openedPages, setOpenedPages] = useRecoilState(openedPagesAtom);
  const settings = useRecoilValue(settingsAtom);
  const deviceOrientation = useRecoilValue(deviceOrientationAtom);
  const screenSize = useRecoilValue(screenSizeAtom);
  const pages = useRecoilValue(pagesAtom);
  const pageModels = useRecoilValue(pagesModelsAtom);
  const thumbnails = useRecoilValue(thumbnailsAtom);

  const previousOpenedPages = usePrevious(openedPages);

  const updateState = useCallback(
    (key, stateOn, stateOff) => {
      const currentState = menuModel[key];
      const newState = currentState === stateOn ? stateOff : stateOn;
      setMenuModel({ ...menuModel, [key]: newState });
    },
    [menuModel, setMenuModel]
  );

  const onBackButtonClick = useCallback(() => {
    HistoryManager.back();
  }, []);

  const onGameButtonClick = useCallback(() => {
    // TODO: implement it when we'll support games again.
  }, []);

  const onLayerControlButtonClick = useCallback(
    async (newLayers) => {
      await LayersUtils.updateLayers(
        settings.projectId,
        newLayers.addLayersIds,
        newLayers.setLayersIds,
        newLayers.toggleLayersIds,
        newLayers.removeLayersIds,
        {},
        layers,
        layersClasses,
        null,
        setLayers
      );
    },
    [layers, layersClasses, setLayers, settings.projectId]
  );

  const onOpenCloseMenu = useCallback(() => {
    updateState("state", MenuState.OPEN, MenuState.CLOSED);
  }, [updateState]);

  const onOwnVoiceToggle = useCallback(() => {
    const { ownVoiceSoundState } = menuModel;
    const newOwnVoiceSoundState =
      ownVoiceSoundState === OwnVoiceSoundState.ON
        ? OwnVoiceSoundState.OFF
        : OwnVoiceSoundState.ON;
    const soundState =
      newOwnVoiceSoundState === OwnVoiceSoundState.ON
        ? SoundState.ON
        : SoundState.OFF;
    setMenuModel({
      ...menuModel,
      soundState,
      ownVoiceSoundState: newOwnVoiceSoundState,
      readTextState: ReadTextState.OFF,
    });
  }, [menuModel, setMenuModel]);

  const onReadTextToggle = useCallback(() => {
    const { readTextState } = menuModel;
    const newReadTextState =
      readTextState === ReadTextState.ON ? ReadTextState.OFF : ReadTextState.ON;
    const soundState =
      newReadTextState === ReadTextState.ON ? SoundState.ON : SoundState.OFF;
    setMenuModel({
      ...menuModel,
      soundState,
      ownVoiceSoundState: OwnVoiceSoundState.OFF,
      readTextState: newReadTextState,
    });
  }, [menuModel, setMenuModel]);

  const onShowTextToggle = useCallback(() => {
    updateState("textState", TextState.DISPLAYED, TextState.HIDDEN);
  }, [updateState]);

  const onSoundEffectToggle = useCallback(() => {
    updateState("soundEffectState", SoundEffectState.ON, SoundEffectState.OFF);
  }, [updateState]);

  const onSoundToggle = useCallback(() => {
    const { soundState } = menuModel;
    const newSoundState =
      soundState === SoundState.ON ? SoundState.OFF : SoundState.ON;
    const readTextState =
      newSoundState === SoundState.ON ? ReadTextState.ON : ReadTextState.OFF;
    setMenuModel({
      ...menuModel,
      soundState: newSoundState,
      ownVoiceSoundState: OwnVoiceSoundState.OFF,
      readTextState,
    });
  }, [menuModel, setMenuModel]);

  const onStartStopPlayingRecordedSound = useCallback(async () => {
    const fileName = getRecordedSoundFileName(
      openedPages,
      pages,
      pageModels,
      layers,
      settings
    );
    const soundPath = AudioRecorder.getRecordedSounds()[fileName];

    if (menuModel.playRecordedSoundState === PlayRecordedSoundState.ON) {
      await AudioPlayer.stopSound(fileName);
      return;
    }
    await AudioPlayer.stopAllSounds();
    const onEnded = () => {
      setMenuModel({
        ...menuModel,
        playRecordedSoundState: PlayRecordedSoundState.OFF,
      });
    };
    const onPause = () => {
      setMenuModel({
        ...menuModel,
        playRecordedSoundState: PlayRecordedSoundState.OFF,
      });
    };
    const onPlayStart = () => {
      setMenuModel({
        ...menuModel,
        playRecordedSoundState: PlayRecordedSoundState.ON,
      });
    };

    await AudioPlayer.createSound({
      id: fileName,
      src: soundPath,
      onPlayStart,
      onPause,
      onEnded,
    });

    await AudioPlayer.playSound(fileName);
  }, [
    layers,
    menuModel,
    openedPages,
    pageModels,
    pages,
    setMenuModel,
    settings,
  ]);

  const onStartStopRecording = useCallback(async () => {
    const { recordingState } = menuModel;
    if (recordingState === RecordingState.ON) {
      await AudioRecorder.stopAndSaveRecording(
        getRecordedSoundFileName(
          openedPages,
          pages,
          pageModels,
          layers,
          settings
        )
      );
    } else {
      await AudioPlayer.stopAllSounds();
      await AudioRecorder.record();
    }
    updateState("recordingState", RecordingState.ON, RecordingState.OFF);
  }, [
    layers,
    menuModel,
    openedPages,
    pageModels,
    pages,
    settings,
    updateState,
  ]);

  const onThumbnailSelect = useCallback(
    (thumbnailContainerIndex) => {
      const loadTwoPages = shouldLoadTwoPages(
        settings.reflowable,
        settings.orientation,
        deviceOrientation
      );

      const pagesIndexesToLoad = getPagesIndexesToLoad(
        loadTwoPages ? thumbnailContainerIndex * 2 : thumbnailContainerIndex,
        loadTwoPages,
        pages
      );

      updateUrlHash(pagesIndexesToLoad[0]);
      setOpenedPages(pagesIndexesToLoad);
    },
    [
      deviceOrientation,
      pages,
      setOpenedPages,
      settings.orientation,
      settings.reflowable,
    ]
  );

  useEffect(() => {
    const { recordingState } = menuModel;
    if (
      previousOpenedPages !== openedPages &&
      recordingState === RecordingState.ON
    ) {
      AudioRecorder.stopAndSaveRecording(
        getRecordedSoundFileName(
          previousOpenedPages,
          pages,
          pageModels,
          layers,
          settings
        )
      ).then(() => {
        updateState("recordingState", RecordingState.ON, RecordingState.OFF);
      });
    }
  }, [
    layers,
    menuModel,
    openedPages,
    pageModels,
    pages,
    previousOpenedPages,
    settings,
    updateState,
  ]);

  return (
    <>
      <StyledBottomMenu
        activationIconPosition={menuModel.activationIconPosition}
        addBackButtonTab={menuModel.addBackButtonTab}
        addShowHideTextTab={menuModel.addShowHideTextTab}
        addSoundMuteTab={menuModel.addSoundMuteTab}
        deviceOrientation={deviceOrientation}
        gameId={menuModel.gameId}
        hideNavigationOnFirstPage={menuModel.hideNavigationOnFirstPage}
        language={settings.language}
        layers={layers}
        layersControl={menuModel.layersControl}
        icon={menuModel.icon && PlatformUtils.getResourceUrl(menuModel.icon)}
        onBackButtonClick={onBackButtonClick}
        onGameButtonClick={onGameButtonClick}
        onLayerControlButtonClick={onLayerControlButtonClick}
        onOpenCloseMenu={onOpenCloseMenu}
        onOwnVoiceToggle={onOwnVoiceToggle}
        onReadTextToggle={onReadTextToggle}
        onShowTextToggle={onShowTextToggle}
        onSoundEffectToggle={onSoundEffectToggle}
        onSoundToggle={onSoundToggle}
        onStartStopPlayingRecordedSound={onStartStopPlayingRecordedSound}
        onStartStopRecording={onStartStopRecording}
        onThumbnailSelect={onThumbnailSelect}
        openedPages={openedPages}
        ownVoiceSoundState={menuModel.ownVoiceSoundState}
        playRecordedSoundState={menuModel.playRecordedSoundState}
        projectOrientation={settings.orientation}
        readTextState={menuModel.readTextState}
        recordedSoundExist={
          !!AudioRecorder.getRecordedSounds()[
            getRecordedSoundFileName(
              openedPages,
              pages,
              pageModels,
              layers,
              settings
            )
          ]
        }
        recordingState={menuModel.recordingState}
        reflowable={menuModel.reflowable}
        removeRecordingTab={menuModel.removeRecordingTab}
        removeSettingsTab={menuModel.removeSettingsTab}
        removeTocTab={menuModel.removeTocTab}
        screenWidth={screenSize.width}
        showIcon={menuModel.showIcon}
        soundEffectsControlVisible={menuModel.soundEffectsControlVisible}
        soundOnOffControlVisible={menuModel.soundOnOffControlVisible}
        soundSourceControlVisible={menuModel.soundSourceControlVisible}
        state={menuModel.state}
        soundState={menuModel.soundState}
        soundEffectState={menuModel.soundEffectState}
        transparentActivationArea={menuModel.transparentActivationArea}
        textOnOffControlVisible={menuModel.textOnOffControlVisible}
        textState={menuModel.textState}
        thumbnailHeight={menuModel.thumbnailHeight}
        thumbnails={thumbnails}
        thumbnailWidth={menuModel.thumbnailWidth}
        useTocOnly={menuModel.useTocOnly}
      />
    </>
  );
};

export default BottomMenu;
