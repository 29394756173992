export const Language = {
  BOKMAL: 0,
  ENGLISH: 1,
  NYNORSK: 2,
  LULESAMISK: 3,
  NORDSAMISK: 4,
  SORSAMISK: 5,
};

export const MenuState = {
  CLOSED: 0,
  OPEN: 1,
};

export const SoundState = {
  ON: 0,
  OFF: 1,
};

export const TextState = {
  DISPLAYED: 0,
  HIDDEN: 1,
};

export const Orientation = {
  LANDSCAPE: 0,
  PORTRAIT: 1,
};

export const SoundEffectState = {
  ON: 0,
  OFF: 1,
};

export const OwnVoiceSoundState = {
  ON: 0,
  OFF: 1,
};

export const ReadTextState = {
  ON: 0,
  OFF: 1,
};

export const PlayRecordedSoundState = {
  ON: 0,
  OFF: 1,
};

export const RecordingState = {
  ON: 0,
  OFF: 1,
};

export const SideBarWidth = 96;

export const ObjectType = {
  ANIMATION: 0,
  PHOTO: 1,
  CHART: 2,
  DOWNLOAD: 3,
  DROP_DOWN: 4,
  EVENT: 5,
  EXTERNAL_URL: 6,
  DRAWING: 7,
  HOTPOINT: 8,
  IMAGE: 9,
  ANCHOR: 10,
  MUSIC_PLAYER: 11,
  PACKAGE: 12,
  QUIZ_TEST: 13,
  QUIZ_QUESTION: 14,
  RECORDING: 15,
  PAGE: 16,
  SCORE_VISUALIZER: 17,
  SOCIAL_SHARING: 18,
  SOUND: 19,
  TEXT: 20,
  INPUT: 21,
  TIMED_EVENT: 22,
  VIDEO: 23,
};
export const BorderStyle = {
  NO_BORDER: 0,
  SOLID: 1,
  DOTTED: 2,
  DASHED: 3,
  DOUBLE: 4,
  GROOVE: 5,
  RIDGE: 6,
};

export const InitialDisplayState = {
  VISIBLE: 0,
  HIDDEN: 1,
};

export const BorderStyleValues = {
  [BorderStyle.NO_BORDER]: "none",
  [BorderStyle.SOLID]: "1px solid",
  [BorderStyle.DOTTED]: "1px dotted",
  [BorderStyle.DASHED]: "1px dashed",
  [BorderStyle.DOUBLE]: "4px double",
  [BorderStyle.GROOVE]: "6px groove",
  [BorderStyle.RIDGE]: "5px ridge",
};

export const BorderStyleWidth = {
  [BorderStyle.NO_BORDER]: 0,
  [BorderStyle.SOLID]: 1,
  [BorderStyle.DOTTED]: 1,
  [BorderStyle.DASHED]: 1,
  [BorderStyle.DOUBLE]: 4,
  [BorderStyle.GROOVE]: 6,
  [BorderStyle.RIDGE]: 5,
};

export const ActionType = {
  ZOOM_IN_STARTING_POINT: 0,
  ZOOM_OUT_STARTING_POINT: 1,
  TOGGLE_BOTTOM_MENU: 2,
  TOGGLE_VISIBILITY_ONCE: 3,
  TOGGLE_VISIBILITY: 4,
  FADE_VISIBILITY_ONCE: 5,
  FADE_VISIBILITY: 6,
  PULSATE_ONLY_ONCE: 7,
  PULSATE_ONCE: 8,
  PULSATE: 9,
  ANIMATED_ROTATION: 10,
  BACK: 11,
  START_RECORDING_WIZARD: 12,
  START_PLAY_RECORDED_SOUNDS: 13,
  STOP_PLAY_RECORDED_SOUNDS: 14,
  CLOSE: 15,
  UNLOCK_VIA_SS: 16,
  EVALUATE: 17,
  RESET_EVALUATION: 18,
  API_ACTION: 19,
  PRICE_CONTAINER: 20,
  PRINT: 21,
};

export const GoToActionType = {
  PROJECT: 0,
  PAGE: 1,
  EXTERNAL_URL: 2,
};

export const StylesPrefixes = {
  animation: "animation: ",
  animationDelay: "animation-delay: ",
  animationName: "animation-name: ",
  animationDuration: "animation-duration: ",
  animationTiming: "animation-timing-function: ",
  animationIterationCount: "animation-iteration-count: ",
  animationFillMode: "animation-fill-mode: ",
  backgroundColor: "background-color: ",
  border: "border: ",
  gradient: "background: ",
  height: "height: ",
  keyFrames: "@keyframes ",
  left: "left: ",
  opacity: "opacity: ",
  top: "top: ",
  transform: "transform: ",
  transformOrigin: "transform-origin: ",
  transition: "transition: ",
  width: "width: ",
  zIndex: "z-index: ",
};

export const AnimationType = {
  PULSE: 0,
  ANIMATED_ROTATION: 1,
  ZOOM_OUT_PAGE: 3,
  MOVE: 4,
  ZOOM_AND_PAN: 5,
};

export const ActionsThatShouldNotifyPage = [
  ActionType.ZOOM_IN_STARTING_POINT,
  ActionType.ZOOM_OUT_STARTING_POINT,
  ActionType.EVALUATE,
  ActionType.RESET_EVALUATION,
  ActionType.PRICE_CONTAINER,
];

export const LightBoxType = {
  RECORDING: 0,
  SS_UNLOCK: 1,
  EMBEDDED_SCENE: 2,
  DOWNLOAD: 3,
};

export const LayerActionType = {
  ADD: 0,
  SET: 1,
  TOGGLE: 2,
  REMOVE: 3,
  MOVE: 4,
  ZOOM: 5,
};

export const VideoType = {
  YOUTUBE: 0,
  VIMEO: 1,
};

export const LayerLevel = {
  SCENE: 0,
  PROJECT: 1,
};

export const InputStructure = {
  SINGLE_LINE: 0,
  MULTIPLE_LINE: 1,
};

export const LinkType = {
  ACTIVATE: 0,
  ACTIVATE_ONCE: 1,
  ACTIVATE_TOGGLE: 2,
  PULSATE: 3,
  SHOW: 4,
  HIDE: 5,
  TOGGLE_VISIBILITY: 6,
  FADE_IN: 7,
  FADE_OUT: 8,
  MOVE_TO: 9,
  ZOOM_TO: 10,
  PRINT: 11,
  HIGHLIGHT_TEXT: 12,
  DRAGGABLE_TARGET: 13,
  AGGREGATE: 14,
};

export const MoveLinkEndEffectType = {
  MOVE_BACK: 0,
  FADE_OUT: 1,
};

export const SoundUI = {
  NONE: 0,
  ICON: 1,
  COMPACT_PLAYER: 2,
  FULL_PLAYER: 3,
};

export const Karaoke = {
  ENABLED: 0,
  DISABLED: 1,
};

export const SoundAccessRight = {
  CAN_PLAY: 0,
  CAN_NOT_PLAY: 1,
};

export const NotificationTypes = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
};
