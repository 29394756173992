import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import GestureHandler from "./GestureHandler";

const InteractiveComponent = ({
  children,
  className,
  onMouseDown: onMouseDownHandler,
  onMouseUp: onMouseUpHandler,
  onMouseEnter: onMouseEnterHandler,
  onMouseLeave: onMouseLeaveHandler,
  onMouseMove: onMouseMoveHandler,
  onClick: onClickHandler,
  onLongTouch: onLongTouchHandler,
  style,
  tabIndex,
  styleProps,
  useWrapper,
  customStyleProps,
  ...otherProps
}) => {
  const gestureHandler = useRef(
    new GestureHandler({
      onMouseDown: onMouseDownHandler,
      onMouseUp: onMouseUpHandler,
      onMouseEnter: onMouseEnterHandler,
      onMouseLeave: onMouseLeaveHandler,
      onMouseMove: onMouseMoveHandler,
      onClick: onClickHandler,
      onLongTouch: onLongTouchHandler,
    })
  );

  useEffect(() => {
    gestureHandler.current = new GestureHandler({
      onMouseDown: onMouseDownHandler,
      onMouseUp: onMouseUpHandler,
      onMouseEnter: onMouseEnterHandler,
      onMouseLeave: onMouseLeaveHandler,
      onMouseMove: onMouseMoveHandler,
      onClick: onClickHandler,
      onLongTouch: onLongTouchHandler,
    });
  }, [
    onClickHandler,
    onLongTouchHandler,
    onMouseDownHandler,
    onMouseEnterHandler,
    onMouseLeaveHandler,
    onMouseMoveHandler,
    onMouseUpHandler,
  ]);

  const onMouseDown = (e) => {
    e.preventDefault();
    gestureHandler.current.onMouseDown(e);
  };

  const onTouchStart = (e) => {
    e.preventDefault();
    gestureHandler.current.onTouchStart(e);
  };

  const onMouseUp = (e) => {
    e.preventDefault();
    gestureHandler.current.onMouseUp(e);
  };

  const onTouchEnd = (e) => {
    e.preventDefault();
    gestureHandler.current.onTouchEnd(e);
  };

  const onMouseEnter = (e) => {
    gestureHandler.current.onMouseEnter(e);
  };

  const onMouseMove = (e) => {
    gestureHandler.current.onMouseMove(e);
  };

  const onTouchMove = (e) => {
    gestureHandler.current.onTouchMove(e);
  };

  const onMouseLeave = (e) => {
    gestureHandler.current.onMouseLeave(e);
  };

  const onTouchCancel = (e) => {
    gestureHandler.current.onTouchCancel(e);
  };

  const handlers = (() => {
    const ret = {};
    if (
      onMouseDownHandler ||
      onMouseUpHandler ||
      onClickHandler ||
      onLongTouchHandler
    ) {
      ret.onMouseDown = onMouseDown;
      ret.onTouchStart = onTouchStart;
      ret.onMouseUp = onMouseUp;
      ret.onTouchEnd = onTouchEnd;
      ret.onMouseMove = onMouseMove;
      ret.onTouchMove = onTouchMove;
    }

    if (onMouseEnterHandler) {
      ret.onMouseEnter = onMouseEnter;
    }
    if (onMouseLeaveHandler) {
      ret.onMouseLeave = onMouseLeave;
      ret.onTouchCancel = onTouchCancel;
    }
    if (onMouseMoveHandler) {
      ret.onMouseMove = onMouseMove;
      ret.onTouchMove = onTouchMove;
    }
    return ret;
  })();
  const newProps = { ...handlers, ...otherProps, className, style, tabIndex };
  /* eslint-disable react/jsx-props-no-spreading */
  return <div {...newProps}>{children}</div>;
};

InteractiveComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onLongTouch: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMouseUp: PropTypes.func,
  style: PropTypes.shape({}),
  styleProps: PropTypes.shape({}),
  tabIndex: PropTypes.number,
  useWrapper: PropTypes.bool,
  customStyleProps: PropTypes.shape({}),
};
InteractiveComponent.defaultProps = {
  children: null,
  className: "",
  onClick: null,
  onLongTouch: null,
  onMouseEnter: null,
  onMouseDown: null,
  onMouseLeave: null,
  onMouseMove: null,
  onMouseUp: null,
  style: {},
  styleProps: null,
  tabIndex: null,
  useWrapper: true,
  customStyleProps: undefined,
};

export default InteractiveComponent;
