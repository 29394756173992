import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { getCaption } from "@utils/CaptionsUtils";
import useComponentDidMount from "@hooks/UseComponentDidMount";
import { InputStructure } from "@constants/Constants";
import Button from "@atoms/Button";
import { CenterVertically, OpenSans600 } from "@src/GlobalStyles";
import ImgButton from "@atoms/ImgButton";

import Spacer from "@atoms/Spacer";
import ShareUtils from "@utils/ShareUtils";

import DatabaseService from "@services/DatabaseService";
import SubscriberService from "@services/SubscriberService";

import { storageUpdatedAtom } from "@stateManagement/Atoms";

import sendEmailActiveIcon from "./gfx/sendEmailActiveIcon.svg";
import sendEmailInactiveIcon from "./gfx/sendEmailInactiveIcon.svg";

const styles = (reservedAreaHeight) => `
  ${OpenSans600};
  width: 100%;
  height: calc(100% - ${reservedAreaHeight}px);
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  color: var(--dark-grey-blue);
  &:focus {
    outline: none;
  };
  
  ::placeholder {
    color: var(--blue-grey);
    font-size: 16px;
  };
  
`;

const debounce = 300;

const Container = styled.div`
  width: 100%;
  height: 100%;
  zoom: ${({ zoomFactor }) => zoomFactor};
  box-sizing: border-box;
  padding: 20px;
`;

const StyledInput = styled.input`
  ${({ reservedAreaHeight }) => styles(reservedAreaHeight)}
`;
const StyledTextArea = styled.textarea`
  ${({ reservedAreaHeight }) => styles(reservedAreaHeight)}
`;

const MailActionAreaContainer = styled.div`
  display: flex;
  align-items: flex-end;
  bottom: 0;
  width: 100%;
  height: 60px;
`;

const ClearContentButton = styled(Button)`
  ${OpenSans600};
  ${CenterVertically};
  font-size: 16px;
  color: var(--orange);
  height: 48px;
  width: fit-content;
`;

const SendMailButton = styled(ImgButton)`
  ${OpenSans600};
  ${CenterVertically};
  font-size: 16px;
  color: var(--orange);
  height: 48px;
  width: fit-content;
`;

const Input = React.memo(
  ({ emailInfo, id, onObjectLoad, placeholder, structure, zoomFactor }) => {
    const storageUpdate = useRecoilValue(storageUpdatedAtom);

    const [value, setValue] = useState(undefined);

    const debounceTimer = useRef(0);
    const updatedByUser = useRef(false);
    const lastStoredValue = useRef("");

    const loadStoredValue = useCallback(() => {
      DatabaseService.get(id, { data: undefined }).then((inputStoredValue) => {
        const { data } = inputStoredValue;

        setValue((currentValue) =>
          currentValue !== data ? data : currentValue
        );
      });
    }, [id]);

    useComponentDidMount(() => {
      onObjectLoad(id);
      loadStoredValue();
    });

    const onChange = useCallback((e) => {
      updatedByUser.current = true;
      setValue(e.target.value);
    }, []);

    const onClear = useCallback(() => {
      updatedByUser.current = true;
      setValue("");
    }, []);

    const onShare = useCallback(() => {
      ShareUtils.share(emailInfo.to, emailInfo.subject, value);
    }, [emailInfo, value]);

    useEffect(() => {
      if (!updatedByUser.current || value === lastStoredValue.current) return;

      if (debounceTimer.current) {
        window.clearTimeout(debounceTimer.current);
      }

      debounceTimer.current = window.setTimeout(() => {
        DatabaseService.put(id, value);
        SubscriberService.dispatch("storage", {
          actionType: "push",
          data: { key: id, value },
        });
      }, debounce);
    }, [id, value]);

    useEffect(() => {
      if (!storageUpdate) return;
      loadStoredValue();
    }, [loadStoredValue, storageUpdate]);

    return (
      <Container zoomFactor={zoomFactor}>
        {structure === InputStructure.SINGLE_LINE ? (
          <StyledInput
            onChange={onChange}
            placeholder={placeholder}
            reservedAreaHeight={emailInfo ? 60 : 0}
            value={value}
          />
        ) : (
          <StyledTextArea
            onChange={onChange}
            placeholder={placeholder}
            reservedAreaHeight={emailInfo ? 60 : 0}
            value={value}
          />
        )}
        {emailInfo && (
          <MailActionAreaContainer>
            {((value !== undefined && value.length) || null) && (
              <ClearContentButton onClick={onClear}>
                {getCaption("clear")}
              </ClearContentButton>
            )}
            <Spacer />
            <SendMailButton
              disabled={value === undefined || !value.length}
              disabledImgSrc={sendEmailInactiveIcon}
              unselectedStateImgSrc={sendEmailActiveIcon}
              onClick={onShare}
            />
          </MailActionAreaContainer>
        )}
      </Container>
    );
  }
);

Input.defaultProps = {
  emailInfo: null,
  placeholder: "",
  structure: InputStructure.SINGLE_LINE,
};
Input.propTypes = {
  emailInfo: PropTypes.shape({
    subject: PropTypes.string,
    to: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onObjectLoad: PropTypes.func.isRequired,
  structure: PropTypes.number,
  zoomFactor: PropTypes.number.isRequired,
};

export default Input;
