import { useEffect } from "react";

const useComponentDidMount = (handler) => {
  useEffect(() => {
    const clearEffect = handler();
    if (!clearEffect || typeof clearEffect !== "function") return undefined;
    return clearEffect;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useComponentDidMount;
