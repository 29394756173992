import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot, useRecoilValue } from "recoil";

import { GlobalStyle } from "@src/GlobalStyles";
import HttpService from "@services/HttpService";

import Index from "@src/App";
import AppLoader from "@src/AppLoader";
import "@api/Api";
import PostMessageListener from "@api/PostMessageListener";

import DatabaseService from "@services/DatabaseService";
import { settingsAtom } from "@stateManagement/Atoms";

const Root = () => {
  const settings = useRecoilValue(settingsAtom);

  return (
    <>
      <GlobalStyle settings={settings} />
      <AppLoader />
      <Index />
      <PostMessageListener />
    </>
  );
};

DatabaseService.initialize()
  .then(HttpService.fetchMeta)
  .then(({ startFile }) => {
    const url = new URL(window.location);
    url.searchParams.set("shareURL", startFile);
    window.history.replaceState(null, null, decodeURIComponent(url.toString()));

    ReactDOM.render(
      <RecoilRoot>
        <Root />
      </RecoilRoot>,
      document.getElementById("root")
    );
  });
