import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Center, Interactive } from "@src/GlobalStyles";

const StyledButton = styled(Interactive)`
  ${Center};
  cursor: pointer;
`;

const Button = ({ children, className, disabled, onClick }) => {
  const clickHandler = useCallback(
    (e) => {
      if (disabled) return;
      onClick(e);
    },
    [disabled, onClick]
  );

  return (
    <StyledButton className={className} onClick={clickHandler}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  disabled: false,
  className: "",
};

export default Button;
