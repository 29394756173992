import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import useComponentDidMount from "@hooks/UseComponentDidMount";
import Spacer from "@components/Spacer";

import blackColorIcon from "@pageObject/gfx/blackColorIcon.svg";
import orangeColorIcon from "@pageObject/gfx/orangeColorIcon.svg";
import greenColorIcon from "@pageObject/gfx/greenColorIcon.svg";
import blueColorIcon from "@pageObject/gfx/blueColorIcon.svg";
import redColorIcon from "@pageObject/gfx/redColorIcon.svg";
import drawActiveIcon from "@pageObject/gfx/drawActiveIcon.svg";
import drawInactiveIcon from "@pageObject/gfx/drawInactiveIcon.svg";
import eraserActiveIcon from "@pageObject/gfx/eraserActiveIcon.svg";
import eraserInactiveIcon from "@pageObject/gfx/eraserInactiveIcon.svg";
import deleteDrawingIcon from "@pageObject/gfx/deleteDrawingIcon.svg";
import ImgButton from "@atoms/ImgButton";
import { Center, Interactive } from "@src/GlobalStyles";
import { BorderStyle, BorderStyleWidth } from "@constants/Constants";
import Drawer from "./Drawer";

const inactiveTimerPeriod = 5000;

const eraserWidth = 20;
const colors = ["#211d1d", "#fa9b00", "#009935", "#006db7", "#c40023"];
const colorButtonsIcons = [
  blackColorIcon,
  orangeColorIcon,
  greenColorIcon,
  blueColorIcon,
  redColorIcon,
];

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: visible;
`;

const DrawerContainer = styled.div`
  min-width: 100%;
  width: 100%;
  height: 100%;
`;

const ControllersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -68px;
  min-width: 68px;
  width: 68px;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`;

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 68px;
  width: 68px;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`;

const ColorButtonContainer = styled.div`
  ${Center};
`;

const SelectedBorder = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border: 2px solid var(--orange);
  border-radius: 50%;
  box-sizing: border-box;
`;

const StyledDrawerPlaceholder = styled(Interactive)`
  position: absolute;
  ${Center};
  width: 100%;
  height: 100%;
  ${({ pointerEvents }) => `pointer-events: ${pointerEvents}`};
`;

const DrawerPlaceholder = ({ canvasState, drawingMode, onClick }) => (
  <StyledDrawerPlaceholder
    onClick={onClick}
    pointerEvents={drawingMode ? "none" : "all"}
  >
    {canvasState === "clean" && !drawingMode && (
      <img src={drawInactiveIcon} alt="" />
    )}
  </StyledDrawerPlaceholder>
);

DrawerPlaceholder.propTypes = {
  canvasState: PropTypes.string.isRequired,
  drawingMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ColorButton = ({ color, iconSrc, onSelectColor, selected }) => {
  const onClick = useCallback(() => {
    onSelectColor(color);
  }, [color, onSelectColor]);

  return (
    <>
      <ColorButtonContainer>
        {(selected || null) && <SelectedBorder />}
        <ImgButton
          onClick={onClick}
          selected={selected}
          unselectedStateImgSrc={iconSrc}
        />
      </ColorButtonContainer>
    </>
  );
};

ColorButton.propTypes = {
  color: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  onSelectColor: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

const Drawing = React.memo(
  ({
    borderStyle,
    colorChooserAvailable,
    drawingToolAlwaysActive,
    height,
    id,
    lineColor,
    lineWidth,
    onObjectLoad,
    onObjectStartDragging,
    onObjectStopDragging,
    pageActivatedAt,
    width,
  }) => {
    const [drawingMode, setDrawingMode] = useState(false);
    const [drawerSelected, setDrawerSelected] = useState(true);
    const [selectedColor, setSelectedColor] = useState(lineColor || colors[0]);
    const inactiveTimer = useRef(0);
    const [clearCanvasTimeStamp, setClearCanvasTimeStamp] = useState(0);
    const [canvasState, setCanvasState] = useState("clean");

    const setCanvasStatus = useCallback((state) => {
      setCanvasState(state);
    }, []);

    const onEnterDrawingMode = useCallback(() => {
      setDrawingMode(true);
      if (inactiveTimer.current) {
        window.clearTimeout(inactiveTimer.current);
      }
      inactiveTimer.current = setTimeout(() => {
        setDrawingMode(false);
      }, inactiveTimerPeriod);
    }, []);

    const onSelectColor = useCallback((color) => {
      setSelectedColor(color);
    }, []);

    const onSelectDrawer = useCallback(() => {
      setDrawerSelected(true);
    }, []);

    const onSelectEraser = useCallback(() => {
      setDrawerSelected(false);
    }, []);

    const onDeleteContent = useCallback(() => {
      setClearCanvasTimeStamp(new Date().getTime());
    }, []);

    const onDrawStart = useCallback(() => {
      if (inactiveTimer.current) {
        window.clearInterval(inactiveTimer.current);
      }
    }, []);

    const onDrawEnd = useCallback(() => {
      inactiveTimer.current = setTimeout(() => {
        setDrawingMode(false);
      }, inactiveTimerPeriod);
    }, []);

    useComponentDidMount(() => {
      onObjectLoad(id);
    });

    useEffect(() => {
      if (pageActivatedAt) return;
      setDrawingMode(false);
    }, [pageActivatedAt]);

    return (
      <Container>
        {(drawingToolAlwaysActive || drawingMode || null) && (
          <ControllersContainer>
            <ImgButton
              selected={drawerSelected}
              selectedStateImgSrc={drawActiveIcon}
              unselectedStateImgSrc={drawInactiveIcon}
              onClick={onSelectDrawer}
            />
            <ImgButton
              selected={!drawerSelected}
              selectedStateImgSrc={eraserActiveIcon}
              unselectedStateImgSrc={eraserInactiveIcon}
              onClick={onSelectEraser}
            />
            <Spacer />
            <ImgButton
              unselectedStateImgSrc={deleteDrawingIcon}
              onClick={onDeleteContent}
            />
          </ControllersContainer>
        )}
        <DrawerContainer>
          <Drawer
            clearCanvasTimeStamp={clearCanvasTimeStamp}
            drawingId={id}
            height={height - 2 * BorderStyleWidth[borderStyle]}
            lineColor={drawerSelected ? selectedColor : "#f3f3f3"}
            lineWidth={drawerSelected ? lineWidth : eraserWidth}
            onAllowDraw={onObjectStopDragging}
            onDrawStart={onDrawStart}
            onDenyDraw={onObjectStartDragging}
            onDrawEnd={onDrawEnd}
            width={width - 2 * BorderStyleWidth[borderStyle]}
            setCanvasStatus={setCanvasStatus}
          />

          <DrawerPlaceholder
            onClick={onEnterDrawingMode}
            drawingMode={drawingMode}
            canvasState={canvasState}
          />
        </DrawerContainer>
        {colorChooserAvailable &&
          (drawingToolAlwaysActive || drawingMode || null) && (
            <ColorsContainer>
              {colors.map((color, index) => (
                <ColorButton
                  color={colors[index]}
                  iconSrc={colorButtonsIcons[index]}
                  key={color}
                  onSelectColor={onSelectColor}
                  selected={selectedColor === colors[index]}
                />
              ))}
            </ColorsContainer>
          )}
      </Container>
    );
  }
);

Drawing.defaultProps = {
  borderStyle: BorderStyle.NO_BORDER,
  colorChooserAvailable: false,
  lineColor: "#000000",
};
Drawing.propTypes = {
  borderStyle: PropTypes.number,
  colorChooserAvailable: PropTypes.bool,
  drawingToolAlwaysActive: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  lineColor: PropTypes.string,
  lineWidth: PropTypes.number.isRequired,
  onObjectLoad: PropTypes.func.isRequired,
  onObjectStartDragging: PropTypes.func.isRequired,
  onObjectStopDragging: PropTypes.func.isRequired,
  pageActivatedAt: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Drawing;
