import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useComponentDidMount from "@hooks/UseComponentDidMount";
import usePrevious from "@hooks/UsePrevious";
import { LinkType } from "@constants/Constants";

const TimedEvent = React.memo(
  ({
    activatedByLinkProps,
    addLayersIds,
    autoStart,
    delayRangeStart,
    delayRangeEnd,
    id,
    infiniteLooping,
    iterations,
    links,
    loopIntervalEnd,
    loopIntervalStart,
    moveLayersProps,
    onExecuteLinkAction,
    onExecuteLayersAction,
    onObjectLoad,
    pageActivatedAt,
    removeLayersIds,
    setLayersIds,
    toggleLayersIds,
    zoomLayersProps,
  }) => {
    const linksExecuted = useRef(false);
    const executedLinksTimes = useRef(0);
    const previousLinkActivationProps = usePrevious(activatedByLinkProps);
    const previousPageActivationTime = usePrevious(pageActivatedAt);
    const executedIterationsNumber = useRef(0);
    const delayTimer = useRef(0);

    const [objectActivatedAt, setObjectActivatedAt] = useState(undefined);
    const previousObjectActivationTime = usePrevious(objectActivatedAt);

    const executeLinkAction = useCallback(() => {
      // remove links that should be executed only once
      const filteredLinks = links
        .map((link) => {
          const linkProperties = link.properties.filter(
            (properties) =>
              !(
                properties.type === LinkType.ACTIVATE_ONCE &&
                executedLinksTimes.current === 1
              )
          );
          return linkProperties.length > 0
            ? {
                relatedObjectId: link.relatedObjectId,
                properties: linkProperties,
              }
            : null;
        })
        .filter((link) => link);
      if (filteredLinks.length) {
        executedLinksTimes.current += 1;
        onExecuteLinkAction(filteredLinks);
      }
    }, [links, onExecuteLinkAction]);

    const reset = useCallback(() => {
      window.clearTimeout(delayTimer.current);
      setObjectActivatedAt(undefined);
      executedIterationsNumber.current = 0;
      executedLinksTimes.current = 0;
      linksExecuted.current = false;
    }, []);

    const executeLinkEffect = useCallback(
      async (activatedAt, linkProps) => {
        reset();
        const { type } = linkProps;
        switch (type) {
          case LinkType.ACTIVATE: {
            setObjectActivatedAt(activatedAt);
            break;
          }
          case LinkType.ACTIVATE_ONCE: {
            if (!linksExecuted.current) {
              setObjectActivatedAt(activatedAt);
            }
            break;
          }
          default: // do nothing
        }
        linksExecuted.current = true;
      },
      [reset]
    );

    const executeLayersAction = useCallback(async () => {
      if (
        addLayersIds ||
        setLayersIds ||
        toggleLayersIds ||
        removeLayersIds ||
        moveLayersProps ||
        zoomLayersProps
      ) {
        await onExecuteLayersAction({
          addLayersIds,
          setLayersIds,
          toggleLayersIds,
          removeLayersIds,
          moveLayersProps,
          zoomLayersProps,
        });
      }
    }, [
      addLayersIds,
      moveLayersProps,
      onExecuteLayersAction,
      removeLayersIds,
      setLayersIds,
      toggleLayersIds,
      zoomLayersProps,
    ]);

    const getDelay = useCallback((min, max) => {
      if (!min && !max) return 0;
      const validMin = ((min === undefined || min === null) && max) || min;
      const validMax = ((max === undefined || max === null) && validMin) || max;
      if (validMin === validMax) return validMin;
      return Math.round(min + Math.random() * (validMax - validMin));
    }, []);

    useEffect(() => {
      if (!activatedByLinkProps) {
        return;
      }
      const { activatedAt } = activatedByLinkProps;
      if (
        previousLinkActivationProps &&
        previousLinkActivationProps.activatedAt ===
          activatedByLinkProps.activatedAt
      ) {
        return;
      }

      activatedByLinkProps.properties.forEach((link) => {
        executeLinkEffect(activatedAt, link).then();
      });
    }, [activatedByLinkProps, executeLinkEffect, previousLinkActivationProps]);

    useEffect(() => {
      if (pageActivatedAt !== 0 && previousPageActivationTime === 0) {
        if (autoStart) {
          const activationTime = new Date().getTime();
          setObjectActivatedAt(activationTime);
        }
      } else if (pageActivatedAt === 0 && previousPageActivationTime !== 0) {
        reset();
      }
    }, [autoStart, pageActivatedAt, previousPageActivationTime, reset]);

    useEffect(() => {
      if (
        !objectActivatedAt ||
        objectActivatedAt === previousObjectActivationTime
      ) {
        return;
      }

      const delay = executedIterationsNumber.current
        ? getDelay(loopIntervalStart, loopIntervalEnd)
        : getDelay(delayRangeStart, delayRangeEnd);

      delayTimer.current = window.setTimeout(() => {
        if (links) {
          executeLinkAction();
        }
        executeLayersAction().then(/* do nothing */);
        executedIterationsNumber.current += 1;
        if (infiniteLooping || executedIterationsNumber.current < iterations) {
          setObjectActivatedAt(new Date().getTime());
        }
      }, delay);
    }, [
      delayRangeEnd,
      delayRangeStart,
      executeLayersAction,
      executeLinkAction,
      getDelay,
      id,
      infiniteLooping,
      iterations,
      links,
      loopIntervalEnd,
      loopIntervalStart,
      objectActivatedAt,
      previousObjectActivationTime,
    ]);

    useComponentDidMount(() => {
      if (id === "SceneObject_A961A8AC_491B_4884_B7F4_30241E6F31BF") {
        // alert(id);
      }
      onObjectLoad(id);
      return () => {
        window.clearTimeout(delayTimer.current);
      };
    });
    return null;
  }
);

TimedEvent.defaultProps = {
  activatedByLinkProps: undefined,
  addLayersIds: null,
  autoStart: false,
  infiniteLooping: false,
  links: null,
  loopIntervalEnd: 0,
  loopIntervalStart: 0,
  moveLayersProps: null,
  pageActivatedAt: undefined,
  removeLayersIds: null,
  setLayersIds: null,
  toggleLayersIds: null,
  zoomLayersProps: null,
};

TimedEvent.propTypes = {
  activatedByLinkProps: PropTypes.shape({
    activatedAt: PropTypes.number.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
      })
    ),
  }),
  addLayersIds: PropTypes.arrayOf(PropTypes.number),
  autoStart: PropTypes.bool,
  delayRangeStart: PropTypes.number.isRequired,
  delayRangeEnd: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  infiniteLooping: PropTypes.bool,
  iterations: PropTypes.number.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      relatedObjectId: PropTypes.string.isRequired,
      properties: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.number.isRequired,
          duration: PropTypes.number,
          endEffect: PropTypes.shape({
            type: PropTypes.number.isRequired,
            delay: PropTypes.number,
            bezier: PropTypes.string,
          }),
          bezier: PropTypes.string,
          autoStart: PropTypes.bool,
          abortOnTap: PropTypes.bool,
          snap: PropTypes.bool,
          tolerance: PropTypes.number,
        })
      ),
    })
  ),
  loopIntervalEnd: PropTypes.number,
  loopIntervalStart: PropTypes.number,
  moveLayersProps: PropTypes.shape({
    layerId: PropTypes.number,
    duration: PropTypes.number,
    xOffset: PropTypes.number,
    yOffset: PropTypes.number,
    bezier: PropTypes.string,
  }),
  onExecuteLinkAction: PropTypes.func.isRequired,
  onExecuteLayersAction: PropTypes.func.isRequired,
  onObjectLoad: PropTypes.func.isRequired,
  pageActivatedAt: PropTypes.number,
  removeLayersIds: PropTypes.arrayOf(PropTypes.number),
  setLayersIds: PropTypes.arrayOf(PropTypes.number),
  toggleLayersIds: PropTypes.arrayOf(PropTypes.number),

  zoomLayersProps: PropTypes.shape({
    layerId: PropTypes.number,
    duration: PropTypes.number,
    delay: PropTypes.number,
    abortOnTap: PropTypes.bool,
    zoomFactor: PropTypes.number,
    bezier: PropTypes.string,
  }),
};

export default TimedEvent;
