import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Page from "./Page";

export const StyledPageContainer = styled.div.attrs(
  ({ height, left, width }) => ({
    style: {
      height,
      left,
      width,
    },
  })
)`
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
`;

const getPageContent = (
  fullScreen,
  layers,
  onGoToPage,
  onObjectStartDragging,
  onObjectStopDragging,
  openedPage,
  pageId,
  pageIndex,
  pageModel,
  projectHeight,
  projectId,
  projectWidth,
  zoomFactor
) => {
  if (pageIndex === undefined) return null;

  return (
    <Page
      active={pageIndex === openedPage}
      fullScreen={fullScreen}
      id={pageId}
      layers={layers}
      model={pageModel}
      onGoToPage={onGoToPage}
      onObjectStartDragging={onObjectStartDragging}
      onObjectStopDragging={onObjectStopDragging}
      projectHeight={projectHeight}
      projectId={projectId}
      projectWidth={projectWidth}
      zoomFactor={zoomFactor}
    />
  );
};

const ViewportPageContainer = React.memo(
  ({
    height,
    layers,
    left,
    leftOpenedPage,
    leftPageId,
    leftPageIndex,
    leftPageModel,
    pageIsFullScreen,
    onGoToPage,
    onObjectStartDragging,
    onObjectStopDragging,
    projectHeight,
    projectId,
    projectWidth,
    rightOpenedPage,
    rightPageIndex,
    rightPageId,
    rightPageModel,
    width,
    zoomFactor,
  }) => (
    <StyledPageContainer height={height} left={left} width={width}>
      {getPageContent(
        pageIsFullScreen,
        layers,
        onGoToPage,
        onObjectStartDragging,
        onObjectStopDragging,
        leftOpenedPage,
        leftPageId,
        leftPageIndex,
        leftPageModel,
        projectHeight,
        projectId,
        projectWidth,
        zoomFactor
      )}
      {getPageContent(
        pageIsFullScreen,
        layers,
        onGoToPage,
        onObjectStartDragging,
        onObjectStopDragging,
        rightOpenedPage,
        rightPageId,
        rightPageIndex,
        rightPageModel,
        projectHeight,
        projectId,
        projectWidth,
        zoomFactor
      )}
    </StyledPageContainer>
  ),
  () => false
);

ViewportPageContainer.defaultProps = {
  leftOpenedPage: undefined,
  leftPageId: undefined,
  leftPageIndex: undefined,
  leftPageModel: undefined,
  rightOpenedPage: undefined,
  rightPageId: undefined,
  rightPageIndex: undefined,
  rightPageModel: undefined,
};

ViewportPageContainer.propTypes = {
  height: PropTypes.number.isRequired,
  layers: PropTypes.shape({}).isRequired,
  left: PropTypes.number.isRequired,
  leftOpenedPage: PropTypes.number,
  leftPageId: PropTypes.number,
  leftPageIndex: PropTypes.number,
  leftPageModel: PropTypes.shape({}),
  onGoToPage: PropTypes.func.isRequired,
  onObjectStartDragging: PropTypes.func.isRequired,
  onObjectStopDragging: PropTypes.func.isRequired,
  pageIsFullScreen: PropTypes.bool.isRequired,
  projectHeight: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  projectWidth: PropTypes.number.isRequired,
  rightOpenedPage: PropTypes.number,
  rightPageId: PropTypes.number,
  rightPageIndex: PropTypes.number,
  rightPageModel: PropTypes.shape({}),
  width: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number.isRequired,
};

export default ViewportPageContainer;
