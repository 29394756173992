import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  ActionType,
  BorderStyle,
  Karaoke,
  LayerLevel,
  LinkType,
  ObjectType,
  OwnVoiceSoundState,
  PlayRecordedSoundState,
  ReadTextState,
  SoundAccessRight,
  SoundEffectState,
  SoundState,
  StylesPrefixes,
  TextState,
} from "@constants/Constants";
import ActionsUtils from "@utils/ActionsUtils";
import LayersUtils from "@utils/LayersUtils";
import {
  bottomMenuAtom,
  layersAtom,
  layersClassesAtom,
  settingsAtom,
} from "@stateManagement/Atoms";
import { getRecordedSoundFileName, app } from "@utils/Utils";
import PageObject from "./objects/PageObject";
import AutoStartSoundsOverlay from "./AutoStartSoundsOverlay";

const PageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  ${({ styleProps }) =>
    Object.keys(styleProps)
      .map((key) => `${StylesPrefixes[key]}${styleProps[key]};`)
      .join("\n")}
`;
const PageContentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

const hasMoveLinks = (links) =>
  (links &&
    links.reduce(
      (ret, { properties }) =>
        ret || properties.find(({ type }) => type === LinkType.MOVE_TO),
      false
    ) &&
    true) ||
  false;

const getObjectsIdsThatNeedToBeInitiallyLoaded = (
  pageObjects,
  layers,
  appSettings
) => {
  const displayTexts = appSettings.textState === TextState.DISPLAYED;

  const moveLinksTargetIds = pageObjects
    .map((object) => (hasMoveLinks(object.links) ? object.id : null))
    .filter((id) => id);

  return pageObjects
    .map(({ id, layerId, type }) =>
      (displayTexts || type !== ObjectType.TEXT) &&
      layers[layerId].active &&
      !moveLinksTargetIds.includes(id)
        ? id
        : undefined
    )
    .filter((objectId) => objectId);
};

const getCurrentPageLayers = (pageObjects, layers) => {
  const currentPageLayers = {};
  pageObjects
    .map(({ layerId }) => layerId)
    .filter((layerId, index, self) => self.indexOf(layerId) === index)
    .forEach((layerId) => {
      if (layers[layerId].level === LayerLevel.PROJECT) return;
      currentPageLayers[layerId] = { ...layers[layerId] };
    });
  return currentPageLayers;
};

const getKaraokeSyncPoints = (objects) =>
  objects.find(({ karaokeConfig }) => karaokeConfig).karaokeConfig.syncPoints;

const getKaraokeTextBoxesOrder = (objects, pageWidth) => {
  const karaokeSound = objects.find(({ karaokeConfig }) => karaokeConfig);
  const { karaokeConfig } = karaokeSound;
  const { syncOrder } = karaokeConfig;

  const sortedTextBoxesFromTopToBottom = objects
    .filter(({ type }) => type === ObjectType.TEXT)
    .sort((o1, o2) => o1.top - o2.top);

  if (syncOrder !== "left-right-top-bottom") {
    return sortedTextBoxesFromTopToBottom.map(({ id }) => id);
  }

  const leftSideTexts = [];
  const rightSideTexts = [];
  const middle = pageWidth / 2;

  sortedTextBoxesFromTopToBottom.forEach((textBox) => {
    if (textBox.left < middle) {
      leftSideTexts.push(textBox);
    } else {
      rightSideTexts.push(textBox);
    }
  });

  return [...leftSideTexts, ...rightSideTexts].map(({ id }) => id);
};

const shouldLoadObject = (
  currentPageLayers,
  layers,
  objectModel,
  appSettings
) => {
  const shouldLoad = (
    currentPageLayers[objectModel.layerId] || layers[objectModel.layerId]
  ).active;

  if (!shouldLoad) return false;

  return (
    appSettings.textState === TextState.DISPLAYED ||
    objectModel.type !== ObjectType.TEXT
  );
};

const shouldLoadOverlay = (autostartSoundsIds) =>
  !app.isNative &&
  (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
  autostartSoundsIds.length;

const getAutoStartSoundsIds = (loadedObjectsIds, objects) =>
  objects
    .filter(
      (object) =>
        loadedObjectsIds.includes(object.id) &&
        object.type === ObjectType.SOUND &&
        object.autoStart
    )
    .map(({ id }) => id);

const getSoundAccessRights = (objectModel, menuSettings) => {
  if (objectModel.type !== ObjectType.SOUND) {
    return {
      defaultSoundAccessRight: SoundAccessRight.CAN_PLAY,
      recordedSoundAccessRight: SoundAccessRight.CAN_PLAY,
    };
  }

  if (menuSettings.soundState === SoundState.OFF) {
    return {
      defaultSoundAccessRight: SoundAccessRight.CAN_NOT_PLAY,
      recordedSoundAccessRight: SoundAccessRight.CAN_NOT_PLAY,
    };
  }

  if (objectModel.syncPoints) {
    return {
      defaultSoundAccessRight:
        !menuSettings.soundSourceControlVisible ||
        menuSettings.ownVoiceSoundState === OwnVoiceSoundState.OFF
          ? SoundAccessRight.CAN_PLAY
          : SoundAccessRight.CAN_NOT_PLAY,
      recordedSoundAccessRight:
        menuSettings.playRecordedSoundState === PlayRecordedSoundState.ON ||
        (menuSettings.soundSourceControlVisible &&
        menuSettings.ownVoiceSoundState === OwnVoiceSoundState.ON
          ? SoundAccessRight.CAN_PLAY
          : SoundAccessRight.CAN_NOT_PLAY),
    };
  }

  if (menuSettings.soundSourceControlVisible) {
    return {
      defaultSoundAccessRight:
        menuSettings.readTextState === ReadTextState.ON
          ? SoundAccessRight.CAN_PLAY
          : SoundAccessRight.CAN_NOT_PLAY,
      recordedSoundAccessRight:
        menuSettings.playRecordedSoundState === PlayRecordedSoundState.ON ||
        (menuSettings.ownVoiceSoundState === OwnVoiceSoundState.ON
          ? SoundAccessRight.CAN_PLAY
          : SoundAccessRight.CAN_NOT_PLAY),
    };
  }
  if (menuSettings.soundEffectsControlVisible) {
    return {
      defaultSoundAccessRight:
        menuSettings.soundEffectState === SoundEffectState.ON
          ? SoundAccessRight.CAN_PLAY
          : SoundAccessRight.CAN_NOT_PLAY,
      recordedSoundAccessRight:
        menuSettings.playRecordedSoundState === PlayRecordedSoundState.ON ||
        (menuSettings.soundEffectState === SoundEffectState.ON
          ? SoundAccessRight.CAN_PLAY
          : SoundAccessRight.CAN_NOT_PLAY),
    };
  }
  return {
    defaultSoundAccessRight: SoundAccessRight.CAN_PLAY,
    recordedSoundAccessRight: SoundAccessRight.CAN_NOT_PLAY,
  };
};

const Page = React.memo(
  ({
    active,
    fullScreen,
    id,
    layers,
    model,
    onGoToPage,
    onObjectStartDragging,
    onObjectStopDragging,
    projectHeight,
    projectId,
    projectWidth,
    zoomFactor,
  }) => {
    const setLayers = useSetRecoilState(layersAtom);
    const layersClasses = useRecoilValue(layersClassesAtom);
    const menuSettings = useRecoilValue(bottomMenuAtom);
    const appSettings = useRecoilValue(settingsAtom);

    const objectsIdsInPageThatNeedToBeInitiallyLoaded = useRef(
      getObjectsIdsThatNeedToBeInitiallyLoaded(
        model.objects,
        layers,
        menuSettings
      )
    );

    const autostartSoundsIds = useRef(
      getAutoStartSoundsIds(
        objectsIdsInPageThatNeedToBeInitiallyLoaded.current,
        model.objects
      )
    );

    const showSoundOverlay = useRef(
      shouldLoadOverlay(autostartSoundsIds.current)
    );

    const recordedSoundId = useRef(
      getRecordedSoundFileName([0], [id], { [id]: model }, layers, appSettings)
    );

    const [activatedByLinkProps, setActivatedByLinkProps] = useState({});

    const [styleProps, setStyleProps] = useState({
      width: fullScreen ? "100%" : "50%",
    });

    const [currentPageLayers, setCurrentPageLayers] = useState(
      getCurrentPageLayers(model.objects, layers)
    );

    const pageRef = useRef();
    const loadedObjects = useRef([]);

    const [pageActivatedAt, setPageActivatedAt] = useState(0);
    const pageLoaded = useRef(false);

    const karaokeSoundSyncPoints = useRef(
      (model.karaoke === Karaoke.ENABLED || null) &&
        getKaraokeSyncPoints(model.objects)
    );

    const karaokeTextBoxesOrder = useRef(
      (model.karaoke === Karaoke.ENABLED || null) &&
        getKaraokeTextBoxesOrder(model.objects, projectWidth)
    );

    const karaokeTextsWordsNumber = useRef(
      (model.karaoke === Karaoke.ENABLED || null) &&
        karaokeTextBoxesOrder.current.reduce(
          (ret, textBoxId) => ({ ...ret, [textBoxId]: 0 }),
          {}
        )
    );
    const karaokeTextsSyncOffset = useRef(
      (model.karaoke === Karaoke.ENABLED || null) &&
        karaokeTextBoxesOrder.current.reduce(
          (ret, textBoxId) => ({ ...ret, [textBoxId]: 0 }),
          {}
        )
    );

    const [karaokeSyncIndex, setKaraokeSyncIndex] = useState(-1);

    const onKaraokeSoundTimeUpdate = useCallback((syncIndex) => {
      setKaraokeSyncIndex(syncIndex);
    }, []);

    const [
      translatedObjectsByLayersAction,
      setTranslatedObjectsByLayersAction,
    ] = useState({});

    const resetPage = useCallback(() => {
      setPageActivatedAt(0);
      setKaraokeSyncIndex(-1);
      setStyleProps({
        width: fullScreen ? "100%" : "50%",
      });
      setCurrentPageLayers(getCurrentPageLayers(model.objects, layers));
      showSoundOverlay.current = shouldLoadOverlay(
        objectsIdsInPageThatNeedToBeInitiallyLoaded.current,
        model.objects
      );
    }, [fullScreen, layers, model.objects]);

    const onKaraokeTextLoaded = useCallback((textBoxId, numberOfWords) => {
      karaokeTextsWordsNumber.current[textBoxId] = numberOfWords;
    }, []);

    const updateKaraokeTextOffsets = useCallback(() => {
      let offset = 0;
      karaokeTextsSyncOffset.current =
        (model.karaoke === Karaoke.ENABLED || null) &&
        karaokeTextBoxesOrder.current.reduce((ret, textBoxId) => {
          const returnObject = { ...ret, [textBoxId]: offset };
          offset += karaokeTextsWordsNumber.current[textBoxId];
          return returnObject;
        }, {});
    }, [model.karaoke]);

    const onSoundOverlayClicked = useCallback(() => {
      showSoundOverlay.current = false;
      pageLoaded.current = true;
      if (active) {
        setPageActivatedAt(new Date().getTime());
      }
    }, [active]);

    const onObjectLoad = useCallback(
      (objectId) => {
        if (!loadedObjects.current.includes(objectId)) {
          loadedObjects.current.push(objectId);
          if (
            loadedObjects.current.length ===
            objectsIdsInPageThatNeedToBeInitiallyLoaded.current.length
          ) {
            updateKaraokeTextOffsets();
            if (showSoundOverlay.current) {
              return;
            }
            pageLoaded.current = true;
            if (active) {
              setPageActivatedAt(new Date().getTime());
            }
          }
        }
      },
      [active, updateKaraokeTextOffsets]
    );

    const executeZoomOutAction = useCallback(
      (actionProps) => {
        const { userInteracted } = actionProps;
        let animationStartZoomFactor;
        if (userInteracted) {
          const boundClientRect = pageRef.current.getBoundingClientRect();
          const originalPageWidth = zoomFactor * projectWidth;
          animationStartZoomFactor = boundClientRect.width / originalPageWidth;
        }
        return ActionsUtils.execute(
          { action: { type: ActionType.ZOOM_OUT_STARTING_POINT } },
          {
            animationStartZoomFactor,
            zoomFactor,
            ...actionProps,
            width: styleProps.width,
            pageAbsoluteWidth: projectWidth,
            pageAbsoluteHeight: projectHeight,
            userInteracted,
            setStyleProps,
          }
        );
      },
      [projectHeight, projectWidth, styleProps.width, zoomFactor]
    );
    const onExecuteLinkAction = useCallback((links, additionalProps) => {
      const newActivatedObjectsByLinks = {};
      const activatedAt = new Date().getTime();

      links.forEach(({ relatedObjectId, properties }) => {
        newActivatedObjectsByLinks[relatedObjectId] = {
          activatedAt,
          properties: properties.map((p) => ({ ...p, ...additionalProps })),
        };
      });

      setActivatedByLinkProps((currentValue) => ({
        ...currentValue,
        ...newActivatedObjectsByLinks,
      }));
    }, []);

    const onDragObjectEnded = useCallback(() => {}, []);

    const onExecuteLayersAction = useCallback(
      async ({
        addLayersIds,
        setLayersIds,
        toggleLayersIds,
        removeLayersIds,
        moveLayersProps,
        zoomLayersProps,
      }) => {
        if (
          addLayersIds ||
          setLayersIds ||
          toggleLayersIds ||
          removeLayersIds
        ) {
          await LayersUtils.updateLayers(
            projectId,
            addLayersIds,
            setLayersIds,
            toggleLayersIds,
            removeLayersIds,
            currentPageLayers,
            layers,
            layersClasses,
            setCurrentPageLayers,
            setLayers
          );
        }

        if (moveLayersProps) {
          const translatedObjects = {};

          moveLayersProps.forEach((moveProps) => {
            const { layerId: translatedLayerId } = moveProps;
            model.objects.forEach(({ objectId, layerId }) => {
              if (layerId === translatedLayerId) {
                translatedObjects[objectId] = moveProps;
              }
            });
          });

          setTranslatedObjectsByLayersAction((currentValue) => ({
            ...currentValue,
            ...translatedObjects,
          }));
        }

        if (zoomLayersProps) {
          let layerRectLeft = Number.MAX_SAFE_INTEGER;
          let layerRectTop = Number.MAX_SAFE_INTEGER;
          let layerRectRight = Number.MIN_SAFE_INTEGER;
          let layerRectBottom = Number.MIN_SAFE_INTEGER;

          zoomLayersProps.forEach((zoomProps) => {
            const { layerId: zoomLayerId } = zoomProps;
            model.objects.forEach(({ layerId, left, top, width, height }) => {
              const objectRight = left + width;
              const objectBottom = top + height;
              if (layerId !== zoomLayerId) return;
              if (left < layerRectLeft) {
                layerRectLeft = left;
              }
              if (top < layerRectTop) {
                layerRectTop = top;
              }
              if (objectRight > layerRectRight) {
                layerRectRight = objectRight;
              }
              if (objectBottom > layerRectBottom) {
                layerRectBottom = objectBottom;
              }
            });
            ActionsUtils.execute(
              { action: { type: ActionType.ZOOM_IN_STARTING_POINT } },
              {
                borderStyle: BorderStyle.NO_BORDER,
                zoomFactor,
                objectLeft: layerRectLeft,
                objectTop: layerRectTop,
                objectWidth: layerRectRight - layerRectLeft,
                objectHeight: layerRectBottom - layerRectTop,
                width: styleProps.width,
                pageAbsoluteWidth: projectWidth,
                pageAbsoluteHeight: projectHeight,
                setStyleProps,
                duration: zoomProps.duration,
                bezier: zoomProps.bezier,
                delay: zoomProps.delay,
              }
            );
          });
        }
      },

      [
        currentPageLayers,
        layers,
        layersClasses,
        model.objects,
        projectHeight,
        projectId,
        projectWidth,
        setLayers,
        styleProps.width,
        zoomFactor,
      ]
    );

    const onActionToBeExecutedOnPage = useCallback(
      (actionProps) => {
        const { type } = actionProps;

        switch (type) {
          case ActionType.ZOOM_IN_STARTING_POINT: {
            if (actionProps.executedActionsTimes === 2) return false;
            if (actionProps.executedActionsTimes === 1) {
              if (!actionProps.abortOnTap) return false;
              return executeZoomOutAction({
                ...actionProps,
                userInteracted: true,
              });
            }
            return ActionsUtils.execute(
              { action: actionProps },
              {
                zoomFactor,
                ...actionProps,
                width: styleProps.width,
                pageAbsoluteWidth: projectWidth,
                pageAbsoluteHeight: projectHeight,
                setStyleProps,
              }
            );
          }
          case ActionType.ZOOM_OUT_STARTING_POINT: {
            if (actionProps.executedActionsTimes !== 0) return false;
            return executeZoomOutAction(actionProps);
          }

          default:
            return false;
        }
      },
      [
        executeZoomOutAction,
        projectHeight,
        projectWidth,
        styleProps.width,
        zoomFactor,
      ]
    );

    useEffect(() => {
      objectsIdsInPageThatNeedToBeInitiallyLoaded.current =
        getObjectsIdsThatNeedToBeInitiallyLoaded(
          model.objects,
          layers,
          menuSettings
        );

      autostartSoundsIds.current = getAutoStartSoundsIds(
        objectsIdsInPageThatNeedToBeInitiallyLoaded.current,
        model.objects
      );
      resetPage();
    }, [layers, model.objects, resetPage]);

    useEffect(() => {
      if (
        active &&
        pageLoaded.current &&
        pageActivatedAt === 0 &&
        !showSoundOverlay.current
      ) {
        setPageActivatedAt(new Date().getTime());
      }
      if (!active && pageActivatedAt !== 0) {
        resetPage();
      }
    }, [active, id, pageActivatedAt, resetPage]);
    return (
      (model || null) && (
        <PageContainer styleProps={styleProps} key={model.pageId} ref={pageRef}>
          <PageContentContainer>
            {model.objects.map((objectModel) => {
              const { defaultSoundAccessRight, recordedSoundAccessRight } =
                getSoundAccessRights(objectModel, menuSettings);
              return (
                <PageObject
                  activatedByLinkProps={activatedByLinkProps[objectModel.id]}
                  defaultSoundAccessRight={defaultSoundAccessRight}
                  layers={layers}
                  karaokeSyncIndex={
                    karaokeTextsSyncOffset.current
                      ? karaokeSyncIndex -
                        (karaokeTextsSyncOffset.current[objectModel.id] || 0)
                      : -1
                  }
                  karaokeSoundSyncPoints={karaokeSoundSyncPoints.current}
                  key={`${objectModel.id}_container`}
                  objectModel={objectModel}
                  onActionToBeExecutedOnPage={onActionToBeExecutedOnPage}
                  onDragObjectEnded={onDragObjectEnded}
                  onExecuteLinkAction={onExecuteLinkAction}
                  onExecuteLayersAction={onExecuteLayersAction}
                  onGoToPage={onGoToPage}
                  onKaraokeSoundTimeUpdate={onKaraokeSoundTimeUpdate}
                  onKaraokeTextLoaded={onKaraokeTextLoaded}
                  onObjectStartDragging={onObjectStartDragging}
                  onObjectStopDragging={onObjectStopDragging}
                  onObjectLoad={onObjectLoad}
                  pageActivatedAt={pageActivatedAt}
                  projectHeight={projectHeight}
                  projectWidth={projectWidth}
                  recordedSoundAccessRight={recordedSoundAccessRight}
                  recordedSoundId={recordedSoundId.current}
                  shouldLoad={shouldLoadObject(
                    currentPageLayers,
                    layers,
                    objectModel,
                    menuSettings
                  )}
                  translateProps={
                    translatedObjectsByLayersAction[objectModel.id]
                  }
                  zoomFactor={zoomFactor}
                />
              );
            })}
          </PageContentContainer>
          {showSoundOverlay.current && (
            <AutoStartSoundsOverlay
              onClick={onSoundOverlayClicked}
              soundIds={autostartSoundsIds.current}
              objects={model.objects}
            />
          )}
        </PageContainer>
      )
    );
  }
);

Page.defaultProps = {
  model: null,
};

Page.propTypes = {
  active: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  layers: PropTypes.shape({}).isRequired,
  model: PropTypes.shape({
    karaoke: PropTypes.number,
    objects: PropTypes.arrayOf(PropTypes.shape({})),
    pageId: PropTypes.number.isRequired,
  }),
  onGoToPage: PropTypes.func.isRequired,
  onObjectStartDragging: PropTypes.func.isRequired,
  onObjectStopDragging: PropTypes.func.isRequired,
  projectHeight: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  projectWidth: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number.isRequired,
};

export default Page;
