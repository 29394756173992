import axios from "axios";

const axiosFetch = async (url) => {
  const response = await axios.get(url);
  return response.data;
};

const fetchHtmlPackage = async (url) => axiosFetch(url);

const fetchModel = async (url) => axiosFetch(url);

const fetchMeta = async () => axiosFetch("projectMeta.json");

const fetch = async (url) => axiosFetch(url);

export default {
  fetchHtmlPackage,
  fetchModel,
  fetchMeta,
  fetch,
};
