import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import Grid from "scroll-view";
import {
  deviceOrientationAtom,
  openedPagesAtom,
  pagesAtom,
  settingsAtom,
  projectSizeAtom,
  pagesModelsAtom,
  projectZoomFactorAtom,
  layersAtom,
} from "@stateManagement/Atoms";
import usePrevious from "@hooks/UsePrevious";
import {
  getPagesIndexesToLoad,
  shouldLoadTwoPages,
  updateUrlHash,
} from "@utils/Utils";

import PageContainer from "./pages/PageContainer";

const StyledContent = styled.div`
  position: relative;
  ${({ width, height }) => `width: ${width}px;
  height: ${height}px;`}
  background-color:#ffffff;
  overflow: hidden;
  z-index: 0;
`;

const PagesContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`;

const { abs } = Math;

const Content = () => {
  const [openedPages, setOpenedPages] = useRecoilState(openedPagesAtom);
  const projectZoomFactor = useRecoilValue(projectZoomFactorAtom);
  const deviceOrientation = useRecoilValue(deviceOrientationAtom);
  const { projectWidth, projectHeight } = useRecoilValue(projectSizeAtom);
  const layers = useRecoilValue(layersAtom);

  const previousOrientation = usePrevious(deviceOrientation);

  const settings = useRecoilValue(settingsAtom);

  const pages = useRecoilValue(pagesAtom);
  const pagesModels = useRecoilValue(pagesModelsAtom);

  const previousWidth = usePrevious(projectWidth);
  const previousOpenedPages = usePrevious(openedPages);

  const scrollValue = useRef(0);
  const nextScrollValue = useRef(0);
  const isScrolling = useRef(false);

  const scrollLeftStartValue = useRef(0);

  const publicFunctions = useRef({});
  const [, setReRender] = useState({});
  const [userInteractionEnabled, setUserInteractionEnabled] = useState(true);
  const scrolledByUserInteraction = useRef(true);

  const [loadTwoPages, setLoadTwoPages] = useState(
    shouldLoadTwoPages(
      settings.reflowable,
      settings.orientation,
      deviceOrientation
    )
  );

  const [snapPoints, setSnapPoints] = useState({
    xAxis: new Array(loadTwoPages ? Math.ceil(pages.length / 2) : pages.length)
      .fill(1)
      .map((el, index) => -index * projectWidth),
  });

  const onScrollStart = useCallback((options) => {
    scrolledByUserInteraction.current = options.userInteraction;
    isScrolling.current = true;
    scrollLeftStartValue.current = scrollValue.current;
  }, []);

  const onScroll = useCallback(({ scrollLeft }) => {
    scrollValue.current = scrollLeft;
  }, []);

  const onScrollEnd = useCallback(() => {
    isScrolling.current = false;
    const columnIndex = Math.floor(scrollValue.current / projectWidth);
    const startPage = loadTwoPages ? columnIndex * 2 : columnIndex;
    const newPagesIndexesToLoad = getPagesIndexesToLoad(
      startPage,
      loadTwoPages,
      pages
    );

    const shouldUpdate = newPagesIndexesToLoad.reduce(
      (ret, pageIndex) => ret || !openedPages.includes(pageIndex),
      false
    );
    if (shouldUpdate) {
      setOpenedPages(newPagesIndexesToLoad);
      updateUrlHash(startPage);
    } else {
      setReRender({});
    }
  }, [loadTwoPages, openedPages, pages, projectWidth, setOpenedPages]);

  const exposePublicFunctions = useCallback((exposedFunctions) => {
    publicFunctions.current = exposedFunctions;
  }, []);

  useEffect(() => {
    if (
      projectWidth === previousWidth ||
      isScrolling.current ||
      !projectWidth ||
      !previousWidth
    ) {
      return;
    }

    const scaleFactor = projectWidth / previousWidth;
    publicFunctions.current.setScrollLeft(-scrollValue.current * scaleFactor, {
      animation: false,
    });
  }, [previousWidth, projectWidth]);

  useEffect(() => {
    if (
      !previousOpenedPages ||
      (isScrolling.current && scrolledByUserInteraction.current)
    ) {
      return;
    }

    const leftPage = openedPages[0];
    const newScrollValue =
      -projectWidth * (loadTwoPages ? Math.floor(leftPage / 2) : leftPage);

    if (
      abs(newScrollValue) === abs(scrollValue.current) ||
      abs(newScrollValue) === abs(nextScrollValue.current)
    ) {
      nextScrollValue.current = newScrollValue;
      return;
    }

    nextScrollValue.current = newScrollValue;
    const offset = loadTwoPages ? 2 : 1;
    const pagesPassed = Math.abs(leftPage - previousOpenedPages[0]);

    const animation = pagesPassed && pagesPassed <= offset;

    if (animation) {
      isScrolling.current = true;
    }

    setTimeout(() => {
      publicFunctions.current.setScrollLeft(newScrollValue, {
        animation,
      });
    }, 0);
  }, [
    deviceOrientation,
    loadTwoPages,
    openedPages,
    previousOpenedPages,
    previousOrientation,
    settings.orientation,
    projectWidth,
    settings.reflowable,
  ]);

  useEffect(() => {
    setSnapPoints({
      xAxis: new Array(
        loadTwoPages ? Math.ceil(pages.length / 2) : pages.length
      )
        .fill(1)
        .map((el, index) => -index * projectWidth),
    });
  }, [loadTwoPages, pages.length, projectWidth]);

  useEffect(() => {
    setLoadTwoPages(
      shouldLoadTwoPages(
        settings.reflowable,
        settings.orientation,
        deviceOrientation
      )
    );
  }, [deviceOrientation, settings.orientation, settings.reflowable]);

  const onGoToPage = useCallback(
    (pageId) => {
      const pageIndex = pages.findIndex((pId) => pId === pageId);
      const pagesIndexesToLoad = getPagesIndexesToLoad(
        pageIndex,
        loadTwoPages,
        pages
      );

      updateUrlHash(pagesIndexesToLoad[0]);
      setOpenedPages(pagesIndexesToLoad);
    },
    [loadTwoPages, pages, setOpenedPages]
  );

  const onObjectStartDragging = useCallback(() => {
    setUserInteractionEnabled(false);
  }, []);

  const onObjectStopDragging = useCallback(() => {
    setUserInteractionEnabled(true);
  }, []);

  return (
    <StyledContent width={projectWidth} height={projectHeight}>
      <PagesContainer
        cellRenderer={({ columnIndex, key, rowIndex }) => {
          const startPage = loadTwoPages ? 2 * columnIndex : columnIndex;

          const pagesIndexesToLoad = getPagesIndexesToLoad(
            startPage,
            loadTwoPages,
            pages
          );

          const rightPageIndex =
            pagesIndexesToLoad.length === 2 ? pagesIndexesToLoad[1] : undefined;

          const leftPageId = pages[startPage];
          const rightPageId =
            pagesIndexesToLoad.length === 2 ? pages[rightPageIndex] : undefined;

          return (
            <PageContainer
              height={projectHeight}
              key={key}
              layers={layers}
              left={columnIndex * projectWidth}
              leftOpenedPage={openedPages[0]}
              leftPageId={leftPageId}
              leftPageIndex={pagesIndexesToLoad[0]}
              leftPageModel={pagesModels[pages[pagesIndexesToLoad[0]]]}
              onGoToPage={onGoToPage}
              onObjectStartDragging={onObjectStartDragging}
              onObjectStopDragging={onObjectStopDragging}
              pageIsFullScreen={!loadTwoPages}
              projectHeight={settings.height}
              projectId={settings.projectId}
              projectWidth={settings.width}
              rightOpenedPage={openedPages[1]}
              rightPageId={rightPageId}
              rightPageIndex={rightPageIndex}
              rightPageModel={
                pagesIndexesToLoad.length === 2
                  ? pagesModels[pages[pagesIndexesToLoad[1]]]
                  : undefined
              }
              width={projectWidth}
              zoomFactor={projectZoomFactor}
            >
              {`${columnIndex}-${rowIndex}`}
            </PageContainer>
          );
        }}
        columnCount={loadTwoPages ? Math.ceil(pages.length / 2) : pages.length}
        columnWidth={projectWidth}
        rowCount={1}
        rowHeight={projectHeight}
        snapPoints={snapPoints}
        onScrollStart={onScrollStart}
        onScroll={onScroll}
        onScrollEnd={onScrollEnd}
        exposePublicFunctions={exposePublicFunctions}
        userInteractionEnabled={userInteractionEnabled}
      />
    </StyledContent>
  );
};

export default Content;
