const events = {};

let handler;

const attach = (h) => {
  handler = h;
};
const dispatch = (event, data) => {
  if (!events[event]) {
    return;
  }
  const actionsIds = events[event];
  actionsIds.forEach((actionId) => {
    handler(actionId, event, data);
  });
};
const subscribe = (event, actionId) => {
  if (!events[event]) {
    events[event] = [];
  }
  if (!events[event].includes(actionId)) {
    events[event].push(actionId);
  }
};

const unsubscribe = (event, actionId) => {
  if (!events[event]) {
    return;
  }
  const actions = events[event];
  const index = actions.findIndex((id) => id === actionId);

  if (index === -1) return;

  if (actions.length === 1) {
    delete events[event];
    return;
  }

  actions.splice(index, 1);
};

export default {
  attach,
  dispatch,
  subscribe,
  unsubscribe,
};
