let shareUrl = "";

const openUrl = (url) => {
  window.open(url, "_blank");
};

const getModelUrl = () => `${shareUrl}/model.json`;

const loadThumbnail = (thumbUrl) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(`${shareUrl}/${thumbUrl}`);
    };

    image.onerror = () => {
      reject(thumbUrl);
    };

    image.src = `${shareUrl}/${thumbUrl}`;
  });

const getResourceUrl = (relativePath) => `${shareUrl}/${relativePath}`;

const setServerUrl = () => {
  // Do nothing. We need this function for compatibility with other platforms
};

const setShareUrl = (externalShareUrl) => {
  shareUrl = `projects/${externalShareUrl}`;
};

export default {
  openUrl,
  getModelUrl,
  loadThumbnail,
  setServerUrl,
  getResourceUrl,
  setShareUrl,
};
