import React from "react";
import { createGlobalStyle } from "styled-components";
import InteractiveComponent from "@components/interactive/InteractiveComponent";

import fontOpenSans600 from "@fonts/OpenSans600.woff2";
import fontOpenSans400 from "@fonts/OpenSans400.woff2";

let serverUrl = "";

export const setServerUrl = (externalServerurl) => {
  serverUrl = externalServerurl;
};

const defaultFontsProps = {
  "font-family": "times new roman,times,serif",
  "font-size": "14px",
  "letter-spacing": "0px",
  padding: "0px 0px 0px 0px",
  margin: "0px 0px 0px 0px",
  "line-height": "1.0",
  color: "#000000",
  "background-color": "transparent",
  "font-weight": "normal",
  "font-style": "normal",
  "text-decoration": "none",
  "text-align": "none",
};

const H1 = {
  ...defaultFontsProps,
  "font-size": "24px",
};
const H2 = {
  ...defaultFontsProps,
  "font-size": "18px",
};
const H3 = {
  ...defaultFontsProps,
};
const mainText = {
  ...defaultFontsProps,
};
const captionText = {
  ...defaultFontsProps,
};
const highlightingText = {
  ...defaultFontsProps,
};
const inputText = {
  ...defaultFontsProps,
};

const availableFontsInformation = {
  H1,
  H2,
  H3,
  mainText,
  captionText,
  highlightingText,
  inputText,
};

export const GlobalStyle = createGlobalStyle`
html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0 !important;
  touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  text-size-adjust: none; /* prevent webkit from resizing text to fit */
  user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
  user-drag: none; /* prevent dragging the objects unless they are explicitly specified to do so */
  position: fixed;
  width: 100%;
  height: 100%;
  margin:0;
  font-family: Arial, sans-serif ;
}

div::-webkit-scrollbar {
  display: none;
}

#root {
  width: 100%;
  height: 100%;
  display:flex;  
  align-items: center;
  justify-content: center;
  background-color:#555b69;
}

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
      transform: scale(1.0);
    }
}

img{
  user-select: none;
  user-drag:none;
}

${({ settings: { styles } }) =>
  styles &&
  Object.keys(availableFontsInformation)
    .filter((key) => key !== "highlightingText" || styles[key])
    .map((key) => {
      const fontInfo = {
        ...availableFontsInformation[key],
        ...(styles[key] || {}),
      };
      return `.${key} {${Object.keys(fontInfo)
        .map((styleKey) => `${styleKey}: ${fontInfo[styleKey]};`)
        .join("")}}`;
    })
    .join("")}

${({ settings: { fonts } }) =>
  fonts &&
  fonts
    .map(
      (font) =>
        `@font-face {
             font-family: ${font["font-family"]};
             src: url('${serverUrl}/${font.url}');
             }`
    )
    .join("")}

${({ settings: { karaokeModeTextColor, karaokeModeBackgroundColor } }) => {
  const textColorRule = karaokeModeTextColor
    ? `color: ${karaokeModeTextColor};`
    : "";
  const backgroundColorRule = karaokeModeBackgroundColor
    ? `background-color: ${karaokeModeBackgroundColor};`
    : "";

  return (
    (textColorRule || backgroundColorRule) &&
    `.karaokeHighlight {
  ${textColorRule}
  ${backgroundColorRule}
  }`
  );
}}


:root {
  --tba-player-zoom-factor: 1px;
  --dark-grey-blue: #353e55;
  --orange:#ff7710;
  --cornflower-blue:#4e78dd;
  --slate-grey:#61697d;
  --very-light-pink:#f3f3f3;
  --blue-grey:#9aa0af;
  --white: #ffffff;


  --boring-green: #68af63;
  --red: #f55c5c;
  --pale-grey: #f5f6f9;
}



@font-face {
  font-family: 'OpenSans600';
  font-style: normal;
  font-weight: 600;
  src: url(${fontOpenSans600}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'OpenSans400';
  font-style: normal;
  font-weight: 400;
  src: url(${fontOpenSans400}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.jpedal .t {
  user-select: text;
}

.jpedal {
  background: none;
  position: absolute !important;
  transform-origin: 50% 50%;
  left: 50%;
  top: 50%;
}

p > *{display:inherit}
span {display:inline}
em {display:inline}
strong {display:inline}

`;

export const OpenSans400 = `
  font-family: OpenSans400;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

export const OpenSans600 = `
  font-family: OpenSans600;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

export const CenterVertically = `
    display: flex;
    align-items: center;
`;

export const CenterHorizontally = `
    display: flex;
    justify-content: center;
`;
export const CenterVerticallyItself = `
    align-self: center;
`;

export const CenterHorizontallyItself = `
    justify-self: center;
`;

export const Center = `
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LeftAligned = `
    display: flex;
    justify-content: flex-start;
`;

export const RightAligned = `
    display: flex;
    justify-content: flex-end;
`;

export const CenterItself = `
    align-self: center;
    justify-self: center;
`;

export const LeftAlignedItself = `
    justify-self: flex-start;
`;

export const RightAlignedItself = `
    justify-self: flex-end;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const Interactive = (props) => <InteractiveComponent {...props} />;
