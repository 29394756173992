import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import useComponentDidMount from "@hooks/UseComponentDidMount";
import AudioPlayer from "@media/player/AudioPlayer";
import {
  ActionType,
  LinkType,
  SoundAccessRight,
  SoundUI,
  StylesPrefixes,
} from "@constants/Constants";
import PlatformUtils from "@utils/PlatformUtils";
import CircularKnob from "@atoms/CircularKnob";
import ActionsUtils from "@utils/ActionsUtils";
import usePrevious from "@hooks/UsePrevious";
import AudioRecorder from "@media/recorder/AudioRecorder";

import soundIcon from "./gfx/soundIcon.svg";
import soundPauseIcon from "./gfx/soundPauseIcon.svg";
import soundPlayIcon from "./gfx/soundPlayIcon.svg";

const StyledKnob = styled(CircularKnob)`
  position: absolute;
  ${({ zoomFactor }) => `
   width: ${48 * zoomFactor}px;
   height: ${48 * zoomFactor}px;
  `}
`;

const SoundPlayerIcon = styled.img`
  position: absolute;
  ${({ zoomFactor }) => `
   width: ${48 * zoomFactor}px;
   height: ${48 * zoomFactor}px;
  `}
`;

const pulsateStyles = (styleProps) => `
    ${Object.keys(styleProps)
      .map((key) => `${StylesPrefixes[key]}${styleProps[key]};`)
      .join("\n")}
`;

const SoundPlayerIconUI = styled.img`
  position: absolute;
  ${({ zoomFactor }) => `
   width: ${48 * zoomFactor}px;
   height: ${48 * zoomFactor}px;
  `}
  ${({ styleProps }) =>
    Object.keys(styleProps).length && pulsateStyles(styleProps)};
`;

const SoundPlayer = ({
  isPlaying,
  onChange,
  onInteractionEnded,
  onInteractionStarted,
  seekValue,
  zoomFactor,
}) => (
  <>
    <SoundPlayerIcon
      src={isPlaying ? soundPauseIcon : soundPlayIcon}
      zoomFactor={zoomFactor}
    />
    <StyledKnob
      interactive
      value={seekValue}
      foregroundStrokeColor="#9aa0af"
      foregroundStroke={2.6 * zoomFactor}
      onChange={onChange}
      onInteractionEnded={onInteractionEnded}
      onInteractionStarted={onInteractionStarted}
      zoomFactor={zoomFactor}
    />
  </>
);

SoundPlayer.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onInteractionEnded: PropTypes.func.isRequired,
  onInteractionStarted: PropTypes.func.isRequired,
  seekValue: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number.isRequired,
};

const SoundIcon = React.memo(({ isPlaying, zoomFactor }) => {
  const [styleProps, setStyleProps] = useState({});
  const previousIsPlaying = usePrevious(isPlaying);

  useEffect(() => {
    if (isPlaying === previousIsPlaying) return;

    if (!isPlaying) {
      setStyleProps({});
    } else {
      ActionsUtils.execute(
        {
          action: { type: ActionType.PULSATE, duration: 2000 },
          initialOpacity: 1,
        },
        {
          executedActionsTimes: 0,
          styleProps: { opacity: 1 },
          setStyleProps,
          initialOpacity: 1,
        }
      );
    }
  }, [isPlaying, previousIsPlaying, styleProps]);

  return (
    <SoundPlayerIconUI
      src={soundIcon}
      zoomFactor={zoomFactor}
      styleProps={styleProps}
    />
  );
});
SoundIcon.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  zoomFactor: PropTypes.number.isRequired,
};

const { round } = Math;

const Sound = React.memo(
  ({
    activatedAt,
    activatedByLinkProps,
    defaultSoundAccessRight,
    display,
    id,
    karaokeConfig,
    loop,
    onKaraokeSoundTimeUpdate,
    onObjectLoad,
    onObjectStartDragging,
    onObjectStopDragging,
    pageActivatedAt,
    path,
    recordedSoundAccessRight,
    recordedSoundId,
    zoomFactor,
  }) => {
    const [seekValue, setSeekValue] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const previousLinkActivationProps = usePrevious(activatedByLinkProps);
    const previousActivationAt = usePrevious(activatedAt);
    const defaultSoundLoaded = useRef(false);

    const getPlayingSoundId = useCallback(async () => {
      try {
        const soundIsPlaying = await AudioPlayer.isSoundPlaying(id);
        return soundIsPlaying ? id : null;
      } catch (e) {
        try {
          const soundIsPlaying = await AudioPlayer.isSoundPlaying(
            recordedSoundId
          );
          return soundIsPlaying ? recordedSoundId : null;
        } catch (err) {
          return null;
        }
      }
    }, [id, recordedSoundId]);

    const getSoundId = useCallback(
      () =>
        recordedSoundId &&
        recordedSoundAccessRight === SoundAccessRight.CAN_PLAY &&
        AudioRecorder.getRecordedSounds()[recordedSoundId]
          ? recordedSoundId
          : id,
      [id, recordedSoundAccessRight, recordedSoundId]
    );

    const onSeekSoundBasedOnUserInteraction = useCallback(
      async (value) => {
        const soundId = getSoundId();
        const soundDuration = await AudioPlayer.getSoundDuration(soundId);
        const soundCurrentTime = (soundDuration * value) / 100;
        await AudioPlayer.setSoundCurrentTime(soundId, soundCurrentTime);
      },
      [getSoundId]
    );

    const onPause = useCallback(() => {
      setIsPlaying(false);
    }, []);

    const onPlayStart = useCallback(() => {
      setIsPlaying(true);
    }, []);

    const onEnded = useCallback(
      async (soundId) => {
        if (!pageActivatedAt) return;
        setSeekValue(0);
        if (loop) {
          await AudioPlayer.pauseSound(soundId);
          await AudioPlayer.playSound(soundId);
        } else {
          setIsPlaying(false);
        }
      },
      [loop, pageActivatedAt]
    );

    const onTimeUpdate = useCallback(
      async (soundId) => {
        const soundDuration = await AudioPlayer.getSoundDuration(soundId);
        const soundCurrentTime = await AudioPlayer.getSoundCurrentTime(soundId);
        setSeekValue(round((100 * soundCurrentTime) / soundDuration));
        if (soundCurrentTime === 0 || !karaokeConfig) {
          return;
        }

        const soundCurrentTimeInMs = 1000 * soundCurrentTime;

        const syncPointIndex = karaokeConfig.syncPoints.findIndex(
          (position) => position > soundCurrentTimeInMs
        );
        onKaraokeSoundTimeUpdate(syncPointIndex);
      },
      [karaokeConfig, onKaraokeSoundTimeUpdate]
    );

    const createSound = useCallback(
      (soundId, src) => {
        const options = {
          id: soundId,
          src,
        };

        if (karaokeConfig || display !== SoundUI.NONE) {
          options.onEnded = onEnded;
        }
        if (
          karaokeConfig ||
          display === SoundUI.COMPACT_PLAYER ||
          display === SoundUI.FULL_PLAYER
        ) {
          options.onTimeUpdate = onTimeUpdate;
        }
        if (
          display === SoundUI.COMPACT_PLAYER ||
          display === SoundUI.FULL_PLAYER
        ) {
          options.onPlayStart = onPlayStart;
          options.onPause = onPause;
        }

        return AudioPlayer.createSound(options);
      },
      [display, karaokeConfig, onEnded, onPause, onPlayStart, onTimeUpdate]
    );

    useComponentDidMount(() => {
      createSound(id, PlatformUtils.getResourceUrl(path)).then(() => {
        defaultSoundLoaded.current = true;
        onObjectLoad(id);
      });
      return () => {
        AudioPlayer.destroySound(id).then();
      };
    });

    const playPauseSound = useCallback(
      async (soundId, forceStop = false) => {
        const playingSoundId = await getPlayingSoundId();
        if (forceStop || !pageActivatedAt) {
          if (playingSoundId) {
            AudioPlayer.stopSound(playingSoundId).then();
          }
          return;
        }

        if (playingSoundId) {
          if (playingSoundId === soundId) {
            if (display === SoundUI.ICON) {
              AudioPlayer.stopSound(playingSoundId).then();
            } else {
              AudioPlayer.pauseSound(playingSoundId).then();
            }
          } else {
            AudioPlayer.stopSound(playingSoundId).then();
          }
        } else {
          if (soundId === recordedSoundId) {
            await createSound(
              recordedSoundId,
              AudioRecorder.getRecordedSounds()[recordedSoundId]
            );
          }
          AudioPlayer.playSound(soundId).then();
        }
      },
      [
        createSound,
        display,
        getPlayingSoundId,
        pageActivatedAt,
        recordedSoundId,
      ]
    );

    useEffect(() => {
      if (previousActivationAt === activatedAt) return;

      if (
        recordedSoundId &&
        recordedSoundAccessRight === SoundAccessRight.CAN_PLAY &&
        AudioRecorder.getRecordedSounds()[recordedSoundId]
      ) {
        playPauseSound(recordedSoundId, !activatedAt).then();
        return;
      }
      if (defaultSoundAccessRight === SoundAccessRight.CAN_PLAY) {
        playPauseSound(id, !activatedAt).then();
      }
    }, [
      activatedAt,
      defaultSoundAccessRight,
      id,
      playPauseSound,
      previousActivationAt,
      recordedSoundAccessRight,
      recordedSoundId,
    ]);

    useEffect(() => {
      if (!activatedByLinkProps || !pageActivatedAt) {
        return;
      }

      if (
        previousLinkActivationProps &&
        previousLinkActivationProps.activatedAt ===
          activatedByLinkProps.activatedAt
      ) {
        return;
      }
      const soundId = getSoundId();
      AudioPlayer.isSoundPlaying(soundId).then((play) => {
        activatedByLinkProps.properties.forEach((link) => {
          if (link.type === LinkType.ACTIVATE_TOGGLE) {
            if (play) {
              AudioPlayer.stopSound(soundId).then();
              return;
            }

            if (
              (soundId === recordedSoundId &&
                recordedSoundAccessRight === SoundAccessRight.CAN_NOT_PLAY) ||
              (soundId !== recordedSoundId &&
                defaultSoundAccessRight === SoundAccessRight.CAN_NOT_PLAY)
            ) {
              return;
            }
            AudioPlayer.playSound(soundId).then();
          }
          if (
            (soundId === recordedSoundId &&
              recordedSoundAccessRight === SoundAccessRight.CAN_NOT_PLAY) ||
            (soundId !== recordedSoundId &&
              defaultSoundAccessRight === SoundAccessRight.CAN_NOT_PLAY)
          ) {
            return;
          }
          AudioPlayer.stopSound(soundId).then(() =>
            AudioPlayer.playSound(soundId)
          );
        });
      });
    }, [
      activatedAt,
      activatedByLinkProps,
      defaultSoundAccessRight,
      getSoundId,
      pageActivatedAt,
      previousLinkActivationProps,
      recordedSoundAccessRight,
      recordedSoundId,
    ]);

    useEffect(() => {
      if (!pageActivatedAt) return;
      if (
        defaultSoundAccessRight === SoundAccessRight.CAN_NOT_PLAY &&
        defaultSoundLoaded.current
      ) {
        AudioPlayer.stopSound(id).then();
      }
      if (!AudioRecorder.getRecordedSounds()[recordedSoundId]) return;

      if (recordedSoundAccessRight === SoundAccessRight.CAN_NOT_PLAY) {
        AudioPlayer.stopSound(recordedSoundId).then();
      }
    }, [
      defaultSoundAccessRight,
      id,
      pageActivatedAt,
      recordedSoundAccessRight,
      recordedSoundId,
    ]);

    useEffect(() => {
      if (pageActivatedAt) return;
      if (
        recordedSoundId &&
        recordedSoundAccessRight === SoundAccessRight.CAN_PLAY &&
        AudioRecorder.getRecordedSounds()[recordedSoundId]
      ) {
        playPauseSound(recordedSoundId, true).then();
        return;
      }
      if (defaultSoundAccessRight === SoundAccessRight.CAN_PLAY) {
        playPauseSound(id, true).then();
      }
    }, [
      defaultSoundAccessRight,
      id,
      pageActivatedAt,
      playPauseSound,
      recordedSoundAccessRight,
      recordedSoundId,
    ]);

    const getUI = useCallback(() => {
      switch (display) {
        case SoundUI.COMPACT_PLAYER:
        case SoundUI.FULL_PLAYER: {
          return (
            <SoundPlayer
              isPlaying={isPlaying}
              onChange={onSeekSoundBasedOnUserInteraction}
              onInteractionEnded={onObjectStopDragging}
              onInteractionStarted={onObjectStartDragging}
              seekValue={seekValue}
              zoomFactor={zoomFactor}
            />
          );
        }
        case SoundUI.ICON: {
          return <SoundIcon isPlaying={isPlaying} zoomFactor={zoomFactor} />;
        }
        default:
          return null;
      }
    }, [
      display,
      isPlaying,
      onObjectStartDragging,
      onObjectStopDragging,
      onSeekSoundBasedOnUserInteraction,
      seekValue,
      zoomFactor,
    ]);

    return getUI();
  }
);
Sound.defaultProps = {
  activatedAt: undefined,
  activatedByLinkProps: undefined,
  display: SoundUI.NONE,
  karaokeConfig: null,
  loop: false,
};

Sound.propTypes = {
  activatedAt: PropTypes.number,
  activatedByLinkProps: PropTypes.shape({
    activatedAt: PropTypes.number.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
      })
    ),
  }),
  defaultSoundAccessRight: PropTypes.number.isRequired,
  display: PropTypes.number,
  id: PropTypes.string.isRequired,
  karaokeConfig: PropTypes.shape({
    syncOrder: PropTypes.string.isRequired,
    syncPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  loop: PropTypes.bool,
  onKaraokeSoundTimeUpdate: PropTypes.func.isRequired,
  onObjectLoad: PropTypes.func.isRequired,
  onObjectStartDragging: PropTypes.func.isRequired,
  onObjectStopDragging: PropTypes.func.isRequired,
  pageActivatedAt: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  recordedSoundAccessRight: PropTypes.number.isRequired,
  recordedSoundId: PropTypes.string.isRequired,
  zoomFactor: PropTypes.number.isRequired,
};

export default Sound;
