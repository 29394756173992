import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";

import useComponentDidMount from "@hooks/UseComponentDidMount";
import ShortId from "@utils/ShortId";
import { NotificationTypes } from "@constants/Constants";
import UserNotificationItem from "./UserNotificationItem";

const StyledUserNotifications = styled.div`
  z-index: 11;
  position: absolute;
  bottom: 40px;
  left: 40px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const UserNotifications = () => {
  const persistentUserNotifications = useRef([]);
  const [, updateState] = useState({});

  const deleteNotification = useCallback((notificationId) => {
    persistentUserNotifications.current =
      persistentUserNotifications.current.filter(
        ({ id }) => id !== notificationId
      );
    updateState({});
  }, []);

  useComponentDidMount(() => {
    const addNotification = (message, type) => {
      persistentUserNotifications.current = [
        ...persistentUserNotifications.current,
        { id: ShortId.generate(), type, message },
      ];
      updateState({});
    };

    window.NotificationManager = {
      showError: (message) => {
        addNotification(message, NotificationTypes.ERROR);
      },
      showInfo: (message) => {
        addNotification(message, NotificationTypes.INFO);
      },
      showSuccess: (message) => {
        addNotification(message, NotificationTypes.SUCCESS);
      },
    };
  });

  return (
    <StyledUserNotifications>
      {persistentUserNotifications.current.map((notification) => (
        <UserNotificationItem
          id={notification.id}
          key={notification.id}
          message={notification.message}
          onDismiss={deleteNotification}
          type={notification.type}
        />
      ))}
    </StyledUserNotifications>
  );
};

export default UserNotifications;
