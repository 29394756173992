import React, { useCallback } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";

import { CenterVertically } from "@src/GlobalStyles";

import ArrowButton from "@atoms/ArrowButton";
import {
  openedPagesAtom,
  deviceOrientationAtom,
  settingsAtom,
  pagesAtom,
} from "@stateManagement/Atoms";
import {
  getPagesIndexesToLoad,
  shouldLoadTwoPages,
  updateUrlHash,
} from "@utils/Utils";

import disabledArrowIcon from "./gfx/leftArrowInactiveIcon.svg";
import arrowIcon from "./gfx/leftArrowActiveIcon.svg";

const Container = styled.div`
  ${CenterVertically};
  width: 96px;
  height: 100%;
`;

const LeftSideBar = () => {
  const deviceOrientation = useRecoilValue(deviceOrientationAtom);
  const settings = useRecoilValue(settingsAtom);
  const pages = useRecoilValue(pagesAtom);

  const [openedPages, setOpenedPages] = useRecoilState(openedPagesAtom);

  const onNavigateToPreviousPage = useCallback(() => {
    const pagesIndexesToLoad = getPagesIndexesToLoad(
      openedPages[0] - 1,
      shouldLoadTwoPages(
        settings.reflowable,
        settings.orientation,
        deviceOrientation
      ),
      pages
    );

    updateUrlHash(pagesIndexesToLoad[0]);
    setOpenedPages(pagesIndexesToLoad);
  }, [
    deviceOrientation,
    openedPages,
    pages,
    setOpenedPages,
    settings.orientation,
    settings.reflowable,
  ]);

  return (
    <Container>
      <ArrowButton
        onClick={onNavigateToPreviousPage}
        disabled={(openedPages[0] || 0) === 0}
        disabledStateImgSrc={disabledArrowIcon}
        enabledStateImgSrc={arrowIcon}
      />
    </Container>
  );
};

export default LeftSideBar;
